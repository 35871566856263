import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Button from "@/common/components/button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DrawerLayout from "@/layouts/DrawerLayout";
import Stack from "@mui/material/Stack";
import { SyntheticEvent, useMemo } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { closeCreateDealDrawer } from "@/redux/reducers/dealFlow/slices/listDeals";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Input from "@/common/components/input";
import { createDeal, updateDeal } from "@/redux/reducers/dealFlow/slices/createDeal";
import LineItemForm from "./LineItemForm";
import Avatar from "@/common/components/avatar/Avatar";
import debounce from "@/common/functions";
import { getProspectsByLastName } from "@/redux/reducers/prospects/slices/listProspects";

const schema = Yup.object({
  name: Yup.string().label("Name").required(),
  pipelineId: Yup.string().label("Pipeline").required(),
  pipelineStageId: Yup.string().label("Pipeline Stage").required(),
  assigneeId: Yup.string().label("Assignee").required(),
  lineItems: Yup.array(
    Yup.object({
      productId: Yup.string(),
      quantity: Yup.number(),
      price: Yup.number(),
    }),
  ),
  contactId: Yup.string().label("Contact"),
  timelineActivityEnabled: Yup.boolean(),
}).required();

type Form = Yup.InferType<typeof schema>;

export default function CreateDealForm() {
  const dispatch = useAppDispatch();
  const currentDeal = useAppSelector(({ dealFlow }) => dealFlow.listDeals.currentDeal);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);
  const { prospects, loading } = useAppSelector(({ prospects }) => prospects.listProspects);
  const contacts = prospects;

  const { t } = useTranslation();

  const handleInputChangeContact = useMemo(
    () =>
      debounce((value) => {
        if (value.length < 1) return;
        dispatch(getProspectsByLastName({ lastName: value }));
      }, 500),
    [dispatch],
  );

  const { control, watch, handleSubmit, formState } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: currentDeal?.name || "",
      pipelineId: currentDeal?.pipelineId || "",
      pipelineStageId: currentDeal?.stage?.id || "",
      assigneeId: currentDeal?.assignee?.id || "",
      lineItems: currentDeal?.lineItems?.map(({ id, ...rest }) => ({ lineItemId: id, ...rest })) || [],
    },
  });
  const pipelineId = watch("pipelineId");

  const currentPipeline = useMemo(() => {
    if (!pipelineId) return;
    return pipelines?.find(({ id }) => id === pipelineId);
  }, [pipelines, pipelineId]);

  const onSubmit: SubmitHandler<Form> = (data) => {
    if (!currentDeal?.id) {
      dispatch(
        createDeal({
          ...data,
          contactIdList: data.contactId ? [data.contactId] : [],
        }),
      );
    } else {
      dispatch(
        updateDeal({
          id: currentDeal.id,
          ...data,
          contactIdList: data.contactId ? [data.contactId] : [],
        }),
      );
    }
    dispatch(closeCreateDealDrawer());
  };

  const pipelinesOptions = useMemo(
    () =>
      (pipelines || []).map((pipeline) => ({
        value: pipeline.id.toString(),
        label: pipeline.name,
      })),
    [pipelines],
  );

  const pipelineStageOptions = useMemo(
    () =>
      (currentPipeline?.stages || []).map((stage) => ({
        value: stage.id,
        label: stage.name,
      })),
    [currentPipeline],
  );

  const assigneesOptions = useMemo(
    () =>
      (teamUsers || []).map((assignee) => ({
        icon: () => <Avatar src={assignee.imgUrl} size="small" />,
        value: assignee.user_id!,
        label: assignee.firstName! + " " + assignee.lastName!,
      })),
    [teamUsers],
  );

  const contactOptions = useMemo(
    () =>
      (contacts || []).map((contact) => ({
        icon: () => <Avatar src={""} size="small" />,
        value: contact.id!,
        label: contact.firstName! + " " + contact.lastName!,
      })),
    [contacts],
  );

  return (
    <form>
      <DrawerLayout
        customWidth="600px"
        title={currentDeal?.id ? t("dealFlow.createDrawer.editTitle") : t("dealFlow.createDrawer.title")}
        testid="newDealForm"
        closeDrawer={() => dispatch(closeCreateDealDrawer())}
      >
        <Box sx={{ display: "grid", gap: "16px" }}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Deal Name" />
                  <Input
                    error={fieldState.error?.message}
                    placeholder="Deal Name"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </>
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="pipelineId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <FormLabel aria-required label="Pipeline" />
                    <Dropdown
                      label="Pipeline"
                      placeholder="Pipeline"
                      error={formState.errors?.pipelineStageId?.message}
                      options={pipelinesOptions}
                      value={field.value}
                      onChange={(option) => field.onChange(option.value)}
                    />
                  </>
                );
              }}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="pipelineStageId"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Deal Stage" />
                  <Dropdown
                    placeholder="Select Deal Stage"
                    options={pipelineStageOptions}
                    value={field.value}
                    onChange={(option) => field.onChange(option.value)}
                    error={fieldState.error?.message}
                  />
                </>
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="assigneeId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <FormLabel aria-required label="Deal Assignee" />
                    <Dropdown
                      placeholder="Select Deal Assignee"
                      options={assigneesOptions}
                      value={field.value}
                      onChange={(option) => field.onChange(option.value)}
                      error={fieldState.error?.message}
                    />
                  </>
                );
              }}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="contactId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <FormLabel aria-required label="Contact" />
                    <Dropdown
                      placeholder="Select Contact"
                      isSearchable
                      onInputChange={handleInputChangeContact}
                      isPending={loading}
                      options={contactOptions}
                      value={field.value}
                      onChange={(option) => field.onChange(option.value)}
                      error={formState?.errors?.contactId?.message}
                    />
                  </>
                );
              }}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="lineItems"
              control={control}
              render={({ field }) => (
                <LineItemForm
                  lineItems={currentDeal?.lineItems}
                  onChangeLineItems={(lineItems) => field.onChange(lineItems)}
                  errors={formState.errors.lineItems}
                />
              )}
            />
          </FormControl>
        </Box>
      </DrawerLayout>
      <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} position="absolute" bottom={0} width="100%">
        <Button
          variant="secondary"
          onClick={(e: SyntheticEvent) => {
            // TODO: Figure it out why this is causing submit
            e.preventDefault();
            dispatch(closeCreateDealDrawer());
          }}
          sx={{ width: "100%" }}
        >
          {t("dealFlow.createDrawer.cancel")}
        </Button>

        <Button
          type="button"
          variant="primary"
          sx={{ width: "100%" }}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          {t("dealFlow.createDrawer.addNew")}
        </Button>
      </Stack>
    </form>
  );
}
