import { useState } from "react";

export function useUploadPercent() {
  const [percent, setPercent] = useState<number>(0);
  const isUploading = percent > 0 && percent < 100;

  const handleDropInterval = () => {
    let i = 0;
    const interval = setInterval(() => {
      if (i < 100) {
        i += 1;
        setPercent(i);
      } else {
        clearInterval(interval);
      }
    }, 15);
  };

  return { percent, setPercent, isUploading, handleDropInterval };
}

interface UploadPercentsHook {
  percents: number[];
  setPercent: (index: number, value: number) => void;
  isUploading: boolean;
  handleDropInterval: (index: number) => void;
  addFile: () => void;
}

export function useUploadPercents(): UploadPercentsHook {
  const [percents, setPercents] = useState<number[]>([]);

  const addFile = () => {
    setPercents((prev) => [...prev, 0]);
  };

  const setPercent = (index: number, value: number) => {
    setPercents((prev) => {
      const newPercents = [...prev];
      newPercents[index] = value;
      return newPercents;
    });
  };

  const isUploading = percents.some((percent) => percent > 0 && percent < 100);

  const handleDropInterval = (index: number) => {
    let i = 0;
    const interval = setInterval(() => {
      if (i < 100) {
        i += 1;
        setPercent(index, i);
      } else {
        clearInterval(interval);
      }
    }, 15);
  };

  return { percents, setPercent, isUploading, handleDropInterval, addFile };
}
