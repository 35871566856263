import React, { useMemo, useRef, useCallback } from "react";
import ReactQuill from "react-quill";
import { useDropzone } from "react-dropzone";
import "react-quill/dist/quill.snow.css";
import { PaperClipOutline } from "heroicons-react";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  handleDropInterval?: (index: number) => void;
  addFile?: () => void;
}

export const TextEditor: React.FC<TextEditorProps> = ({ value, onChange, handleDropInterval, addFile }) => {
  const quill = useRef<ReactQuill | null>(null);
  const handleChanges = (content: string) => {
    onChange(content);
  };
  const { setValue, getValues } = useFormContext();

  const FileUploader: React.FC = () => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
      acceptedFiles.forEach((_, index) => {
        if (addFile) addFile();
        if (handleDropInterval) handleDropInterval(index);
      });
      const allFiles = acceptedFiles.map((file) => ({
        media: file,
        name: file.name,
      }));

      // agreagr a los que estaban antes
      const attachmentsValue = getValues("attachments");
      setValue("attachments", [...allFiles, ...attachmentsValue]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Box {...getRootProps()} style={{ cursor: "pointer" }}>
        <input {...getInputProps()} />
        <PaperClipOutline
          style={{
            width: "24px",
            height: "17px",
            position: "absolute",
            bottom: 12,
            stroke: "#374667",
          }}
        />
      </Box>
    );
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: "#toolbar",
        handlers: {
          fileUploader: () => {
            // Open the file uploader modal or logic
            console.log("File Uploader clicked");
          },
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [setValue],
  );

  const formats = ["header", "bold", "italic", "underline", "list", "bullet", "fileUploader"];

  return (
    <>
      <div
        style={{
          borderRadius: "8px",
          border: "1px solid #E7EBED",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        <ReactQuill
          ref={(el) => (quill.current = el)}
          theme="snow"
          value={value}
          onChange={handleChanges}
          formats={formats}
          modules={modules}
          style={{
            height: "150px",
            border: "none",
          }}
        />

        <Box id="toolbar">
          <select className="ql-header" defaultValue={""} onChange={(e) => e.persist()}>
            <option value="2">Heading 2</option>
            <option value="3">Heading 3</option>
            <option value="4">Heading 4</option>
            <option value="">Normal</option>
          </select>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-list" value="bullet" />
          <button className="ql-list" value="ordered" />
          <FileUploader />
        </Box>
      </div>
    </>
  );
};
