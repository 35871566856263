import { Box, FormControl } from "@mui/material";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ActionButtons } from "./ActionButtons";
import Input from "@/common/components/input/Input";
import { createInboxSms } from "@/redux/reducers/inbox/slices/createSms";
import { GenMsgCustomMessageTypeEnum, SmsDTOActionEnum } from "@/services/generated";
import { useAppSelector } from "@/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import SmsMessages from "./SmsMessages";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "@/redux/store";
import { getSmsCurrentInbox } from "@/redux/reducers/inbox/slices/getCurrentInbox";
import { lookupMessageThreads } from "@/redux/reducers/inbox/slices/listMessages";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { closeSendNewMessageDrawer } from "@/redux/reducers/inbox/slices/getInboxData";
import { useState } from "react";
import AiForm from "./../AiForm";
import { genMsgCustom, genMsgSuggestMeetingTimes } from "@/redux/reducers/inbox/slices/generateMessage";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import { getTeamUsers } from "@/redux/reducers/settings/team/get";
import { getProspects } from "@/redux/reducers/prospects/slices/listProspects";

const schema = Yup.object({
  message: Yup.string().label("Message").required(),
  // TODO require on messageGen
  type: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  userIds: Yup.array().of(Yup.string().required()),
  prompt: Yup.string().label("Prompt"),
});

export type SmsFormType = Yup.InferType<typeof schema>;

export const SmsTab = ({ newMessage = false }: { newMessage?: boolean }) => {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);
  const [isShowGenAi, setIsShowGenAi] = useState(false);
  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);

  const users = useAppSelector(({ settings }) => settings.team.users);

  const methods = useForm<SmsFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      message: "",
    },
  });
  const { control, reset } = methods;

  useEffect(() => {
    if (!prospects) {
      // TODO I think this should be prospects inbox?
      dispatch(getProspects());
    }
  }, [prospects]);

  useEffect(() => {
    if (currentProspect) {
      dispatch(getSmsCurrentInbox({ prospectId: currentProspect?.id }));
    }
  }, [currentProspect, dispatch]);

  useEffect(() => {
    if (!users?.length) {
      dispatch(getTeamUsers());
    }
  }, [dispatch]);

  const usersWithCal = useMemo(() => {
    return (users || []).filter((u) => u.calendarConnected);
  }, [users]);
  console.log(usersWithCal);

  const generateMessage = (e: Event) => {
    e.preventDefault();

    console.log("Generating message", methods.getValues());
    const values = methods.getValues();

    // TODO: GET MESSAGE TYPE SMS OR EMAIL

    const promise =
      values.type.value === "suggest_meeting_times"
        ? dispatch(
            genMsgSuggestMeetingTimes({
              message_type: GenMsgCustomMessageTypeEnum.Sms,
              contact_id: currentProspect?.id || "",
              meet_with_user_id_list: values.userIds || [],
            }),
          )
        : dispatch(
            genMsgCustom({
              message_type: GenMsgCustomMessageTypeEnum.Sms,
              contact_id: currentProspect?.id || "",
              // TODO remove this with conditional required
              prompt: values.prompt || "",
            }),
          );

    promise.then((response) => {
      console.log("Response", response);
      methods.setValue("message", response.payload.data.message);
      setIsShowGenAi(false);
    });
  };

  const onSubmit: SubmitHandler<SmsFormType> = (data) => {
    if (isShowGenAi) {
      return;
    }

    dispatch(
      createInboxSms({
        team_id: user?.team_id || 0,
        from_user_id: user?.sub || "000",
        prospect_id: currentProspect?.id || "",
        action: SmsDTOActionEnum.Send,
        sequence_id: "",
        body: data.message,
      }),
    ).then(() => {
      if (user?.sub) {
        dispatch(lookupMessageThreads(user?.sub));
      }
      if (!newMessage) {
        dispatch(getSmsCurrentInbox({ prospectId: currentProspect?.id }));
      }
      dispatch(closeSendNewMessageDrawer());

      reset();
    });
  };

  const onError: SubmitErrorHandler<Yup.InferType<typeof schema>> = (errors) => {
    console.log(errors);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 164px)",
        justifyContent: "space-between",
      }}
    >
      {!newMessage ? <SmsMessages /> : <Box />}
      <FormProvider {...methods}>
        <form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              py: 2,
            }}
          >
            {!isShowGenAi ? (
              <FormControl sx={{ flexGrow: 1, width: "100%" }}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <Input
                        {...field}
                        multiline={true}
                        rows={3}
                        placeholder={"[SalesAi proposed message]"}
                        error={fieldState.error?.message}
                      />
                    );
                  }}
                />
              </FormControl>
            ) : (
              <AiForm
                users={usersWithCal}
                onClose={() => setIsShowGenAi(false)}
                onGenerate={(e) => generateMessage(e)}
              />
            )}

            {!isShowGenAi && (
              <ActionButtons
                sendText="Send SMS"
                onSubmit={onSubmit}
                onError={onError}
                setIsGenerating={setIsShowGenAi}
              />
            )}
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
