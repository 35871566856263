import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

import ToolTip from "../tool-tip";
import { Box, Typography } from "@mui/material";
import { ReactComponent as PlusCircle } from "@/common/assets/svg/sequence/plus-circle.svg";
import { ReactComponent as MinusCircle } from "@/common/assets/svg/sequence/minus-circle.svg";
import Button from "../button";
import { useState } from "react";

interface ActionSetDelayProps {
  icon?: JSX.Element;
  description: string;
  id: string;
  delay?: number;
  onSetDelay?: (delay: number) => void;
}

export default function ActionSetDelay(props: ActionSetDelayProps) {
  const { icon, description, id, delay: delayProp, onSetDelay } = props;
  const [delay, setDelay] = useState(delayProp || 1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    onSetDelay?.(delay);
    handleClose();
  };

  return (
    <>
      <ToolTip key={id} title={description} placement={"right"}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {icon}
        </IconButton>
      </ToolTip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ml: 11.23,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 100,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        sx={(theme) => ({
          "& .MuiPaper-root": {
            borderRadius: "10px",
            fontSize: "30px",
          },
        })}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", padding: "4px 14px" }}>
          <Box sx={{ display: "flex", gap: "9px", justifyContent: "center", alignItems: "center" }}>
            <IconButton
              onClick={() => {
                if (delay === 0) return;
                setDelay(delay - 1);
              }}
            >
              <MinusCircle />
            </IconButton>
            <Typography variant="body2" color="#6B747B">
              {delay} days
            </Typography>
            <IconButton onClick={() => setDelay(delay + 1)}>
              <PlusCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", gap: "8px", justifyContent: "center", alignItems: "center" }}>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
}
