import { Box } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SubmitHandler } from "react-hook-form";
import DrawerLayout from "@/layouts/DrawerLayout";
import Stack from "@mui/material/Stack";
import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";

import Input from "@/common/components/input";

import Accordion from "@/common/components/accordion/Accordion";
import { Typography, useTheme } from "@mui/material";
import { ProspectAddressOptions, ProspectJobTitleOptions } from "@/redux/reducers/prospects/slices/helpers";
import { CompanyHeadcountOptions, CompanyRevenueOptions } from "@/redux/reducers/prospects/companies/slices/helpers";
import { useAppSelector } from "@/redux/store";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";

// TODO: Replace with real data

export default function AdvancedFilters(props: any) {
  const { toggleDrawer } = props;
  const { control, handleSubmit, reset, setValue, watch } = useForm();
  const { t } = useTranslation();
  const theme = useTheme();
  const companyNames = useAppSelector(({ companies }) => companies.listCompanies.companies)?.map((company) => ({
    label: company.name || "",
    value: company.id || "",
    icon: "",
  }));
  const onSubmit: SubmitHandler<any> = (data, event) => {
    if (!data) return;
    console.log(data);

    try {
      // dispatch(closeEditDrawer());
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ height: "calc(100vh - 80px)", overflow: "scroll" }}>
          <DrawerLayout
            customWidth="500px"
            title="Advanced Filters"
            testid="advancedFilters"
            closeDrawer={toggleDrawer}
          >
            <Box sx={{ background: theme.palette.midnight[20] }}>
              <Accordion
                defaultExpanded
                summaryTitle={
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      paddingX: "8px",
                      paddingY: "8px",
                    })}
                  >
                    <Typography variant="body2" fontWeight="600">
                      Contact Information
                    </Typography>
                  </Box>
                }
                id="contact-info"
              >
                <Box
                  sx={(theme) => ({
                    gap: "12px",
                    display: "flex",
                    flexDirection: "column",
                    paddingX: "16px",
                    paddingBottom: "8px",
                  })}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="fisrtName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="First Name" />
                          <Input {...field} type="text" placeholder="John " />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Last Name" />
                          <Input {...field} type="text" placeholder="Doe " />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="hasEmail"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Has Email" />
                          <Box sx={{ display: "flex", gap: "16px" }}>
                            <IndeterminateCheckbox {...field} label="Yes" key="yes" checked={false} />
                            <IndeterminateCheckbox {...field} label="No" key="no" checked={false} />
                          </Box>
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="hasPhoneNumber"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Has Phone Number" />
                          <Box sx={{ display: "flex", gap: "16px" }}>
                            <IndeterminateCheckbox {...field} label="Yes" key="yes" checked={false} />
                            <IndeterminateCheckbox {...field} label="No" key="no" checked={false} />
                          </Box>
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="jobTitle"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Job Title" />
                          <Dropdown
                            isSearchable
                            label="Job Title"
                            options={ProspectJobTitleOptions}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
              </Accordion>
            </Box>

            <Box sx={{ background: theme.palette.midnight[20] }}>
              <Accordion
                defaultExpanded
                summaryTitle={
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      paddingX: "8px",
                      paddingY: "8px",
                    })}
                  >
                    <Typography variant="body2" fontWeight="600">
                      Company Information
                    </Typography>
                  </Box>
                }
                id="contact-info"
              >
                <Box
                  sx={(theme) => ({
                    gap: "12px",
                    display: "flex",
                    flexDirection: "column",
                    paddingX: "16px",
                    paddingBottom: "8px",
                  })}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="companyRevenue"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Company Revenue" />
                          <Dropdown
                            isSearchable
                            label="Company Revenue"
                            options={CompanyRevenueOptions}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="companyHeadcount"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Company Headcount" />
                          <Dropdown
                            isSearchable
                            label="Company Headcount"
                            options={CompanyHeadcountOptions}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>{" "}
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="companyKeywordSearch"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Company keyword search" />
                          <Dropdown
                            isSearchable
                            label="Company keyword search"
                            options={companyNames || []}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>{" "}
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="industry"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Industry" />
                          <Dropdown
                            isSearchable
                            label="Industry"
                            options={ProspectJobTitleOptions}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
              </Accordion>
            </Box>

            <Box sx={{ background: theme.palette.midnight[20] }}>
              <Accordion
                defaultExpanded
                summaryTitle={
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      paddingX: "8px",
                      paddingY: "8px",
                    })}
                  >
                    <Typography variant="body2" fontWeight="600">
                      Geographical Information
                    </Typography>
                  </Box>
                }
                id="contact-info"
              >
                <Box
                  sx={(theme) => ({
                    gap: "12px",
                    display: "flex",
                    flexDirection: "column",
                    paddingX: "16px",
                    paddingBottom: "8px",
                  })}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="location"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Location" />
                          <Dropdown
                            isSearchable
                            label="Location"
                            options={ProspectAddressOptions.map((address) => ({
                              value: JSON.stringify(address),
                              label: `${address.city}, ${address.state}`,
                            }))}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="City" />
                          <Dropdown
                            isSearchable
                            label="City"
                            options={ProspectAddressOptions.map((address) => ({
                              value: JSON.stringify(address),
                              label: address.city,
                            }))}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="statep_province"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="State/Province" />
                          <Dropdown
                            isSearchable
                            label="State/Province"
                            options={ProspectAddressOptions.map((address) => ({
                              value: JSON.stringify(address),
                              label: address.state,
                            }))}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
              </Accordion>
            </Box>
          </DrawerLayout>
        </Box>
        <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} position="absolute" bottom={0} width="100%">
          <Button data-testid="closeTaskDrawer" variant="secondary" onClick={toggleDrawer} sx={{ width: "100%" }}>
            {t("tasks.cancel")}
          </Button>

          <Button type="submit" variant="primary" sx={{ width: "100%" }}>
            Narrow Results
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
