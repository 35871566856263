import AttachmentInput from "@/common/components/attachment/Attachment";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import { setDraftTeam } from "@/redux/reducers/settings/team";
import { useAppDispatch } from "@/redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
// import FileUpload from "@/common/components/file-upload";

const schema = Yup.object({
  name: Yup.string(),
  domain: Yup.string(),
  logoImgUrl: Yup.string(),
  media: Yup.mixed(),
}).required();

type TeamInformationFormProps = {
  team: any;
  readOnly?: boolean;
};

export default function TeamInformationForm({ team, readOnly = false }: TeamInformationFormProps) {
  const dispatch = useAppDispatch();
  const { control, reset, watch, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: team,
  });

  const data = watch();
  const previousDataRef = useRef(data);

  useEffect(() => {
    reset(team);
  }, [team, reset]);

  useEffect(() => {
    const hasFormStateChanged = JSON.stringify(previousDataRef.current) !== JSON.stringify(data);

    if (formState.isDirty && hasFormStateChanged && !readOnly) {
      previousDataRef.current = data;
      dispatch(setDraftTeam(data));
    }
  }, [data, dispatch, formState.isDirty, readOnly]);

  const handleFileSelect = (file: File) => {
    setValue("media", file, { shouldDirty: true });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginTop: "16px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "28px",
          }}
        >
          {readOnly ? "Your Team" : "Team Brand"}
        </Typography>
        {readOnly && <Typography variant="body2">Provide your team name, domain and team logo.</Typography>}
      </Box>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Company Name" aria-required />
            <Input {...field} error={fieldState.error?.message} disabled={readOnly} />
          </FormControl>
        )}
      />
      <Controller
        name="domain"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Domain" aria-required />
            <Input {...field} error={fieldState.error?.message} disabled={readOnly} />
          </FormControl>
        )}
      />

      <FormControl>
        <Controller
          name="media"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel label="Company Logo" />
              <AttachmentInput {...field} disabled={readOnly} />
            </>
          )}
        />
      </FormControl>

      {/* <FileUpload label="Team Logo" onFileSelect={handleFileSelect} iconPlaceholderUrl={team?.logoImgUrl} /> */}
    </Box>
  );
}
