import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import CreateTaskFormValues from "./CreateTaskFormValues";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { LookupTasksRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";
import * as Yup from "yup";

import dayjs from "dayjs";
import { AttachmentDto } from "@/services/generated";
import { createTask } from "@/redux/reducers/tasks/slices/createTask";

type TaskFormProps = {
  dueDate?: Date;
  dueTime?: Date;
  assignee?: {
    label: string;
    value: string;
  };
  description?: string;
  attachments?: AttachmentDto[];
  deal?: {
    label: string;
    value: string;
  };
  type: {
    label: string;
    value: string;
  };
  prospect?: {
    value: string;
  };
};

const schema = Yup.object({
  dueDate: Yup.date().required("Due date is required"),
  dueTime: Yup.date().required("Due time is required"),
  assignee: Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  prospect: Yup.object({
    value: Yup.string().required(),
  }).nullable(),
  type: Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }).required(),
  deal: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }),
  description: Yup.string().nullable(),
  attachments: Yup.array().of(
    Yup.object().shape({
      media: Yup.mixed().required(),
      name: Yup.string().required(),
    }),
  ),
  status: Yup.string(),
}).required();

export default function CreateTaskForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const users = useAppSelector(({ team }) => team.listUsers.users);

  useEffect(() => {
    if (users === undefined) {
      dispatch(getTeamUsers());
    }
  }, [users, dispatch]);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: TaskFormProps) => {
    if (!data) return;

    const { type, description, dueDate, dueTime, deal, assignee, prospect } = data;

    if (type.value !== TaskRequestTypeEnum.Other) {
      delete data.description;
    } else if (!description) {
      methods.setError("description", { type: "required", message: "Description is required" });
      return;
    }

    if (!dueDate) {
      methods.setError("dueDate", { type: "required", message: "Due date is required" });
      return;
    }

    if (!dueTime) {
      methods.setError("dueTime", { type: "required", message: "Due time is required" });
      return;
    }

    const formattedDate = `${dayjs(dueDate).format("YYYY-MM-DD")}T${dayjs(dueTime).format("HH:mm:ss")}`;

    const createData: any = {
      dueDate: formattedDate,
      assigneeId: assignee?.value,
      prospectId: prospect?.value,
      dealId: deal?.value,
      status: LookupTasksRequestStatusEnum.Due,
      type: type.value as TaskRequestTypeEnum,
      attachments: data.attachments?.map((attachment) => ({
        media: attachment.media,
        name: attachment.name,
      })),
    };

    try {
      dispatch(createTask({ newTask: createData }));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <CreateTaskFormValues
          drawerTitle={t("tasks.createTask")}
          onSubmit={onSubmit}
          actionButtonLabel={t("tasks.createTask")}
        />
      </FormProvider>
    </>
  );
}
