import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import EditProspectForm from "@/pages/prospects/edit/EditProspectForm";
import { closeEditDrawer } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppSelector } from "@/redux/store";
import { useAppDispatch } from "@/redux/store";

export default function EditProspectDrawer() {
  const { t } = useTranslation();
  const { editDrawerIsOpen } = useAppSelector(({ prospects }) => prospects.listProspects);
  const dispatch = useAppDispatch();

  return (
    <Drawer open={editDrawerIsOpen} onClose={() => dispatch(closeEditDrawer())} anchor="right">
      <EditProspectForm />
    </Drawer>
  );
}
