import { Badge, Box } from "@mui/material";
import Button from "@/common/components/button";
import { useNavigate } from "react-router";
import { getCoreRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import { SequenceColumns } from "./table/columns";

import SequenceFilters from "./filters";
import SequenceTable from "./table";
import { Sequence } from "@/types/sequence";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getSequencesTemplates, setCurrentSequence } from "@/redux/reducers/sequences/slices/listSequences";
import routes from "@/common/constants/routes";
import PageHeader from "@/common/components/page-header/PageHeader";

export default function SequenceBuilderPage() {
  const sequencesLoading = useAppSelector((selector) => selector.sequences.listSequences.loading);
  const sequences = useAppSelector((selector) => selector.sequences.listSequences.sequences);
  const [filters, setFilters] = useState({});
  const dispatch = useAppDispatch();
  const table = useReactTable<Sequence>({
    data: sequences || [],
    columns: SequenceColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(setCurrentSequence({ sequence: null }));
    navigate(routes.sequence.create.path);
  };

  useEffect(() => {
    if (sequences === undefined && !sequencesLoading) {
      dispatch(getSequencesTemplates());
    }
  }, [dispatch, sequencesLoading, sequences]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PageHeader
        title="Sequences"
        badge={<Badge badgeContent={sequences?.length} max={999} color="primary" />}
        actions={
          <Button variant="primary" onClick={handleNavigate}>
            Create Sequence
          </Button>
        }
        filters={<SequenceFilters filters={filters} setFilters={setFilters} />}
      />

      <SequenceTable table={table} />
    </Box>
  );
}
