import { useCallback } from "react";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { PaperClipOutline } from "heroicons-react";
import { Typography } from "@mui/material";
import { ReactComponent as TrashIcon } from "@/common/assets/svg/trash_icon.svg";

export default function AttachmentInput({
  value = [],
  onChange,
  disabled,
}: {
  value: any[];
  onChange: (value: any[]) => void;
  disabled?: boolean;
}) {
  const theme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (disabled) return;

      const allFiles = acceptedFiles.map((file) => ({
        media: file,
        name: file.name,
      }));

      onChange([...value, ...allFiles]);
    },
    [value, onChange, disabled],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          padding: "12px 16px",
          border: `1px solid ${theme.palette.commonColors.light}`,
          borderRadius: "8px",
          fontSize: "14px",
          color: theme.palette.txt.placeholder,
          background: disabled ? "#F8F9FA" : "transparent",
        }}
      >
        <input {...getInputProps()} disabled={disabled} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Select</span>
          <PaperClipOutline
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </div>
      </Box>
      {value && value.length > 0 && (
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            marginTop: "8px",
            flexWrap: "wrap",
            marginLeft: "8px",
            flexDirection: "column",
          }}
        >
          {value.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
              }}
            >
              <Typography variant="link" sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <PaperClipOutline size={18} />
                {file.name}
              </Typography>

              <TrashIcon
                width={16}
                height={16}
                color={theme.palette.icon.danger}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (disabled) return;
                  const newFiles = value.filter((_, i) => i !== index);
                  onChange(newFiles);
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
