import * as React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Route, Routes } from "react-router";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import routes from "@/common/constants/routes";
import Onboarding from "@/pages/onboarding";
import Layout from "@/layouts/layout";
import store from "@/redux/store";
import { Provider } from "react-redux";
import TriggersLayout from "@/layouts/triggers/TriggersLayout";
import DataImportLayout from "@/layouts/data-import/DataImportLayout";
import NewDataImport from "@/pages/data-import/new-import";
import {
  TasksPage,
  TriggersPage,
  CreateTriggerPage,
  ProspectsPage,
  DealFlowPage,
  DealFlowActionsPage,
  DealFlowProductLibraryPage,
  SequenceBuilderPage,
  InboxPage,
  ProspectFinder,
  ProspectCompanies,
  SettingsPage,
  MainDashboardPage,
  DataImportPage,
  AiLearningPage,
  CreateSequencePage,
} from "@/pages";
import TermsOfServicePage from "@/pages/onboarding/steps/terms-of-service";
import ProvidersWrapper from "./providers/Wrapper";
import { ConfirmProvider } from "material-ui-confirm";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "swiper/css";
import "swiper/css/grid";
import "./common/components/custom-date-picker/CustomDatePicker.css";
import "react-toastify/dist/ReactToastify.css";
import LeadFlowPage from "./pages/lead-flow";
import LeadFlowConfigPage from "@/pages/lead-flow/configure";
import UpdateTriggerPage from "@/pages/triggers/edit/UpdateTrigger";
import OauthCallback from "./pages/oauth-callback";
import AiAgentsPage from "@/pages/ai-voice/ai-agents";
import CallLogsPage from "@/pages/ai-voice/call-logs";
import AiAgentEdit from "@/pages/ai-voice/ai-agent-edit";
import CompanySettings from "@/pages/onboarding/steps/company";
import BillingSettings from "./pages/onboarding/steps/billing";
import { TermsOfServiceGuard } from "@/common/components/route-guard/TermsOfServiceGuard";
import { TeamGuard } from "@/common/components/route-guard/TeamGuard";
import { BillingGuard } from "@/common/components/route-guard/BillingGuard";
import { AuthenticationGuard } from "@/common/components/route-guard/AuthenticationGuard";
import { useEffect } from "react";
import userflow from "userflow.js";
import config from "@/common/constants/config";

const UnhandledError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const errorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

const App: React.FC = () => {
  useEffect(() => {
    userflow.init(config.userflowToken);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={UnhandledError} onError={errorHandler}>
      <ToastContainer />
      <ProvidersWrapper>
        <ConfirmProvider>
          <Router>
            <Provider store={store}>
              <Routes>
                <Route path={routes.oauth.callback.path} element={<OauthCallback />} />

                <Route
                  path={routes.onboard.path}
                  element={
                    <AuthenticationGuard>
                      <Onboarding />
                    </AuthenticationGuard>
                  }
                >
                  <Route path={routes.onboard.termsOfService.path} element={<TermsOfServicePage />} />
                  <Route
                    path={routes.onboard.team.path}
                    element={
                      <TermsOfServiceGuard>
                        <CompanySettings />
                      </TermsOfServiceGuard>
                    }
                  />
                  <Route
                    path={routes.onboard.billing.path}
                    element={
                      <TermsOfServiceGuard>
                        <TeamGuard>
                          <BillingSettings />
                        </TeamGuard>
                      </TermsOfServiceGuard>
                    }
                  />
                </Route>

                <Route
                  path={routes.root.path}
                  element={
                    <AuthenticationGuard>
                      <TermsOfServiceGuard>
                        <TeamGuard>
                          <BillingGuard>
                            <Layout />
                          </BillingGuard>
                        </TeamGuard>
                      </TermsOfServiceGuard>
                    </AuthenticationGuard>
                  }
                >
                  <Route index element={<MainDashboardPage />} />

                  <Route path={routes.tasks.path} element={<TasksPage />} />

                  <Route path={routes.triggers.path} element={<TriggersLayout />}>
                    <Route path={routes.triggers.table.path} element={<TriggersPage />} />
                    <Route path={routes.triggers.create.path} element={<CreateTriggerPage />} />
                    <Route path={routes.triggers.update.path} element={<UpdateTriggerPage />} />
                  </Route>

                  <Route path={routes.prospects.path}>
                    <Route path={routes.prospects.table.path} element={<ProspectsPage />} />
                    <Route path={routes.prospects.companies.path} element={<ProspectCompanies />} />
                    <Route path={routes.prospects.prospectFinder.path} element={<ProspectFinder />} />
                  </Route>

                  <Route path={routes.dealFlow.path}>
                    <Route path={routes.dealFlow.table.path} element={<DealFlowPage />} />
                    <Route path={routes.dealFlow.update.path} element={<DealFlowActionsPage />} />
                    <Route path={routes.dealFlow.create.path} element={<DealFlowActionsPage />} />
                    <Route path={routes.dealFlow.productLibrary.path} element={<DealFlowProductLibraryPage />} />
                    <Route path={routes.dealFlow.details.path} element={<DealFlowPage />} />
                  </Route>

                  <Route path={routes.leadFlow.path}>
                    <Route path={routes.leadFlow.dashboard.path} element={<LeadFlowPage />} />
                    <Route path={routes.leadFlow.setup.path} element={<LeadFlowConfigPage />} />
                  </Route>

                  <Route path={routes.sequence.path}>
                    <Route path={routes.sequence.table.path} element={<SequenceBuilderPage />} />
                    <Route path={routes.sequence.create.path} element={<CreateSequencePage />} />
                    <Route path={routes.sequence.update.path} element={<CreateSequencePage />} />
                  </Route>

                  <Route path={routes.inbox.path} element={<InboxPage />} />

                  <Route path={routes.dataImport.path} element={<DataImportLayout />}>
                    <Route path={routes.dataImport.table.path} element={<DataImportPage />} />
                    <Route path={routes.dataImport.import.path} element={<NewDataImport />} />
                  </Route>

                  <Route path={routes.aiLearning.path} element={<AiLearningPage />} />

                  <Route path={routes.aiVoice.path}>
                    <Route path={routes.aiVoice.callLogs.path} element={<CallLogsPage />}>
                      <Route path={routes.aiVoice.callLogs.id.path} element={<CallLogsPage />} />
                    </Route>
                    <Route path={routes.aiVoice.agents.path}>
                      <Route path={routes.aiVoice.agents.table.path} element={<AiAgentsPage />} />
                      <Route path={routes.aiVoice.agents.create.path} element={<AiAgentEdit />} />
                      <Route path={routes.aiVoice.agents.edit.path} element={<AiAgentEdit />} />
                    </Route>
                  </Route>

                  <Route path={routes.settings.path} element={<SettingsPage />} />
                </Route>

                <Route path={routes.path} element={<Navigate to={routes.path} />} />
              </Routes>
            </Provider>
          </Router>
        </ConfirmProvider>
      </ProvidersWrapper>
    </ErrorBoundary>
  );
};

export default App;
