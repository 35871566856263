import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import Button from "@/common/components/button";
import { useNavigate, useParams } from "react-router-dom";
import Stepper from "@/common/components/stepper/Stepper";
import { useForm, FormProvider } from "react-hook-form";
import { ObjectStep } from "@/pages/triggers/edit/steps/ObjectStep";
import React from "react";
import LeadTriggers from "@/pages/triggers/edit/steps/event/lead";
import Index from "@/pages/triggers/edit/steps/event/deal";
import { Badge } from "@mui/material";
import { ArrowRightOutline } from "heroicons-react";
import ActionStep from "@/pages/triggers/edit/steps/action";
import OverviewStep from "@/pages/triggers/edit/steps/OverviewStep";
import { ListByEventTypeEventEnum } from "@/services/generated";
import { useEffect } from "react";
import { setCurrentTrigger } from "@/redux/reducers/triggers/slices/listTriggers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { updateTrigger } from "@/redux/reducers/triggers/slices/updateTrigger";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import routes from "@/common/constants/routes";
import { success } from "@/lib/toast";
import { TriggerForm } from "@/pages/triggers/edit/types";

export enum TriggersType {
  LEAD = "LEAD",
  DEAL = "DEAL",
}

export enum DealFlowOptions {
  ALL = "All",
  SPECIFIC_DEAL_FLOW = "Specific Deal Flow",
}

export default function UpdateTrigger() {
  const { triggerId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { currentTrigger, updateTriggerPending } = useAppSelector(({ triggers }) => triggers.listTriggers as any);

  const methods = useForm<TriggerForm>({
    defaultValues: {
      name: "",
      type: TriggersType.LEAD,
      event: {
        eventType: "",
        eventTriggerType: TriggersType.DEAL,
        dealFlowIdType: DealFlowOptions.ALL,
        dealFlowId: DealFlowOptions.ALL,
      },
      action: {
        actionType: "",
        receiver: [],
        message: "",
        pipelineId: "",
        pipelineStageId: "",
      },
      deleted: false,
    },
  });

  const { getValues, setError } = methods;

  const [activeStep, setActiveStep] = React.useState<{
    idx: number;
    label: string;
  }>({ idx: 0, label: "Type" });

  const handleCancelImport = () => {
    navigate(routes.triggers.table.path);
  };

  const onSubmit = (data: TriggerForm) => {
    const id = triggerId!;

    try {
      dispatch(updateTrigger({ id, data })).then(() => {
        navigate(routes.triggers.table.path);
        success("Trigger updated successfully");
      });
    } catch (error) {
      return;
    }
  };

  const goToNextStep = () => {
    if (activeStep.idx === 0 && getValues("name") === undefined) {
      setError("name", { type: "required", message: "Name is required" });
    }

    if (activeStep.idx === 3) return;
    setActiveStep((prevActiveStep) => {
      return { ...prevActiveStep, idx: prevActiveStep.idx + 1 };
    });
  };

  const goToPreviousStep = () => {
    if (activeStep.idx === 0) return;
    setActiveStep((prevActiveStep) => {
      return { ...prevActiveStep, idx: prevActiveStep.idx - 1 };
    });
  };

  useEffect(() => {
    if (!triggerId) return;
    dispatch(setCurrentTrigger(triggerId));
  }, [dispatch, triggerId]);

  useEffect(() => {
    if (!currentTrigger) return;

    methods.reset({
      ...currentTrigger,
      type:
        currentTrigger?.event?.eventType?.includes(ListByEventTypeEventEnum.DealAssigneeChange) ||
        currentTrigger?.event?.eventType?.includes(ListByEventTypeEventEnum.DealFlowStateChange)
          ? TriggersType.DEAL
          : TriggersType.LEAD,
      selectedEventType: currentTrigger?.event?.eventType,
      event: {
        ...currentTrigger.event,
        leadSource: currentTrigger.event?.leadSource || "All",
      },
      action: {
        ...currentTrigger.action,
      },
    });
  }, [methods, currentTrigger]);

  const { watch } = methods;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
          <Typography fontSize="24px" fontWeight="bold">
            Update Trigger
          </Typography>
          <Badge
            badgeContent={400}
            max={999}
            color="primary"
            sx={{
              background: theme.palette.mint.p,
              color: theme.palette.txt.default,
            }}
          />
        </Box>

        <Button data-testid="openTaskDrawer" variant="secondary" onClick={handleCancelImport}>
          Cancel
        </Button>
      </Stack>

      <FullLoadingOverlay loading={updateTriggerPending} overlay />

      <Stack
        direction="column"
        sx={{
          background: "#fff",
          padding: "20px",
        }}
      >
        <Box>
          <Stepper
            steps={[
              { idx: 0, label: "Object" },
              { idx: 1, label: "Event" },
              { idx: 2, label: "Action" },
              { idx: 3, label: "Overview" },
            ]}
            activeStep={activeStep.idx}
          />
        </Box>

        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Stack
              direction="column"
              sx={{
                background: "#fff",
                padding: "42px 24px",
                maxWidth: "1500px",
                margin: "0 auto",
              }}
            >
              {activeStep.idx === 0 && <ObjectStep />}
              {activeStep.idx === 1 ? watch("type") === TriggersType.LEAD ? <LeadTriggers /> : <Index /> : null}
              {activeStep.idx === 2 && <ActionStep />}
              {activeStep.idx === 3 && <OverviewStep />}
            </Stack>

            <Divider sx={{ margin: "20px 0px" }} />

            <Box
              sx={{
                display: "flex",
                justifyContent: activeStep.idx === 0 ? "flex-end" : "space-between",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              {activeStep.idx > 0 && (
                <Button variant="secondary" type="button" onClick={goToPreviousStep}>
                  Back
                </Button>
              )}

              {activeStep.idx < 3 && (
                <Button
                  rightIcon={<ArrowRightOutline size={16} />}
                  variant="primary"
                  type="button"
                  onClick={goToNextStep}
                >
                  Next
                </Button>
              )}
              {activeStep.idx === 3 && (
                <Button rightIcon={<ArrowRightOutline size={16} />} variant="primary" type="submit">
                  Save Trigger
                </Button>
              )}
            </Box>
          </FormProvider>
        </form>
      </Stack>
    </>
  );
}
