import { Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";

import { DataImportJob } from "@/types/data-import";
import DataImportChip from "./chips";
import { dateTimeFormat } from "@/common/functions/dateFormat";

const columnHelper = createColumnHelper<DataImportJob>();

export const DataImportColumns = [
  columnHelper.accessor("importJobName", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        IMPORT NAME
      </Typography>
    ),
    cell: (info) => <Typography variant="body2">{info.getValue() || "Test"}</Typography>,
  }),

  columnHelper.accessor("importJobType", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        OBJECT
      </Typography>
    ),
    cell: (info) => <DataImportChip status={info.getValue() || ""} />,
  }),

  columnHelper.accessor("createdDocumentResultCount", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        NEW RECORDS
      </Typography>
    ),
    cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
  }),

  columnHelper.accessor("updatedDocumentResultCount", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        UPDATED RECORDS
      </Typography>
    ),
    cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
  }),

  columnHelper.accessor("errorDocumentResultCount", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        ERROR COUNT
      </Typography>
    ),
    cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
  }),

  columnHelper.accessor("createdAt", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        IMPORTED ON
      </Typography>
    ),
    cell: (info) => {
      const seconds = info.getValue()?.seconds || 0;
      const nanos = info.getValue()?.nanos || 0;
      const formatedDate = dateTimeFormat(seconds, nanos);

      return <Typography variant="body2">{formatedDate}</Typography>;
    },
  }),
];
