import { Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const CreateFormOptions = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Facebook Lead Ads",
    value: "FACEBOOK_LEAD_ADS",
  },
  {
    label: "Google Form",
    value: "GOOGLE_FORM",
  },
  {
    label: "Hubspot 2-Way Sync",
    value: "HUBSPOT_2_WAY_SYNC",
  },
  {
    label: "Prospect Finder",
    value: "PROSPECT_FINDER",
  },
];

export const ContactCreatedForm = () => {
  const methods = useFormContext();
  const { control } = methods;

  return (
    <Controller
      name="event.leadSource"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          {CreateFormOptions.map((option) => {
            return (
              <label
                key={option.value}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  border: "1px solid #E0E0E0",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <Typography variant="body2">{option.label}</Typography>

                <input
                  type="radio"
                  className="radio"
                  checked={field.value === option.value}
                  onChange={() => {
                    field.onChange(option.value);
                  }}
                />
              </label>
            );
          })}
        </>
      )}
    />
  );
};
