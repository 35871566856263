import { useCallback } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Switch from "@/common/components/switch/Switch";
import Typography from "@mui/material/Typography";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import { updateTrigger } from "@/redux/reducers/triggers/slices/updateTrigger";
import TableActionButton from "./ActionButton";
import { Trigger } from "@/services/generated";
import { ListByEventTypeEventEnum } from "@/services/generated";
import { useAppDispatch } from "@/redux/store";
import { success } from "@/lib/toast";
import { ActionsType } from "@/pages/triggers/edit/steps/action";
import TableDate from "@/common/components/table-date";

const columnHelper = createColumnHelper<Trigger>();

const mapEventType = (eventType: string) => {
  switch (eventType) {
    case ListByEventTypeEventEnum.DealFlowStateChange:
      return "Deal Flow State Change";
    case ListByEventTypeEventEnum.MeetingStateChange:
      return "Meeting State Change";
    case ListByEventTypeEventEnum.ProspectEngagement:
      return "Prospect Engagement";
    case ListByEventTypeEventEnum.DealAssigneeChange:
      return "deal Assignee Change";
    case ListByEventTypeEventEnum.NewContact:
      return "New Contact";
    default:
      return "";
  }
};

const mapActionType = (actionType: string) => {
  switch (actionType) {
    case ActionsType.CREATE_TASK:
      return "Create Task";
    case ActionsType.ENROLL_SEQUENCE:
      return "Enroll Sequence";
    case ActionsType.SET_DEAL_FLOW_STAGE:
      return "Set deal Flow Stage";
    case ActionsType.CANCEL_SEQUENCE:
      return "Cancel Sequence";
    case ActionsType.CREATE_DEAL:
      return "Create deal";
    case ActionsType.SEND_EMAIL:
      return "Send Email";
    case ActionsType.SEND_SMS:
      return "Send SMS";
    default:
      return "";
  }
};

export const TriggerColumns = () => {
  const dispatch = useAppDispatch();

  const toggleTrigger = useCallback(
    ({ id, data }: { id: string; data: Trigger }) => {
      dispatch(updateTrigger({ id, data })).then(() => {
        success("Trigger updated successfully");
      });
    },
    [dispatch],
  );

  return [
    columnHelper.accessor("name", {
      header: ({ table }: any) => (
        <div style={{ display: "flex", gap: "18px", alignItems: "center", marginLeft: "16px" }}>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
          <Typography variant="body3">TRIGGER NAME</Typography>
        </div>
      ),

      cell: ({ row }: any) => {
        return (
          <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            <Typography variant="body2">{row.original.name}</Typography>
          </div>
        );
      },
    }),

    columnHelper.accessor("event", {
      header: () => <Typography variant="body3">Event</Typography>,
      cell: ({ row }: any) => {
        return <Typography variant="body2">{mapEventType(row.original.event.eventType)}</Typography>;
      },
    }),

    columnHelper.accessor("action", {
      header: () => <Typography variant="body3">Action</Typography>,
      cell: ({ row }: any) => {
        return <Typography variant="body2">{mapActionType(row.original.action.actionType)}</Typography>;
      },
    }),

    columnHelper.accessor("status", {
      header: () => <Typography variant="body3">Status</Typography>,
      cell: ({ row }: any) => {
        return (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Switch
              value={row.original.active}
              onChange={(e, checked) => {
                toggleTrigger({ id: row.original.id, data: { ...row.original, isActive: checked } });
              }}
            />
            <Typography variant="body2">{row.original.active ? "Active" : "Inactive"}</Typography>
          </div>
        );
      },
    }),

    columnHelper.accessor("triggered", {
      header: () => <Typography variant="body3">Triggered</Typography>,
      cell: ({ row }) => {
        return <Typography variant="body2">{row.original.executionCount || 0}</Typography>;
      },
    }),

    columnHelper.accessor("lastEdited", {
      header: () => <Typography variant="body3">Last Edited</Typography>,
      cell: ({ row }) => {
        return <TableDate date={row.original.updatedAt} />;
      },
    }),

    columnHelper.display({
      id: "actions",
      header: () => <Typography variant="body3">Actions</Typography>,
      cell: (props) => <TableActionButton params={props} />,
    }),
  ];
};
