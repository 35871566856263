import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import { useMemo } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { useAppSelector } from "@/redux/store";
import Input from "@/common/components/input";
import { useFormContext } from "react-hook-form";

export default function NewDealForm() {
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);
  const { control, watch } = useFormContext();

  const pipelineId = watch("pipelineId");

  const currentPipeline = useMemo(() => {
    if (!pipelineId) return;
    return pipelines?.find(({ id }) => id === pipelineId);
  }, [pipelines, pipelineId]);

  const stageOptions = (currentPipeline?.stages || []).map((stage) => ({ value: stage.id, label: stage.name }));

  const pipelinesOptions = useMemo(
    () =>
      (pipelines || []).map((pipeline) => ({
        value: pipeline.id.toString(),
        label: pipeline.name,
      })),
    [pipelines],
  );

  const assigneesOptions = useMemo(
    () =>
      (teamUsers || []).map((assignee) => ({
        // icon: () => <Avatar src={assignee.avatar} size="small" />,
        value: assignee.id!,
        label: assignee.firstName! + " " + assignee.lastName!,
      })),
    [teamUsers],
  );

  return (
    <>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.name"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel aria-required label="Deal Name" />
              <Input placeholder="Deal Name" value={field.value} onChange={field.onChange} />
            </>
          )}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.pipelineId"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel aria-required label="Pipeline" />
              <Dropdown
                label="Sales Pipeline"
                placeholder="Sales Pipeline"
                defaultValue={pipelinesOptions.find((option) => option.value === field.value || "")?.value}
                value={pipelinesOptions.find((option) => option.value === field.value)?.value}
                options={pipelinesOptions}
                onChange={(option) => {
                  field.onChange(option.value);
                }}
              />
            </>
          )}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.pipelineStageId"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel aria-required label="Deal Stage" />
              <Dropdown
                label="Deal Stage"
                placeholder="Select deal stage"
                defaultValue={stageOptions.find((option) => option.value === field.value)?.value}
                value={stageOptions.find((option) => option.value === field.value)?.value}
                options={stageOptions}
                onChange={(option) => {
                  field.onChange(option.value);
                }}
              />
            </>
          )}
        />
      </FormControl>

      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.assigneeId"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel aria-required label="Deal Owner" />
              <Dropdown
                label="Deal Owner"
                placeholder="Select deal owner"
                options={assigneesOptions}
                value={assigneesOptions.find((option) => option.value === field.value)?.value}
                onChange={(option) => {
                  field.onChange(option.value);
                }}
              />
            </>
          )}
        />
      </FormControl>
    </>
  );
}
