import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { TaskStatusSelectOptions, TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { CustomDatePicker } from "@/common/components/custom-date-picker/CustomDatePicker";
import { FormControl } from "@mui/material";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { debounce } from "lodash";
import { useMemo } from "react";
import Button from "@/common/components/button/Button";
import { getTasks } from "@/redux/reducers/tasks/slices/listTask";

export default function TaskFilters({ filters, setFilters }: { filters: any; setFilters: any }) {
  const { totalCount, loadingTasks } = useAppSelector(({ tasks }) => tasks.listTask);
  const dispatch = useAppDispatch();

  const handleResetFilters = () => {
    dispatch(getTasks());
  };

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev: any) => ({ ...prev, prospectName: value }));
      }, 500),
    [setFilters],
  );

  if (loadingTasks || totalCount === 0)
    return (
      <>
        <div style={{ width: "100%" }}>
          <Button variant="secondary" onClick={handleResetFilters} size="sm">
            Reset filters
          </Button>
        </div>
      </>
    );

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr repeat(3, 0.25fr)",
          gap: "16px",
        }}
      >
        <Searchbar
          placeholder="Search for a prospect"
          onChange={({ target: { value } }: any) => {
            debouncedSetFilters(value);
          }}
        />

        <Box>
          <Dropdown
            label="Task Type"
            placeholder="Task Type"
            options={[
              {
                label: "All",
                value: "",
              },
              ...TaskTypeSelectOptions,
            ]}
            onChange={({ value }: any) => {
              setFilters((prev: any) => ({ ...prev, type: value }));
            }}
          />
        </Box>

        <Box>
          <Dropdown
            label="Status"
            placeholder="Status"
            options={[
              {
                label: "All",
                value: "",
              },
              ...TaskStatusSelectOptions.map((option) => ({
                label: option.label.toLocaleLowerCase().replace(/^\w/, (c) => c.toUpperCase()),
                value: option.value,
              })),
            ]}
            onChange={({ value }: any) => {
              setFilters((prev: any) => ({ ...prev, status: value }));
            }}
          />
        </Box>
        <Box>
          <FormControl sx={{ width: "100%" }}>
            <CustomDatePicker
              onChange={(date) => {
                setFilters((prev: any) => ({
                  ...prev,
                  dueDate: date ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss") : null,
                }));
              }}
              value={filters?.dueDate ? dayjs(filters.dueDate) : null}
            />
          </FormControl>
        </Box>
      </Box>
    </form>
  );
}
