import FormLabel from "@/common/components/form-label/FormLabel";
import FormControl from "@mui/material/FormControl";
import { Box, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Input from "@/common/components/input";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { activateCode, getBillingInfo } from "@/redux/reducers/settings/billing";
import _ from "lodash";

interface PrepaidFormProps {
  setCanContinue?: (value: boolean) => void;
}

export default function PrepaidForm({ setCanContinue }: PrepaidFormProps) {
  const { control } = useForm();
  const [isValidCode, setIsValidCode] = useState<boolean | undefined>();
  const team = useAppSelector(({ settings }) => settings.team.team);
  const dispatch = useAppDispatch();

  const debouncedPrepaidCode = _.debounce((teamId: string, code: string) => {
    setIsValidCode(undefined);
    if (code) {
      dispatch(activateCode({ code, teamId }))
        .unwrap()
        .then(() => {
          dispatch(getBillingInfo(teamId));
          setIsValidCode(true);
        })
        .catch(() => {
          setIsValidCode(false);
        });
    }
  }, 500);

  useEffect(() => {
    setCanContinue && setCanContinue(isValidCode || false);
  }, [isValidCode, setCanContinue]);

  if (isValidCode) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "50%",
      }}
    >
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <FormControl sx={{ width: "100%" }}>
          <Controller
            control={control}
            name="code"
            render={({ field, fieldState }) => (
              <>
                <FormLabel aria-required label="Enter your prepaid code below" />
                <Input
                  {...field}
                  type="text"
                  placeholder="0 0 0 0 0 0 0 - 0 0 0 0"
                  error={!isValidCode ? "Invalid Code" : fieldState?.error?.message}
                  onChange={(e) => {
                    if (team?.id) {
                      debouncedPrepaidCode(team.id, e.target.value);
                    }
                  }}
                />
              </>
            )}
          />
        </FormControl>
      </Stack>
    </Box>
  );
}
