import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import { getProducts } from "@/redux/reducers/productLibrary/slices/listProducts";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  ButtonBase,
  FormControl,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { MinusCircleOutline, PlusCircleOutline } from "heroicons-react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";

type LineItemFormProps = {
  onChangeLineItems: (lineItems: LineItemType[]) => void;
  lineItems?: LineItemType[];
  errors?: any;
};

type LineItemType = {
  id?: string;
  productId?: string;
  name?: string;
  price?: number;
  quantity: number;
};

const LINE_ITEMS_COLUMNS: {
  id: keyof LineItemType;
  name: string;
  align?: "center" | "left" | "right";
  format?: (value: string | number) => string;
}[] = [
  { id: "name", name: "ITEM NAME", align: "left" },
  { id: "quantity", name: "QUANTITY", align: "center" },
  { id: "price", name: "PRICE", format: (value: number) => `$${value.toFixed(2)}` },
];

export default function LineItemForm({ onChangeLineItems, lineItems, errors }: LineItemFormProps) {
  const products = useAppSelector(({ productLibrary }) => productLibrary.listProducts.products);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [quantity, setQuantity] = useState<number>(1);
  const [productId, setProductId] = useState<string>("");
  const [currentLineItems, setCurrentLineItems] = useState<LineItemType[]>(lineItems || []);

  useEffect(() => {
    if (products === undefined) {
      dispatch(getProducts());
    }
  }, [dispatch, products]);

  const productOptions = useMemo(
    () =>
      (products || [])
        .filter((product) => !currentLineItems.find((lineItem) => lineItem.productId === product.id))
        .map((product) => ({
          value: product.id,
          label: product.name,
        })),
    [products, currentLineItems],
  );

  const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuantity(+event.target.value);
  };

  const handleAddNewLineItem = () => {
    if (!productId) {
      return;
    }
    if (!!currentLineItems.find((lineItem) => lineItem.productId === productId)) {
      return;
    }
    const product = products?.find((product) => product.id === productId);
    const newNewLineItem = { productId, quantity, name: product?.name, price: product?.price || 0 * quantity };
    const updatedLineItems = [...currentLineItems, newNewLineItem];
    setCurrentLineItems(updatedLineItems);
    onChangeLineItems(updatedLineItems);
  };

  const handleRemoveLineItem = (index: number) => {
    const updatedLineItems = [...currentLineItems];
    updatedLineItems.splice(index, 1);
    setCurrentLineItems(updatedLineItems);
    onChangeLineItems(updatedLineItems);
  };

  return (
    <>
      <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
        <FormControl sx={{ width: "100%" }}>
          <FormLabel aria-required label="Add Line Item" />
          <Dropdown
            placeholder="Select Line Item"
            options={productOptions}
            onChange={(option) => {
              setProductId(option.value);
            }}
            defaultValue={productOptions.find((option) => option.value === productId)?.value}
            value={productOptions.find((option) => option.value === productId)?.value}
            error={errors?.productId?.message}
          />
        </FormControl>
        <FormControl>
          <FormLabel aria-required label="Quantity" />
          <Input value={quantity} onChange={handleChangeQuantity} type="number" />
        </FormControl>
        <FormControl sx={{ height: "min-content", display: "flex", alignSelf: "end", p: 1 }}>
          <ButtonBase onClick={() => handleAddNewLineItem()}>
            <PlusCircleOutline size={36} />
          </ButtonBase>
        </FormControl>
      </Stack>
      <FormControl>
        {currentLineItems.length > 0 && (
          <TableContainer sx={{ mt: 2, border: "1px solid #E7EBED", borderRadius: "8px", overflow: "hidden" }}>
            <Table>
              <TableHead sx={{ backgroundColor: theme.palette.tableRow.heading }}>
                <TableRow>
                  {LINE_ITEMS_COLUMNS.map((column, index) => (
                    <TableCell align={column.align} sx={{ fontWeight: 600 }} key={column.id}>
                      {column.name}
                    </TableCell>
                  ))}
                  <TableCell align="center">ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentLineItems.map((lineItem, index) => (
                  <TableRow
                    key={lineItem.productId}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#FFFFFF",
                      },
                      "&:nth-of-type(even)": {
                        backgroundColor: "#FBFBFB",
                      },
                    }}
                  >
                    {LINE_ITEMS_COLUMNS.map((column, index) => (
                      <TableCell align={column.align} key={column.id}>
                        {column.format ? column.format(lineItem[column.id] ?? 0) : lineItem[column.id]}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <ButtonBase onClick={() => handleRemoveLineItem(index)}>
                        <MinusCircleOutline />
                      </ButtonBase>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </FormControl>
    </>
  );
}
