import Accordion from "@/common/components/accordion";
import Avatar from "@/common/components/avatar";
import { TitleAndSubtitle } from "@/common/components/prospect-info/ProspectInfo";
import AudioPlayer from "@/pages/inbox/AudioPlayer";
import { useAppSelector } from "@/redux/store";
import { KnCallMessage } from "@/services/generated";
import { capitalize } from "@/utils/capitalize";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { Box, Divider, Link, List, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { CALL_FLOW_OUTCOME_OPTIONS, CALL_FLOW_TYPE_OPTIONS } from "../../constants";

export default function CallSummary() {
  const { call, callSummary } = useAppSelector(({ aiVoice }) => aiVoice.listCalls);

  const {
    call_began_time,
    call_ended_time,
    call_direction = "",
    call_flow,
    call_outcome,
    call_recording_url = "",
    contact,
  } = call || {};
  const {
    firstName = "",
    lastName = "",
    email = "",
    companyName = "",
    jobTitle = "",
    timezone = "",
    phoneNumber = "",
  } = contact || {};
  const theme = useTheme();
  const durationInMs = call_began_time && call_ended_time ? call_ended_time - call_began_time : 0;
  const callDuration = dayjs.duration(durationInMs).format("m[m] s[s]");
  const callDate = dayjs(call_began_time).format("MMM DD, YYYY h:mm A");

  const message = (message: KnCallMessage) => {
    return (
      <Box>
        <Typography color={theme.palette.txt.placeholder} fontSize={12}>
          [{dayjs(message.created_at).format("h:mm A")}]
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          <Typography fontSize={12} fontWeight={500}>
            {capitalize(message.user || "Test")}:
          </Typography>
          <Typography fontSize={12}>{message.text}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: 985, bgcolor: "background.paper" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 450px",
          gap: 2,
        }}
      >
        <Box>
          <AudioPlayer url={call_recording_url} />

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
            <Box>
              <Typography fontWeight={500} fontSize={12}>
                CALL DATE AND TIME
              </Typography>
              <Typography fontSize={14}>{callDate}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={500} fontSize={12}>
                CALL DURATION
              </Typography>
              <Typography fontSize={14}>{callDuration}</Typography>
            </Box>
            <Link
              href={call_recording_url}
              sx={{
                textDecoration: "none !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  lineHeight: 12,
                  gap: 1,
                }}
              >
                <Typography sx={{ color: theme.palette.commonColors.informative }} fontSize={16}>
                  Download Audio
                </Typography>
                <CloudArrowDownIcon height={24} color={theme.palette.commonColors.informative} />
              </Box>
            </Link>
          </Box>

          <Divider />

          <Box>
            <List>{callSummary?.map((m) => message(m))}</List>
          </Box>
        </Box>
        <Stack
          spacing={2}
          sx={({ palette }) => ({
            background: palette.midnight[20],
            width: "100%",
            overflowY: "scroll",
            flexDirection: "column",
            padding: 2,
            borderRadius: 2,
            height: "85vh",
          })}
        >
          {contact && (
            <Accordion summaryTitle="Prospect" defaultExpanded id="Prospect">
              <Stack gap={1}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar size="small" label={`${firstName[0]} ${lastName[0]}`} />

                  <Typography>{`${firstName} ${lastName}`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      p: 0.5,
                      backgroundColor: theme.palette.commonColors.light,
                      lineHeight: 0,
                      borderRadius: 1,
                    }}
                  >
                    <EnvelopeIcon width={16} height={16} color={theme.palette.midnight.p} />
                  </Box>
                  <Typography>{email}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box
                    sx={{
                      p: 0.5,
                      backgroundColor: theme.palette.commonColors.light,
                      lineHeight: 0,
                      borderRadius: 1,
                    }}
                  >
                    <PhoneIcon width={16} height={16} />
                  </Box>
                  <Typography>{phoneNumber}</Typography>
                </Box>

                <TitleAndSubtitle title="COMPANY" subtitle={companyName} />
                <TitleAndSubtitle title="JOB TITLE" subtitle={jobTitle} />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <TitleAndSubtitle title="DATE CREATED" subtitle={dayjs(call_began_time).format("MMM DD, YYYY")} />
                  <TitleAndSubtitle
                    title="OPT IN LEVEL"
                    subtitle={
                      <Box
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: "white",
                          borderRadius: "50%",
                          width: 24,
                          height: 24,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography fontSize={12}>L1</Typography>
                      </Box>
                    }
                  />
                </Box>
                <TitleAndSubtitle title="TIMEZONE" subtitle={timezone} />
              </Stack>
            </Accordion>
          )}

          <Accordion summaryTitle="Basic Call Details" defaultExpanded id="Basic-Call-Details">
            <Stack gap={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TitleAndSubtitle title="CALL DATE AND TIME" subtitle={callDate} />
                <TitleAndSubtitle title="CALL DURATION" subtitle={callDuration} />
              </Box>

              <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                <TitleAndSubtitle
                  title="CALL OUTCOME"
                  subtitle={call_outcome ? CALL_FLOW_OUTCOME_OPTIONS[call_outcome].label : ""}
                  customWidth="70%"
                />
                <TitleAndSubtitle title="CALL RESULT" subtitle="Appointment Booked" customWidth="70%" />
              </Box>
              <Box style={{ justifyContent: "space-between", display: "flex" }}>
                <TitleAndSubtitle title="CALL TYPE" subtitle={capitalize(call_direction)} />
                <TitleAndSubtitle
                  title="CALL FLOW"
                  subtitle={call_flow ? CALL_FLOW_TYPE_OPTIONS[call_flow].label : ""}
                />
              </Box>

              <TitleAndSubtitle title="NUMBER OF CALLS ATTEMPTS" subtitle="2" />
              <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                <TitleAndSubtitle title="APPOINTMENT DATE AND TIME" subtitle={callDate} />
                <TitleAndSubtitle title="MEETING INVITE SENT?" subtitle="YES" />
              </Box>
              <TitleAndSubtitle
                title="RECORDING LINK"
                subtitle="https://www.salesai.com/recordings/J434-Mkfse45"
                customWidth="70%"
              />
            </Stack>
          </Accordion>
        </Stack>
      </Box>
    </Box>
  );
}
