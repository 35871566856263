import Box from "@mui/material/Box";
import InboxList from "./list/InboxList";
import ViewInboxMessageDrawer from "./view/ViewInboxMessagesDrawer";
import { Typography } from "@mui/material";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { ChevronLeft, ChevronRight } from "heroicons-react";
import { selectInboxMessages } from "@/redux/selectors";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { lookupMessageThreads } from "@/redux/reducers/inbox/slices/listMessages";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { lookupProspectsInbox } from "@/redux/reducers/prospects/slices/listProspects";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Message } from "@/types/inbox";

export default function InboxPage() {
  const [cursor, setCursor] = React.useState(0);
  const messages = useAppSelector(selectInboxMessages);
  const messagesLoading = useAppSelector(({ inbox }) => inbox.getInboxData.messagesLoading);

  const totalRows = messages.length;
  const ITEMS_PER_PAGE = 10;
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  const handlePrevClick = () => {
    if (cursor > 0) {
      setCursor(cursor - ITEMS_PER_PAGE);
    }
  };

  const handleNextClick = () => {
    if (cursor + ITEMS_PER_PAGE < totalRows) {
      setCursor(cursor + ITEMS_PER_PAGE);
    }
  };

  useEffect(() => {
    if (user?.sub) {
      dispatch(lookupMessageThreads(user?.sub));
    }
  }, [dispatch, user?.sub]);

  const prospectIds: string[] = useMemo(() => {
    if (messages) {
      return messages.map((m: Message) => m.prospect_id);
    }
    return [];
  }, [messages]);

  useEffect(() => {
    if (prospectIds?.length) {
      dispatch(lookupProspectsInbox(prospectIds));
    }
  }, [dispatch, prospectIds]);

  if (messagesLoading) return <LoadingSpinner />;

  return (
    <>
      <Box
        sx={(theme) => ({
          width: "100%",
          overflowY: "hidden",
          borderRadius: "8px 8px 8px 8px",
          display: "flex",
          justifyContent: "stretch",
        })}
      >
        <InboxList cursor={cursor} totalRows={totalRows} itemsPerPage={ITEMS_PER_PAGE} />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "5px",
          marginTop: "5px",
          background: "#fff",
          padding: "8px 16px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Typography variant="body2">Display</Typography>
          <Dropdown
            label=""
            options={[]}
            onChange={() => {
              console.log("onChange");
            }}
            defaultValue={10}
          />
          <Typography variant="body2">
            of {Math.ceil(totalRows / ITEMS_PER_PAGE)}
            items
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <ChevronLeft onClick={handlePrevClick} style={{ cursor: cursor > 0 ? "pointer" : "not-allowed" }} />
          <Typography variant="body2">
            {Math.ceil((cursor + 1) / ITEMS_PER_PAGE)} of {Math.ceil(totalRows / ITEMS_PER_PAGE)}
          </Typography>
          <ChevronRight
            onClick={handleNextClick}
            style={{ cursor: cursor + ITEMS_PER_PAGE < totalRows ? "pointer" : "not-allowed" }}
          />
        </Box>
      </Box>

      <ViewInboxMessageDrawer />
    </>
  );
}
