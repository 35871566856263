import { RowCallLog } from "@/redux/reducers/ai-voice/listCalls";
import { capitalize } from "@/utils/capitalize";
import { Box, Chip, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import { CALL_FLOW_OUTCOME_OPTIONS, CALL_FLOW_TYPE_OPTIONS } from "../constants";

dayjs.extend(dayjsDuration);

const columnHelper = createColumnHelper<RowCallLog>();

export const CallLogColumns = [
  columnHelper.accessor("contact", {
    header: () => <Typography variant="body3">Contact Name</Typography>,
    cell: (info) => {
      const contact = info.getValue();
      const contactName = contact ? `${contact?.firstName} ${contact?.lastName}` : "Test call";
      return <Box sx={{ maxWidth: "350px" }}>{contactName}</Box>;
    },
  }),

  columnHelper.accessor("call_flow", {
    header: () => <Typography variant="body3">Call Flow</Typography>,
    cell: (info) => {
      const callFlow = info.getValue();
      if (!callFlow) {
        return;
      }

      return <Box sx={{ maxWidth: "350px" }}>{CALL_FLOW_TYPE_OPTIONS[callFlow].label}</Box>;
    },
  }),

  columnHelper.accessor("call_outcome", {
    header: () => <Typography variant="body3">Outcome</Typography>,
    cell: (info) => {
      const outcome = info.getValue();
      if (!outcome) {
        return;
      }

      return (
        <Chip
          label={CALL_FLOW_OUTCOME_OPTIONS[outcome].label}
          sx={{
            fontWeight: 500,
            bgcolor: CALL_FLOW_OUTCOME_OPTIONS[outcome].bgcolor,
            color: CALL_FLOW_OUTCOME_OPTIONS[outcome].color || "#000",
          }}
        />
      );
    },
  }),

  columnHelper.accessor("call_direction", {
    header: () => <Typography variant="body3">Direction</Typography>,
    cell: (info) => {
      const direction = info.getValue();
      if (!direction) {
        return;
      }
      return (
        <Chip
          label={capitalize(direction)}
          sx={{
            fontWeight: 500,
            bgcolor: direction === "INBOUND" ? "#69C0FF80" : "#609C3180",
            maxWidth: "350px",
          }}
        />
      );
    },
  }),

  columnHelper.accessor("call_ended_time", {
    header: () => <Typography variant="body3">Date</Typography>,
    cell: (info) => {
      const date = info.getValue();
      if (!date) {
        return;
      }
      return (
        <Box>
          <Typography variant="body2">{dayjs(date).format("MMM DD, YYYY h:mm A")}</Typography>
        </Box>
      );
    },
  }),

  columnHelper.accessor("call_began_time", {
    header: () => <Typography variant="body3">Duration</Typography>,
    cell: (info) => {
      const { call_began_time, call_ended_time } = info.row.original;

      if (!call_began_time || !call_ended_time) {
        return <Box>No Duration</Box>;
      }

      const durationInMs = call_ended_time - call_began_time;

      const formattedDuration = dayjs.duration(durationInMs).format("m[m] s[s]");
      return <Box>{formattedDuration}</Box>;
    },
  }),
];
