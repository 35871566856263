import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { CallLogFiltersInput } from "..";
import { AiVoiceCallFlowOutcome } from "@/types/ai-voice";
import { useAppSelector } from "@/redux/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

type CallLogFiltersProps = {
  filters: CallLogFiltersInput;
  setFilters: (filters: CallLogFiltersInput | ((prevFilters: CallLogFiltersInput) => CallLogFiltersInput)) => void;
};

export const CALL_OUTCOME_SELECT_OPTIONS: { label: string; value: AiVoiceCallFlowOutcome }[] = [
  { label: "Error", value: "ERROR" },
  { label: "Inbound", value: "INBOUND" },
  { label: "DNC", value: "OUTBOUND_DNC" },
  { label: "Voicemail", value: "OUTBOUND_VOICEMAIL" },
  { label: "Answered", value: "OUTBOUND_ANSWERED" },
  { label: "Booked", value: "BOOKED" },
  { label: "Transferred", value: "TRANSFER" },
  { label: "Transferred - Sales", value: "TRANSFER_SALES" },
  { label: "Transferred - Billing", value: "TRANSFER_BILLING" },
  { label: "Transferred - Support", value: "TRANSFER_SUPPORT" },
];

export default function CallLogFilters({ filters, setFilters }: CallLogFiltersProps) {
  const callLogs = useAppSelector((selector) => selector.aiVoice.listCalls.callLogs);
  const [search, setSearch] = useState<string>("");
  const assigneesOptions = useMemo(() => {
    return (callLogs || [])
      .map((log) => log.contact)
      .filter(Boolean)
      .map((contact) => ({
        label: contact!.firstName + " " + contact!.firstName,
        value: contact!.id,
      }));
  }, [callLogs]);

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetFilters(search);

    return () => debouncedSetFilters.cancel();
  }, [debouncedSetFilters, search]);

  return (
    <form>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
          gap: "16px",
        }}
      >
        {/*
        <Box sx={{ minWidth: 500 }}>
          <Searchbar
            sx={{ flexGrow: 1 }}
            placeholder="Type a phone number or contact name to search"
            value={search}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(value);
            }}
          />
        </Box>
        */}
        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Assignee"
            placeholder="Assignee"
            isClearable
            options={assigneesOptions}
            onChange={(option) => {
              setFilters({ ...filters, assignee: option.value });
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Outcome"
            placeholder="Outcome"
            isClearable
            options={[
              {
                label: "All",
                value: "",
              },
              ...CALL_OUTCOME_SELECT_OPTIONS,
            ]}
            onChange={(option) => {
              setFilters({ ...filters, callOutcome: option.value });
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Dropdown
            isClearable
            label="Date"
            placeholder="Date"
            options={[
              { label: "This Week", value: "this_week" },
              { label: "This Month", value: "this_month" },
              { label: "Last Month", value: "last_month" },
              { label: "Last 90 Days", value: "last_90_days" },
            ]}
            onChange={console.log}
          />
        </Box>
      </Box>
    </form>
  );
}
