import { Box, Typography } from "@mui/material";
import { useAppSelector } from "@/redux/store";
import Accordion from "@/common/components/accordion/Accordion";
import moment from "moment";
import { selectCurrentInbox, selectCurrentInboxProspect } from "@/redux/selectors";
import SmsMessageDetail from "./SmsMessageDetail";
import ReplyBox from "../../../../common/components/reply-box/ReplyBox";
import MailMessageDetail from "./MailMessageDetail";
import { Message } from "@/types/inbox";
import { useAuth0 } from "@auth0/auth0-react";
import ProspectInfo from "@/common/components/prospect-info/ProspectInfo";
import { ReactComponent as Envelope } from "@/common/assets/svg/envelope.svg";
import { ReactComponent as Message1 } from "@/common/assets/svg/message.svg";
import { FormProvider, useForm } from "react-hook-form";
const ViewMessagesDetail = () => {
  const currentInbox = useAppSelector(selectCurrentInbox) as Message;
  const currentProspect = useAppSelector(selectCurrentInboxProspect);
  const { user } = useAuth0();
  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const ViewInboxMessage = () => (
    <Box sx={{ display: "flex", height: "90vh", overflowY: "hidden", gap: "20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 2 }}>
        <Box>
          <Accordion
            disableGutters
            defaultExpanded={currentInbox?.inboxType === "sms"}
            customElementIcon={
              <Box
                sx={(theme) => ({
                  display: "flex",
                  gap: "8.333px",
                  fontSize: "20px",
                  background: "#E7EBED",
                  padding: "3.333px",
                  borderRadius: "3.333px;",
                })}
              >
                <Envelope />
              </Box>
            }
            id="panel-sms"
            summaryTitle="SMS"
          >
            <SmsMessageDetail {...currentInbox} name={user?.name} />
          </Accordion>
          <Accordion
            disableGutters
            defaultExpanded={currentInbox?.inboxType === "email"}
            customElementIcon={
              <Box
                sx={(theme) => ({
                  display: "flex",
                  gap: "8.333px",
                  fontSize: "20px",
                  background: "#E7EBED",
                  padding: "3.333px",
                  borderRadius: "3.333px;",
                })}
              >
                <Message1 />
              </Box>
            }
            id="panel-email"
            summaryTitle={
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "12px" }}>
                  <Box>
                    <Typography variant="body2" fontWeight="600">
                      Email - {currentInbox?.from}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="body2" fontWeight="200">
                      {moment(currentInbox?.date).format("MMM DD, YYYY - hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
              </>
            }
          >
            <MailMessageDetail {...currentInbox} />
          </Accordion>
        </Box>
        <ReplyBox onChange={() => null} />
      </Box>
      <Box sx={{ flex: 1, margin: "0px" }}>
        <ProspectInfo {...currentProspect} isInbox />
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        maxWidth: "800px",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <ViewInboxMessage />
        </FormProvider>
      </form>
    </Box>
  );
};

export default ViewMessagesDetail;
