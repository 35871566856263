import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Button from "@/common/components/button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DrawerLayout from "@/layouts/DrawerLayout";
import Stack from "@mui/material/Stack";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input/Input";
import { createCompany } from "@/redux/reducers/prospects/companies/slices/listCompanies";
import { Box } from "@mui/material";
import {
  CompanyHeadcountOptions,
  CompanyIndustryOptions,
  CompanyRevenueOptions,
} from "@/redux/reducers/prospects/companies/slices/helpers";
import { Company } from "@/redux/reducers/prospects/companies/slices/types";
import { useAppDispatch } from "@/redux/store";
import { createCompanyCloseDrawer } from "@/redux/reducers/prospects/companies/slices/listCompanies";

const schema = Yup.object({
  name: Yup.string().required(),
  industry: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }).required(),
  revenue: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }).required(),
  headcount: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }).required(),
  domain: Yup.string().required(),
}).required();

export default function CreateComapnyForm() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (!data) return;

    const company = {
      ...data,
      industry: data.industry?.value,
      revenue: data.revenue?.value,
      headcount: data.headcount?.value,
      domain: data.domain,
    } as Company;
    try {
      dispatch(createCompany(company));
      dispatch(createCompanyCloseDrawer());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ height: "calc(100vh - 80px)", overflow: "hidden" }}>
        <DrawerLayout
          customWidth="500px"
          title="Add New Company"
          testid="newcompanyForm"
          closeDrawer={() => dispatch(createCompanyCloseDrawer())}
        >
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Company Name" />
                  <Input {...field} type="text" placeholder="Company Name" error={fieldState?.error?.message} />
                </>
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="industry"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel label="Industry" />
                  <Dropdown
                    isSearchable
                    label="Select Industry"
                    options={CompanyIndustryOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                </>
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="revenue"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel label="Revenue" />
                  <Dropdown
                    isSearchable
                    label="Revenue"
                    options={CompanyRevenueOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                </>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="headcount"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel label="Size HeadCounts" />
                  <Dropdown
                    isSearchable
                    label="Size HeadCounts"
                    options={CompanyHeadcountOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                </>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="domain"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel label="Domain" />
                  <Input {...field} type="text" placeholder="Domain" />
                </>
              )}
            />
          </FormControl>
        </DrawerLayout>
      </Box>
      <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} position="absolute" bottom={0} width="100%">
        <Button
          data-testid="closeTaskDrawer"
          variant="secondary"
          onClick={() => dispatch(createCompanyCloseDrawer())}
          sx={{ width: "100%" }}
          type="button"
        >
          {t("tasks.cancel")}
        </Button>

        <Button type="submit" variant="primary" sx={{ width: "100%" }} disabled={!methods.formState.isValid}>
          Add New Company
        </Button>
      </Stack>
    </form>
  );
}
