import TsTable from "@/common/components/ts-table";
import { useAppSelector } from "@/redux/store";
import { getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { DataImportColumns } from "./columns";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

export default function DataImportTable() {
  const data = useAppSelector(({ dataImport }) => dataImport.list.data) || [];

  const table = useReactTable({
    data,
    columns: DataImportColumns as any,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <TsTable
        table={table as any}
        notFoundProps={{
          Icon: DocumentArrowDownIcon,
          title: "No imports found",
          description: "Begin importing prospects, companies, or products  by clicking the New Import button above.",
        }}
        handleCellClick={() => {
          // Do Something
        }}
      />
    </>
  );
}
