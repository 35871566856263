import { useAppSelector } from "@/redux/store";

import Searchbar from "@/common/components/searchbar/Searchbar";
import { getCompaniesCount } from "@/redux/selectors/companies";

type CompanyFiltersProps = {
  onSetFilters?: (filters: { searchString?: string }) => void;
};
export default function CompanyFilters({ onSetFilters }: CompanyFiltersProps) {
  const hasCompanies = useAppSelector(getCompaniesCount);
  return (
    <>
      {hasCompanies > 0 && (
        <Searchbar
          sx={{ width: "450px" }}
          placeholder="Search for a company"
          onChange={(e) => {
            onSetFilters?.({ searchString: e.target.value });
          }}
        />
      )}
    </>
  );
}
