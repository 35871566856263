import DragAndDrop from "@/common/components/drag-and-drop/DragAndDrop";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input/Input";
import { FormControl, useTheme } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import FormLabel from "@/common/components/form-label/FormLabel";
import { uploadCompanyCsv } from "@/redux/reducers/dataImport/company/slices/import";
import { uploadProductCsv } from "@/redux/reducers/dataImport/product/slices/import";
import { uploadProspectCsv } from "@/redux/reducers/dataImport/prospect/slices/import";
import { useAppDispatch } from "@/redux/store";
import { KnContactImportJobConsentLevelEnum, KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";
import Box from "@mui/material/Box";
import { DataImportFormFields, DataImportSchema } from "../types";

const CONSENT_OPTIONS = [
  {
    label: "No Consent",
    value: KnContactImportJobConsentLevelEnum.None,
  },
  {
    label: "L1",
    value: KnContactImportJobConsentLevelEnum.Lvl1,
  },
  {
    label: "L2",
    value: KnContactImportJobConsentLevelEnum.Lvl2,
  },
];

export default function UploadStep() {
  const methods = useFormContext<DataImportSchema>();
  const { importType } = useWatch<DataImportFormFields>();
  const dispatch = useAppDispatch();
  const consentLevel = methods.watch("consentLevel");

  const handleUploadFile = async (file: File) => {
    let response = null;
    switch (importType) {
      case KnFirestoreImportJobImportJobTypeEnum.Company:
        response = await dispatch(uploadCompanyCsv(file)).unwrap();

        break;
      case KnFirestoreImportJobImportJobTypeEnum.Contact:
        response = await dispatch(uploadProspectCsv(file)).unwrap();
        break;

      case KnFirestoreImportJobImportJobTypeEnum.Product:
        response = await dispatch(uploadProductCsv(file)).unwrap();
        break;
      default:
    }

    if (response?.id) {
      methods.setValue("id", response.id);
      methods.setValue("file", file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 700,
        margin: "0 auto",
      }}
    >
      {importType === KnFirestoreImportJobImportJobTypeEnum.Contact && (
        <>
          <Controller
            name="leadSource"
            control={methods.control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }}>
                <FormLabel aria-required label="Lead Source" />
                <Input
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={fieldState?.error?.message}
                  placeholder="Form Submition"
                />
              </FormControl>
            )}
          />

          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="consentLevel"
              control={methods.control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Consent Level" />
                  <Dropdown
                    label="No Consent"
                    error={fieldState?.error?.message}
                    options={CONSENT_OPTIONS?.map((consent) => ({
                      label: consent.label,
                      value: consent.value,
                    }))}
                    onChange={(option) => {
                      field.onChange(option.value);
                    }}
                  />
                </>
              )}
            />
          </FormControl>
          {consentLevel === KnContactImportJobConsentLevelEnum.Lvl2 && (
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="consentLevel"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <>
                    <FormLabel aria-required label="Consent Level" />
                    <Input {...field} error={fieldState?.error?.message} type="text" placeholder="Form Submition" />
                  </>
                )}
              />
            </FormControl>
          )}
        </>
      )}

      <Box sx={{ width: "100%" }}>
        <DragAndDrop accept={{ "text/csv": [".csv"] }} file={methods.watch("file")} onSetFile={handleUploadFile} />
      </Box>

      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="importJobName"
          control={methods.control}
          render={({ field, fieldState }) => (
            <FormControl sx={{ width: "100%" }}>
              <FormLabel aria-required label="Import Name" />
              <Input
                {...field}
                error={fieldState.error?.message}
                onChange={(e) => field.onChange(e.target.value)}
                type="text"
                placeholder="Add file name here"
              />
            </FormControl>
          )}
        />
      </FormControl>
    </Box>
  );
}
