/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";

export const googleTokenExchange = createAsyncThunk(
  "oauth/googleExchangeCode",
  async ({ code, shared }: { code: string; shared: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const redirectUri = `${window.location.origin}/account/oauth-callback/gmail${shared ? "-shared" : ""}`;
      return await Services.OutreachOauth.googleExchangeCode({
        consent: {
          emailAddress: "",
          shared: shared,
        },
        code: code,
        redirectUri: redirectUri,
      });
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const outlookTokenExchange = createAsyncThunk(
  "oauth/outlookExchangeCode",
  async ({ code, shared }: { code: string; shared: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.OutreachOauth.outlookExchangeCode({
        consent: {
          emailAddress: "",
          shared: shared,
        },
        code: code,
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const googleCalendarTokenExchange = createAsyncThunk(
  "oauth/authorizeTokenGoogleCalendarUrl",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Calendar.calendarGoogleTokenExchange({
        code: token,
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const outlookCalendarTokenExchange = createAsyncThunk(
  "oauth/authorizeTokenGraphUrl",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Calendar.graphTokenExchange({
        code: token,
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const hubspotTokenExchange = createAsyncThunk(
  "oauth/authorizeTokenCRMUrl",
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Crm.hubspotTokenExchange({
        code: token,
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
