import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

import { Box, Typography } from "@mui/material";

export default function MenuOptions({
  open,
  anchorEl,
  handleClick,
  handleClose,
  options,
  onClick,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  options: { label: string; value: string; icon: React.ReactNode }[];
  onClick: (option: { label: string; value: string; icon: React.ReactNode }) => void;
}) {
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
          },
        }}
        sx={(theme) => ({
          "& .MuiPaper-root": {
            boxShadow: "0px 6px 15px 0px rgba(55, 70, 103, 0.15)",
            fontSize: "30px",
            boxShados: "none",
            borderRadius: "8px",
          },
          "& .MuiMenu-list": {
            padding: "6px ",
            border: "1px solid #E7EBED",
            borderRadius: "8px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
            maxWidth: "264px",
          },

          "& .MuiDivider-root": {
            margin: "6px 0px",
            borderRadius: "8px",
          },
          "& .MuiMenuItem-root": {
            padding: "10px 12px",
            borderRadius: "8px",
            paddingRight: "22px",

            "&:hover": {
              background: "#F8F8F8",
            },
          },
        })}
      >
        {options.map((option: { label: string; value: string; icon: React.ReactNode }) => (
          <Box key={option.label}>
            <MenuItem disableRipple key={option.value} onClick={() => onClick(option)}>
              <ListItemIcon
                sx={{
                  width: "24px",
                  height: "24px",
                }}
              >
                {option.icon}
              </ListItemIcon>
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
            {option.value !== "delete" && (
              <Divider variant="fullWidth" sx={{ color: "#E7EBED", margin: "0px!important" }} />
            )}
          </Box>
        ))}
      </Menu>
    </div>
  );
}
