import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GenMsgCustom, GenMsgCustomMessageTypeEnum, GenMsgSuggestMeetingTimes } from "@/services/generated";

export const genMsgSuggestMeetingTimes = createAsyncThunk(
  "inbox/genMsgSuggestMeetingTimes",
  async (request: GenMsgSuggestMeetingTimes) => {
    try {
      return await Services.Ai.genMsgSuggestMeetingTimes(request);
    } catch (error) {
      return error;
    }
  },
);

export const genMsgCustom = createAsyncThunk("inbox/genMsgCustom", async (request: GenMsgCustom) => {
  try {
    return await Services.Ai.genMsgCustom(request);
  } catch (error) {
    return error;
  }
});
