import { Stack } from "@mui/material";

export default function DrawerButtonsLayout({ children }: { children: any }) {
  return (
    <Stack
      sx={{
        height: "49px",
        padding: "0px 16px",
      }}
      direction={{ xs: "column", md: "row" }}
      gap={3}
      position="absolute"
      bottom="16px"
      width="100%"
    >
      {children}
    </Stack>
  );
}
