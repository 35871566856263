import { components } from "react-select";

export const Control = ({ children, ...props }: any) => {
  const currentOption = props.selectProps.options.find((o: any) => o.value === props.selectProps.value);
  return (
    <components.Control {...props}>
      {currentOption?.icon || props.placeholderIcon}
      {children}
    </components.Control>
  );
};
