import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { SequenceAction as SequenceActionInterface } from "@/types/sequence";
import { X } from "heroicons-react";

export const sequenceActionTypes: {
  type: SequenceActionInterface["actionType"];
  title: string;
  description: string;
}[] = [
  { type: "SMS", title: "Send SMS", description: "Instantly connect via real-time texts" },
  { type: "EMAIL", title: "Send Email", description: "Craft precise, impactful emails" },
  { type: "CALL", title: "AI Call", description: "Automate calls with your AI Agent" },
];

export type SequenceActionTypesProps = {
  title: string;
  canClose?: boolean;
  onClose?: () => void;
  setActionType?: (type: SequenceActionInterface["actionType"]) => void;
};

export const SequenceActionTypes = (props: SequenceActionTypesProps) => {
  const theme = useTheme();
  const { title, canClose, onClose, setActionType } = props;

  return (
    <Box sx={{ padding: "16px", background: theme.palette.bg.inverse, borderRadius: "8px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "28px",
            letterSpacing: "0.2px",
            color: "#1A2135",
            marginBottom: "12px",
          }}
        >
          {title}
        </Typography>
        {canClose && (
          // TODO: Refactor this and move to a button component with a variant
          <ButtonBase
            onClick={() => {
              onClose?.();
            }}
            sx={{
              color: theme.palette.commonColors.text,
              borderRadius: "4px",
              padding: "4px",
              transition: "all 0.3s ease",
              width: "24px",
              height: "24px",
              cursor: "pointer",
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              "&:hover": {
                backgroundColor: theme.palette.midnight[20],
              },

              "&:active": {
                backgroundColor: theme.palette.bg.default,
              },
            }}
          >
            <X />
          </ButtonBase>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
        }}
      >
        {sequenceActionTypes?.map((item) => (
          <Box
            key={item.title}
            sx={{
              border: `1px solid ${theme.palette.commonColors.light}`,
              padding: "16px 24px",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              alignItems: "flex-start",
              justifyContent: "center",
              borderRadius: "8px",
              backgroundColor: "white",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => {
              setActionType?.(item.type);
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                color: theme.palette.txt.default,
              }}
            >
              {item.title}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                color: theme.palette.txt.placeholder,
              }}
            >
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
