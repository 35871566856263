import { TaskRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";

export const TaskStatusSelectOptions = [
  { label: TaskRequestStatusEnum.Complete, value: TaskRequestStatusEnum.Complete },
  { label: TaskRequestStatusEnum.Due, value: TaskRequestStatusEnum.Due },
  { label: TaskRequestStatusEnum.Overdue, value: TaskRequestStatusEnum.Overdue },
  { label: TaskRequestStatusEnum.Upcoming, value: TaskRequestStatusEnum.Upcoming },
  { label: TaskRequestStatusEnum.Unspecified, value: TaskRequestStatusEnum.Unspecified },
];

export const TaskTypeSelectOptions = [
  { label: "Approve Ai Message", value: TaskRequestTypeEnum.ApproveAiMessage },
  { label: "Attend Meeting", value: TaskRequestTypeEnum.AttendMeeting },
  { label: "Call", value: TaskRequestTypeEnum.Call },
  { label: "Other", value: TaskRequestTypeEnum.Other },
];
