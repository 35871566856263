import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSequencesTemplates, updateCurrentSequence } from "./listSequences";
import {
  SequenceTemplateRequestCreate,
  SequenceTemplateRequestUpdate,
  StepTemplateRequestCreate,
  StepTemplateRequestUpdateBehavior,
  StepTemplateRequestUpdateInfo,
} from "@/services/generated";
import toast from "@/lib/toast";

type State = {
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  loading: false,
  error: null,
};

export const createSequence = createAsyncThunk(
  "sequenceBuilder/createSequence",
  async (newSequence: SequenceTemplateRequestCreate, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await Services.SequenceTemplates.createSequenceTemplate(newSequence);
      dispatch(getSequencesTemplates());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createNewVersionSequenceTemplate = createAsyncThunk(
  "sequenceBuilder/createNewVersionSequenceTemplate",
  async (sequenceId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.SequenceTemplates.createNewVersionSequenceTemplate(sequenceId);
      const sequence = {
        id: response.data.sequenceTemplate!.id!,
        originalSequenceTemplateId: response.data.sequenceTemplate!.originalSequenceTemplateId!,
        name: response.data.sequenceTemplate!.name!,
        status: response.data.sequenceTemplate!.status!,
        createdAt: response.data.sequenceTemplate!.createdAt!,
      };
      dispatch(getSequencesTemplates());
      return sequence;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeSequenceStepTemplate = createAsyncThunk(
  "sequenceBuilder/removeSequenceStepTemplate",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.SequenceStepTemplates.removeStepTemplate(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateStepTemplateBehavior = createAsyncThunk(
  "sequenceBuilder/updateStepTemplateBehavior",
  async (data: StepTemplateRequestUpdateBehavior, { rejectWithValue }) => {
    try {
      const response = await Services.SequenceStepTemplates.updateStepTemplateBehavior(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateSequence = createAsyncThunk(
  "sequenceBuilder/updateSequence",
  async (data: SequenceTemplateRequestUpdate, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.SequenceTemplates.updateSequenceTemplate(data);
      if (response.status === 200) {
        dispatch(getSequencesTemplates());
        if (data.status !== "DELETED") {
          dispatch(updateCurrentSequence({ sequence: data }));
        }
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateStepTemplateInfo = createAsyncThunk(
  "sequenceBuilder/updateStepTemplateInfo",
  async (data: StepTemplateRequestUpdateInfo, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.SequenceStepTemplates.updateStepTemplateInfo(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createStepTemplate = createAsyncThunk(
  "sequenceBuilder/createStepTemplate",
  async (newStep: StepTemplateRequestCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.SequenceStepTemplates.createStepTemplate(newStep);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const createSequenceSlice = createSlice({
  name: "createSequence",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createSequence.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(createSequence.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createSequence.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
  reducers: {},
});

export const {} = createSequenceSlice.actions;

export default createSequenceSlice.reducer;
