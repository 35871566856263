import { KnContact2LeadSourceEnum, KnContact2LeadTypeEnum } from "@/services/generated";
import { ConsentLevel } from "@/common/constants/consent-level";

export const ProspectLeadSourceOptions = Object.entries(KnContact2LeadSourceEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export const ProspectLeadTypeOptions = Object.entries(KnContact2LeadTypeEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export const ProspectConsentLevelOptions = Object.entries(ConsentLevel).map(([key, value]) => ({
  label: key,
  value: value,
}));

const jobTitles = [
  "Marketing Manager",
  "Sales Representative",
  "Financial Analyst",
  "Human Resources Manager",
  "Software Engineer",
  "Graphic Designer",
  "Customer Service Representative",
  "Operations Manager",
  "Account Executive",
  "Data Analyst",
  "Product Manager",
  "Project Coordinator",
  "Legal Counsel",
  "Business Development Manager",
  "Quality Assurance Specialist",
  "Administrative Assistant",
  "Research Scientist",
  "Content Writer",
  "UI/UX Designer",
  "Supply Chain Manager",
  "Systems Administrator",
  "Technical Support Specialist",
  "Event Planner",
  "Social Media Coordinator",
  "Healthcare Administrator",
  "Mechanical Engineer",
  "Real Estate Agent",
  "Financial Planner",
  "Marketing Coordinator",
  "Public Relations Specialist",
  "Educational Consultant",
  "Interior Designer",
  "Operations Coordinator",
  "Network Engineer",
  "Brand Manager",
  "Logistics Coordinator",
  "Executive Assistant",
  "Investment Analyst",
  "Web Developer",
  "Recruitment Specialist",
  "Accountant",
  "Digital Marketing Manager",
  "Pharmaceutical Sales Representative",
  "Customer Success Manager",
  "Art Director",
  "Fitness Trainer",
  "Insurance Agent",
  "Technical Writer",
  "Executive Chef",
  "Event Coordinator",
  "IT Manager",
  "Business Analyst",
  "Financial Controller",
  "Customer Experience Manager",
  "Fashion Designer",
  "Market Research Analyst",
  "Software Developer",
  "Operations Analyst",
  "Public Health Educator",
  "Brand Ambassador",
  "UX Researcher",
  "Investment Banker",
  "SEO Specialist",
  "Risk Analyst",
  "Laboratory Technician",
  "Solutions Architect",
  "Media Planner",
  "Financial Advisor",
  "Community Manager",
  "Digital Content Creator",
  "Sales Manager",
  "Technical Project Manager",
  "Clinical Research Coordinator",
  "Corporate Trainer",
  "Environmental Scientist",
  "Inventory Manager",
  "UX/UI Developer",
  "Legal Assistant",
  "Mechanical Designer",
  "Business Development Representative",
  "IT Support Specialist",
  "Market Analyst",
  "Healthcare Consultant",
  "Social Worker",
  "Software Architect",
  "Event Manager",
  "Quality Control Inspector",
  "Sales Engineer",
  "Content Marketing Manager",
  "Project Manager",
  "UX Designer",
  "Financial Analyst",
  "Business Manager",
  "Customer Support Specialist",
  "Brand Strategist",
  "Data Scientist",
  "HR Coordinator",
  "Digital Strategist",
  "Investment Advisor",
  "Content Strategist",
  "Marketing Analyst",
  "Legal Analyst",
  "Clinical Psychologist",
  "Technical Recruiter",
  "Account Manager",
  "Financial Consultant",
  "Corporate Counsel",
  "Operations Director",
  "Artificial Intelligence Engineer",
  "Sales Analyst",
  "Supply Chain Analyst",
  "Public Relations Manager",
  "HR Generalist",
  "Sales Coordinator",
  "Digital Project Manager",
  "Content Manager",
  "Marketing Specialist",
  "Business Consultant",
  "Health Educator",
  "Database Administrator",
  "HR Manager",
  "Software Tester",
  "Sales Executive",
  "Product Owner",
  "Content Creator",
  "Business Development Director",
  "UX/UI Designer",
  "Digital Designer",
  "Copywriter",
  "Event Planner",
  "Accounting Manager",
  "Marketing Director",
  "Project Engineer",
  "Product Marketing Manager",
  "Financial Manager",
  "Legal Secretary",
  "Technical Sales Engineer",
  "HR Assistant",
  "Customer Relationship Manager",
  "Market Development Manager",
  "Senior Consultant",
  "Clinical Nurse Specialist",
  "Social Media Manager",
  "Finance Manager",
  "Creative Director",
  "Business Operations Manager",
  "Data Entry Clerk",
  "Product Designer",
  "Investment Manager",
  "Systems Analyst",
  "Sales Director",
  "HR Specialist",
  "Customer Experience Specialist",
  "Technical Account Manager",
  "Digital Analyst",
  "Graphic Artist",
  "Financial Advisor",
  "Sales Consultant",
  "Digital Media Specialist",
  "Medical Technologist",
  "Customer Service Manager",
  "Accounting Clerk",
  "Legal Advisor",
  "IT Director",
  "Market Researcher",
  "Operations Assistant",
  "UX Researcher",
  "Public Health Analyst",
  "Sales Support Specialist",
  "UX Designer",
  "Digital Marketing Specialist",
  "Content Writer",
  "HR Recruiter",
  "Quality Assurance Manager",
  "Business Development Manager",
  "Finance Director",
  "Operations Manager",
  "Technical Writer",
  "Brand Manager",
  "Research Analyst",
  "Digital Marketing Manager",
  "Art Director",
  "Data Analyst",
  "Project Coordinator",
  "Financial Analyst",
  "Product Manager",
  "Legal Counsel",
  "Business Development Specialist",
  "Customer Success Manager",
  "Administrative Assistant",
  "Technical Support Specialist",
  "Public Relations Specialist",
  "Quality Assurance Specialist",
  "Marketing Coordinator",
  "IT Manager",
  "Logistics Coordinator",
  "Web Developer",
  "Content Marketing Manager",
  "Market Research Analyst",
  "Digital Content Creator",
  "Investment Analyst",
  "Brand Ambassador",
  "Risk Analyst",
  "Customer Experience Manager",
  "Social Media Coordinator",
  "Financial Planner",
  "Recruitment Specialist",
  "Pharmaceutical Sales Representative",
  "Executive Assistant",
  "Accountant",
  "IT Support Specialist",
  "Operations Analyst",
  "Network Engineer",
  "Software Developer",
  "Financial Controller",
  "Executive Chef",
  "Event Coordinator",
  "Technical Project Manager",
  "Insurance Agent",
  "SEO Specialist",
  "Business Analyst",
  "Fashion Designer",
  "Healthcare Administrator",
  "Business Manager",
  "Market Analyst",
  "Legal Assistant",
  "Fitness Trainer",
  "UX/UI Developer",
  "Environmental Scientist",
  "Market Development Manager",
  "Software Architect",
  "Legal Analyst",
  "Corporate Trainer",
  "Laboratory Technician",
  "Business Development Representative",
  "Mechanical Designer",
  "Sales Manager",
  "Public Health Educator",
  "UX Designer",
  "Mechanical Engineer",
  "Investment Advisor",
  "Community Manager",
  "Digital Strategist",
  "Quality Control Inspector",
  "Customer Support Specialist",
  "Sales Coordinator",
  "Healthcare Consultant",
  "Corporate Counsel",
  "Technical Recruiter",
  "Data Scientist",
  "HR Coordinator",
  "Content Creator",
  "Artificial Intelligence Engineer",
  "Financial Consultant",
  "HR Generalist",
  "Content Strategist",
  "Content Manager",
  "Marketing Specialist",
  "Database Administrator",
  "Financial Manager",
  "Technical Sales Engineer",
  "Business Consultant",
  "Project Engineer",
  "Sales Executive",
  "Business Development Director",
  "HR Manager",
  "Senior Consultant",
  "Product Designer",
  "Finance Manager",
  "Operations Assistant",
  "Digital Analyst",
  "UX Researcher",
  "Market Researcher",
  "Public Health Analyst",
  "Sales Support Specialist",
  "Digital Marketing Specialist",
  "HR Recruiter",
  "Quality Assurance Manager",
  "Operations Manager",
  "Technical Writer",
  "Brand Manager",
  "Research Analyst",
  "Digital Marketing Manager",
  "Art Director",
  "Data Analyst",
  "Project Coordinator",
  "Financial Analyst",
  "Product Manager",
  "Legal Counsel",
  "Business Development Specialist",
  "Customer Success Manager",
  "Administrative Assistant",
  "Technical Support Specialist",
  "Public Relations Specialist",
  "Quality Assurance Specialist",
  "Marketing Coordinator",
  "IT Manager",
  "Logistics Coordinator",
  "Web Developer",
  "Content Marketing Manager",
  "Market Research Analyst",
  "Digital Content Creator",
  "Investment Analyst",
  "Brand Ambassador",
  "Risk Analyst",
  "Customer Experience Manager",
  "Social Media Coordinator",
  "Financial Planner",
  "Recruitment Specialist",
  "Pharmaceutical Sales Representative",
  "Executive Assistant",
  "Accountant",
  "IT Support Specialist",
  "Operations Analyst",
  "Network Engineer",
  "Software Developer",
  "Financial Controller",
  "Executive Chef",
  "Event Coordinator",
  "Technical Project Manager",
  "Insurance Agent",
  "SEO Specialist",
  "Business Analyst",
  "Fashion Designer",
  "Healthcare Administrator",
  "Business Manager",
  "Market Analyst",
  "Legal Assistant",
  "Fitness Trainer",
  "UX/UI Developer",
  "Environmental Scientist",
  "Market Development Manager",
  "Software Architect",
  "Legal Analyst",
  "Corporate Trainer",
  "Laboratory Technician",
  "Business Development Representative",
  "Mechanical Designer",
  "Sales Manager",
  "Public Health Educator",
  "UX Designer",
  "Mechanical Engineer",
  "Investment Advisor",
  "Community Manager",
  "Digital Strategist",
];

export const ProspectJobTitleOptions = jobTitles.map((title) => ({
  label: title,
  value: title,
}));

export const ProspectAddressOptions = [
  { city: "New York City", state: "NY, USA" },
  { city: "Los Angeles", state: "CA, USA" },
  { city: "Chicago", state: "IL, USA" },
  { city: "Houston", state: "TX, USA" },
  { city: "Phoenix", state: "AZ, USA" },
  { city: "Philadelphia", state: "PA, USA" },
  { city: "San Antonio", state: "TX, USA" },
  { city: "San Diego", state: "CA, USA" },
  { city: "Dallas", state: "TX, USA" },
  { city: "San Jose", state: "CA, USA" },
  { city: "Austin", state: "TX, USA" },
  { city: "Jacksonville", state: "FL, USA" },
  { city: "San Francisco", state: "CA, USA" },
  { city: "Indianapolis", state: "IN, USA" },
  { city: "Columbus", state: "OH, USA" },
  { city: "Fort Worth", state: "TX, USA" },
  { city: "Charlotte", state: "NC, USA" },
  { city: "Seattle", state: "WA, USA" },
  { city: "Denver", state: "CO, USA" },
  { city: "Washington", state: "DC, USA" },
  { city: "Boston", state: "MA, USA" },
  { city: "Detroit", state: "MI, USA" },
  { city: "Nashville", state: "TN, USA" },
  { city: "Memphis", state: "TN, USA" },
  { city: "Portland", state: "OR, USA" },
  { city: "Oklahoma City", state: "OK, USA" },
  { city: "Las Vegas", state: "NV, USA" },
  { city: "Louisville", state: "KY, USA" },
  { city: "Baltimore", state: "MD, USA" },
  { city: "Milwaukee", state: "WI, USA" },
  { city: "Albuquerque", state: "NM, USA" },
  { city: "Tucson", state: "AZ, USA" },
  { city: "Fresno", state: "CA, USA" },
  { city: "Sacramento", state: "CA, USA" },
  { city: "Kansas City", state: "MO, USA" },
  { city: "Long Beach", state: "CA, USA" },
  { city: "Mesa", state: "AZ, USA" },
  { city: "Atlanta", state: "GA, USA" },
  { city: "Colorado Springs", state: "CO, USA" },
  { city: "Virginia Beach", state: "VA, USA" },
  { city: "Raleigh", state: "NC, USA" },
  { city: "Omaha", state: "NE, USA" },
  { city: "Miami", state: "FL, USA" },
  { city: "Oakland", state: "CA, USA" },
  { city: "Minneapolis", state: "MN, USA" },
  { city: "Tulsa", state: "OK, USA" },
  { city: "Wichita", state: "KS, USA" },
  { city: "New Orleans", state: "LA, USA" },
  { city: "Arlington", state: "TX, USA" },
  { city: "Cleveland", state: "OH, USA" },
  { city: "Bakersfield", state: "CA, USA" },
  { city: "Tampa", state: "FL, USA" },
  { city: "Aurora", state: "CO, USA" },
  { city: "Anaheim", state: "CA, USA" },
  { city: "Santa Ana", state: "CA, USA" },
  { city: "Corpus Christi", state: "TX, USA" },
  { city: "Riverside", state: "CA, USA" },
  { city: "St. Louis", state: "MO, USA" },
  { city: "Lexington", state: "KY, USA" },
  { city: "Pittsburgh", state: "PA, USA" },
  { city: "Stockton", state: "CA, USA" },
  { city: "Cincinnati", state: "OH, USA" },
  { city: "Anchorage", state: "AK, USA" },
  { city: "Henderson", state: "NV, USA" },
  { city: "Greensboro", state: "NC, USA" },
  { city: "Plano", state: "TX, USA" },
  { city: "Newark", state: "NJ, USA" },
  { city: "Lincoln", state: "NE, USA" },
  { city: "Toledo", state: "OH, USA" },
  { city: "Orlando", state: "FL, USA" },
  { city: "Chula Vista", state: "CA, USA" },
  { city: "Irvine", state: "CA, USA" },
  { city: "Fort Wayne", state: "IN, USA" },
  { city: "Jersey City", state: "NJ, USA" },
  { city: "Durham", state: "NC, USA" },
  { city: "St. Petersburg", state: "FL, USA" },
  { city: "Laredo", state: "TX, USA" },
  { city: "Buffalo", state: "NY, USA" },
  { city: "Madison", state: "WI, USA" },
  { city: "Lubbock", state: "TX, USA" },
  { city: "Chandler", state: "AZ, USA" },
  { city: "Scottsdale", state: "AZ, USA" },
  { city: "Glendale", state: "AZ, USA" },
  { city: "Reno", state: "NV, USA" },
  { city: "Norfolk", state: "VA, USA" },
  { city: "Winston-Salem", state: "NC, USA" },
  { city: "North Las Vegas", state: "NV, USA" },
  { city: "Irving", state: "TX, USA" },
  { city: "Chesapeake", state: "VA, USA" },
  { city: "Gilbert", state: "AZ, USA" },
  { city: "Hialeah", state: "FL, USA" },
  { city: "Garland", state: "TX, USA" },
  { city: "Fremont", state: "CA, USA" },
  {
    city: "Baton Rouge",
    state: "LA, USA",
  },
  { city: "Richmond", state: "VA, USA" },
  { city: "Boise", state: "ID, USA" },
  { city: "San Bernardino", state: "CA, USA" },
  { city: "Spokane", state: "WA, USA" },
  { city: "Birmingham", state: "AL, USA" },
  { city: "Modesto", state: "CA, USA" },
  { city: "Des Moines", state: "IA, USA" },
  { city: "Rochester", state: "NY, USA" },
  { city: "Tacoma", state: "WA, USA" },
  { city: "Fontana", state: "CA, USA" },
  { city: "Oxnard", state: "CA, USA" },
  { city: "Moreno Valley", state: "CA, USA" },
  { city: "Fayetteville", state: "NC, USA" },
  { city: "Aurora", state: "IL, USA" },
  { city: "Glendale", state: "CA, USA" },
  { city: "Yonkers", state: "NY, USA" },
  { city: "Huntington Beach", state: "CA, USA" },
  { city: "Montgomery", state: "AL, USA" },
  { city: "Amarillo", state: "TX, USA" },
  { city: "Little Rock", state: "AR, USA" },
  { city: "Akron", state: "OH, USA" },
  { city: "Columbus", state: "GA, USA" },
  { city: "Augusta", state: "GA, USA" },
  { city: "Grand Rapids", state: "MI, USA" },
  { city: "Shreveport", state: "LA, USA" },
  { city: "Huntsville", state: "AL, USA" },
  { city: "Mobile", state: "AL, USA" },
  { city: "Tallahassee", state: "FL, USA" },
  { city: "Grand Prairie", state: "TX, USA" },
  { city: "Overland Park", state: "KS, USA" },
  { city: "Knoxville", state: "TN, USA" },
  { city: "Worcester", state: "MA, USA" },
  { city: "Brownsville", state: "TX, USA" },
  { city: "Newport News", state: "VA, USA" },
  { city: "Santa Clarita", state: "CA, USA" },
  { city: "Port St. Lucie", state: "FL, USA" },
  { city: "Providence", state: "RI, USA" },
  { city: "Fort Lauderdale", state: "FL, USA" },
  { city: "Chattanooga", state: "TN, USA" },
  { city: "Tempe", state: "AZ, USA" },
  { city: "Oceanside", state: "CA, USA" },
  { city: "Garden Grove", state: "CA, USA" },
  { city: "Cape Coral", state: "FL, USA" },
  { city: "Santa Rosa", state: "CA, USA" },
  { city: "Vancouver", state: "WA, USA" },
  { city: "Sioux Falls", state: "SD, USA" },
  { city: "Peoria", state: "AZ, USA" },
  { city: "Ontario", state: "CA, USA" },
  { city: "Jackson", state: "MS, USA" },
  { city: "Elk Grove", state: "CA, USA" },
  { city: "Springfield", state: "MO, USA" },
  { city: "Pembroke Pines", state: "FL, USA" },
  { city: "Salem", state: "OR, USA" },
  { city: "Corona", state: "CA, USA" },
  { city: "Eugene", state: "OR, USA" },
  { city: "McKinney", state: "TX, USA" },
  { city: "Fort Collins", state: "CO, USA" },
  { city: "Lancaster", state: "CA, USA" },
  { city: "Cary", state: "NC, USA" },
  { city: "Palmdale", state: "CA, USA" },
  { city: "Hayward", state: "CA, USA" },
  { city: "Salinas", state: "CA, USA" },
  { city: "Alexandria", state: "VA, USA" },
  { city: "Lakewood", state: "CO, USA" },
  { city: "Springfield", state: "MA, USA" },
  { city: "Pasadena", state: "TX, USA" },
  { city: "Sunnyvale", state: "CA, USA" },
  { city: "Macon", state: "GA, USA" },
  { city: "Pomona", state: "CA, USA" },
  { city: "Hollywood", state: "FL, USA" },
  { city: "Kansas City", state: "KS, USA" },
  { city: "Escondido", state: "CA, USA" },
  { city: "Clarksville", state: "TN, USA" },
  { city: "Joliet", state: "IL, USA" },
  { city: "Rockford", state: "IL, USA" },
  { city: "Torrance", state: "CA, USA" },
  { city: "Naperville", state: "IL, USA" },
  { city: "Paterson", state: "NJ, USA" },
  { city: "Savannah", state: "GA, USA" },
  { city: "Bridgeport", state: "CT, USA" },
  { city: "Mesquite", state: "TX, USA" },
  { city: "Killeen", state: "TX, USA" },
  { city: "Syracuse", state: "NY, USA" },
  { city: "McAllen", state: "TX, USA" },
  { city: "Pasadena", state: "CA, USA" },
  { city: "Bellevue", state: "WA, USA" },
  { city: "Fullerton", state: "CA, USA" },
  { city: "Orange", state: "CA, USA" },
  { city: "Dayton", state: "OH, USA" },
  { city: "Miramar", state: "FL, USA" },
  { city: "Thornton", state: "CO, USA" },
  { city: "West Valley City", state: "UT, USA" },
  { city: "Olathe", state: "KS, USA" },
  { city: "Hampton", state: "VA, USA" },
  { city: "Warren", state: "MI, USA" },
  { city: "Midland", state: "TX, USA" },
  { city: "Waco", state: "TX, USA" },
  { city: "Charleston", state: "SC, USA" },
  { city: "Denton", state: "TX, USA" },
  { city: "Victorville", state: "CA, USA" },
  { city: "Fairfield", state: "CA, USA" },
  { city: "El Monte", state: "CA, USA" },
  { city: "Buffalo", state: "NY, USA" },
  { city: "South Bend", state: "IN, USA" },
  { city: "Berkeley", state: "CA, USA" },
  { city: "Norman", state: "OK, USA" },
  { city: "Fargo", state: "ND, USA" },
  { city: "Columbia", state: "SC, USA" },
  { city: "Boulder", state: "CO, USA" },
  { city: "Broken Arrow", state: "OK, USA" },
  { city: "Palm Bay", state: "FL, USA" },
  { city: "Sandy Springs", state: "GA, USA" },
  { city: "Roanoke", state: "VA, USA" },
  { city: "Billings", state: "MT, USA" },
  { city: "Surprise", state: "AZ, USA" },
  { city: "Flint", state: "MI, USA" },
  { city: "Gresham", state: "OR, USA" },
  { city: "Antioch", state: "CA, USA" },
  { city: "Erie", state: "PA, USA" },
  { city: "Daly City", state: "CA, USA" },
  { city: "Centennial", state: "CO, USA" },
  { city: "Temecula", state: "CA, USA" },
  { city: "Inglewood", state: "CA, USA" },
  { city: "West Palm Beach", state: "FL, USA" },
  { city: "Clovis", state: "CA, USA" },
  { city: "Waterbury", state: "CT, USA" },
  { city: "Cambridge", state: "MA, USA" },
  { city: "Everett", state: "WA, USA" },
  { city: "Lakeland", state: "FL, USA" },
  { city: "Pompano Beach", state: "FL, USA" },
  { city: "Garden Grove", state: "CA, USA" },
  { city: "McKinney", state: "TX, USA" },
  { city: "Paterson", state: "NJ, USA" },
  { city: "Roseville", state: "CA, USA" },
  { city: "Carson", state: "CA, USA" },
  { city: "Santa Maria", state: "CA, USA" },
  { city: "El Cajon", state: "CA, USA" },
  { city: "San Mateo", state: "CA, USA" },
  { city: "Lewisville", state: "TX, USA" },
  { city: "Rialto", state: "CA, USA" },
  { city: "Davenport", state: "IA, USA" },
  { city: "Lakewood", state: "CA, USA" },
  { city: "Tuscaloosa", state: "AL, USA" },
  { city: "Sandy", state: "UT, USA" },
  { city: "Mission Viejo", state: "CA, USA" },
  { city: "Livermore", state: "CA, USA" },
  { city: "Youngstown", state: "OH, USA" },
  { city: "Jonesboro", state: "AR, USA" },
  { city: "Bellingham", state: "WA, USA" },
  { city: "Greeley", state: "CO, USA" },
  { city: "Taylorsville", state: "UT, USA" },
  { city: "Lafayette", state: "LA, USA" },
  { city: "Federal Way", state: "WA, USA" },
  { city: "Reading", state: "PA, USA" },
  { city: "Fort Smith", state: "AR, USA" },
  { city: "Newport Beach", state: "CA, USA" },
  { city: "Asheville", state: "NC, USA" },
  { city: "Green Bay", state: "WI, USA" },
  { city: "Albany", state: "NY, USA" },
  { city: "Edinburg", state: "TX, USA" },
  { city: "Beaumont", state: "TX, USA" },
  { city: "Scranton", state: "PA, USA" },
  { city: "Trenton", state: "NJ, USA" },
  { city: "Roswell", state: "GA, USA" },
  { city: "Mission", state: "TX, USA" },
  { city: "Lawrence", state: "KS, USA" },
  { city: "Bellflower", state: "CA, USA" },
  { city: "Plantation", state: "FL, USA" },
  { city: "Fishers", state: "IN, USA" },
  { city: "Muncie", state: "IN, USA" },
  { city: "Lynwood", state: "CA, USA" },
  { city: "Concord", state: "NC, USA" },
  { city: "Livermore", state: "CA, USA" },
  { city: "Moore", state: "OK, USA" },
  { city: "Iowa City", state: "IA, USA" },
  { city: "Schaumburg", state: "IL, USA" },
  { city: "Homestead", state: "FL, USA" },
  { city: "Decatur", state: "IL, USA" },
  { city: "Hemet", state: "CA, USA" },
  { city: "Kalamazoo", state: "MI, USA" },
  { city: "Albany", state: "GA, USA" },
  { city: "Council Bluffs", state: "IA, USA" },
  { city: "Deltona", state: "FL, USA" },
  { city: "Flower Mound", state: "TX, USA" },
  { city: "Palatine", state: "IL, USA" },
  { city: "Jupiter", state: "FL, USA" },
];

export const ProspectLocationOptions = ProspectAddressOptions.map((address) => {
  return {
    value: `${address.city}, ${address.state}`,
    label: `${address.city}, ${address.state}`,
  };
});
