import { Controller } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { useFormContext } from "react-hook-form";
import { Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { getActionsByEventType } from "@/redux/reducers/triggers/slices/listTriggers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import CreateTaskForm from "./CreateTaskForm";
import EnrollSequenceForm from "./EnrollSequenceForm";
import SetDealFlowStageForm from "./SetDealFlowStageForm";
import CancelSequenceForm from "./CancelSequenceForm";
import CreateDealForm from "./CreateDealForm";
import SendEmailForm from "./SendEmailForm";
import SendSmsForm from "./SendSmsForm";
import FormLabel from "@/common/components/form-label/FormLabel";

export enum ActionsType {
  CREATE_TASK = "CREATE_TASK",
  ENROLL_SEQUENCE = "ENROLL_SEQUENCE",
  SET_DEAL_FLOW_STAGE = "SET_DEAL_FLOW_STAGE",
  CANCEL_SEQUENCE = "CANCEL_SEQUENCE",
  CREATE_DEAL = "CREATE_DEAL",
  SEND_EMAIL = "SEND_EMAIL",
  SEND_SMS = "SEND_SMS",
}

export const getActionLabel = (actionType: string) => {
  switch (actionType) {
    case ActionsType.CREATE_TASK:
      return "Create Task";
    case ActionsType.ENROLL_SEQUENCE:
      return "Enroll Sequence";
    case ActionsType.SET_DEAL_FLOW_STAGE:
      return "Set Deal Flow Stage";
    case ActionsType.CANCEL_SEQUENCE:
      return "Cancel Sequence";
    case ActionsType.CREATE_DEAL:
      return "Create Deal";
    case ActionsType.SEND_EMAIL:
      return "Send Email";
    case ActionsType.SEND_SMS:
      return "Send SMS";
    default:
      return "";
  }
};

export default function ActionStep() {
  const methods = useFormContext();
  const dispatch = useAppDispatch();
  const { triggerActions, triggerActionsPending } = useAppSelector(({ triggers }) => triggers.listTriggers);
  const eventType = methods.watch("event.eventType");
  const actionType = methods.watch("action.actionType");
  console.log(actionType);

  useEffect(() => {
    dispatch(getActionsByEventType(eventType));
  }, [dispatch, eventType]);

  // TODO isLoading?
  if (triggerActionsPending) {
    return <Typography>loading</Typography>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      <Typography variant="body1">Select Action</Typography>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.actionType"
          control={methods.control}
          render={({ field }) => (
            <>
              <FormLabel label="Action" aria-required />
              <Dropdown
                label="Action"
                placeholder="Select Action"
                isPending={triggerActionsPending}
                options={triggerActions.map((action) => ({
                  label: getActionLabel(action),
                  value: action,
                }))}
                value={field.value}
                onChange={(value) => field.onChange(value.value)}
              />
            </>
          )}
        />
      </FormControl>

      {actionType ? <Divider /> : null}

      {actionType === ActionsType.CREATE_TASK && <CreateTaskForm />}
      {actionType === ActionsType.ENROLL_SEQUENCE && <EnrollSequenceForm />}
      {actionType === ActionsType.SET_DEAL_FLOW_STAGE && <SetDealFlowStageForm />}
      {actionType === ActionsType.CANCEL_SEQUENCE && <CancelSequenceForm />}
      {actionType === ActionsType.CREATE_DEAL && <CreateDealForm />}
      {actionType === ActionsType.SEND_EMAIL && <SendEmailForm />}
      {actionType === ActionsType.SEND_SMS && <SendSmsForm />}
    </div>
  );
}
