import { useAppSelector } from "@/redux/store";

import Searchbar from "@/common/components/searchbar/Searchbar";

import { getProspectsCount } from "@/redux/selectors/prospects";
type ProspectFiltersProps = {
  onSetFilters?: (filters: { searchString?: string }) => void;
};
export default function ProspectFilters({ onSetFilters }: ProspectFiltersProps) {
  const hasProspect = useAppSelector(getProspectsCount);

  return (
    <>
      {hasProspect > 0 && (
        <Searchbar
          sx={{ width: "450px" }}
          placeholder="Search for a prospect"
          onChange={(e) => {
            onSetFilters?.({ searchString: e.target.value });
          }}
        />
      )}
    </>
  );
}
