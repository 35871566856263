import { KnCompanyHeadcountEnum, KnCompanyIndustryEnum, KnCompanyRevenueEnum } from "@/services/generated";

export const CompanyHeadcountOptions = Object.values(KnCompanyHeadcountEnum).map((action) => ({
  label: action,
  value: action,
}));

export const CompanyIndustryOptions = Object.values(KnCompanyIndustryEnum).map((action) => ({
  label: action,
  value: action,
})) as [{ label: string; value: string }];

CompanyIndustryOptions.push(
  { label: "Engineering", value: "Engineering" },
  { label: "Sales", value: "Sales" },
  { label: "Marketing", value: "Marketing" },
  { label: "Product", value: "Product" },
  { label: "Finance", value: "Finance" },
  { label: "HR", value: "HR" },
  { label: "Operations", value: "Operations" },
  { label: "Legal", value: "Legal" },
  { label: "Other", value: "Other" },
);

export const CompanyRevenueOptions = Object.values(KnCompanyRevenueEnum).map((action) => ({
  label: action,
  value: action,
}));
