import { Box, Typography, useTheme } from "@mui/material";
import Avatar from "../avatar/Avatar";
import Accordion from "../accordion/Accordion";
import Envelope from "../../assets/svg/sequence/envelope.svg";
import Phone from "../../assets/svg/sequence/phone.svg";
import { SequenceAction as SequenceActionInterface } from "@/types/sequence";
import AiAgent from "@/pages/inbox/tabs/AiAgent";
import parse from "html-react-parser";
import { KnAiAgent } from "@/services/generated";

type SequenceActionProps = {
  actionType: SequenceActionInterface["actionType"];
  expanded?: boolean;
  subject?: string;
  content?: string;
  agent: KnAiAgent | null;
  setIsExpanded?: (value: boolean) => void;
};

const getTitle = (actionType: string) => {
  switch (actionType) {
    case "SMS":
      return "Send SMS";
    case "EMAIL":
      return "Send Email";
    case "CALL":
      return "AI Call";
    default:
      return "";
  }
};

const getIcon = (actionType: string) => {
  switch (actionType) {
    case "SMS":
    case "CALL":
      return Phone;
    case "EMAIL":
      return Envelope;
  }
};

export const SequenceActionContent = (props: SequenceActionProps) => {
  const theme = useTheme();
  const { actionType = "", subject, content, agent, expanded = false, setIsExpanded } = props;

  const actionIcon = getIcon(actionType);

  return (
    <Box sx={{ padding: "16px", background: theme.palette.bg.inverse, borderRadius: "8px", height: "100%" }}>
      <Accordion
        handleChange={() => setIsExpanded?.(!expanded)}
        summaryTitle={
          <Box style={{ justifyContent: "flex-start", alignItems: "center", gap: "8px", display: "flex" }}>
            {actionIcon && (
              <Avatar size="large" background={theme.palette.icon.informative} src={actionIcon} isIconSize />
            )}
            <Typography
              variant="body2"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              {getTitle(actionType)}
            </Typography>
          </Box>
        }
        id={actionType}
        disabledBorderBottom
        expanded={expanded}
      >
        <Box
          sx={{
            alignSelf: "stretch",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "16px",
            display: "flex",
            paddingLeft: "4px",
            marginTop: "4px",
          }}
        >
          {subject && (
            <Typography variant="body2" fontWeight={700}>
              {subject}
            </Typography>
          )}

          {(actionType === "SMS" || actionType === "EMAIL") && (
            <Typography variant="body2">{parse(content || "No content")}</Typography>
          )}

          {agent && <AiAgent agent={agent} />}
        </Box>
      </Accordion>
    </Box>
  );
};
