import Box from "@mui/material/Box";
import { useEffect, useMemo } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { ProspectJobTitleOptions, ProspectAddressOptions } from "@/redux/reducers/prospects/slices/helpers";
import { CompanyIndustryOptions } from "@/redux/reducers/prospects/companies/slices/helpers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getProspectFinderCompanies } from "@/redux/reducers/prospects/finder/slices/listProspectFinder";
import debounce from "@/common/functions/debounce";
import { ProspectFinderFiltersType } from "./ProspectFinder";

interface ProspectFinderFiltersProps {
  toggleAdvancedFilters: (value: boolean) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  setSearchString?: (value: string) => void;
  filters: ProspectFinderFiltersType;
  setFilters: (filters: ProspectFinderFiltersType) => void;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
}

interface Company {
  name: string;
  id: string;
  domain?: string;
}

export default function ProspectFinderFilters(props: ProspectFinderFiltersProps) {
  const { setFilters, filters } = props;
  const dispatch = useAppDispatch();

  const companies = useAppSelector(({ prospectFinder }) => prospectFinder.listProspectsFinder.companies);

  const isCompaniesLoading = useAppSelector(
    ({ prospectFinder }) => prospectFinder.listProspectsFinder.isCompaniesLoading,
  );

  const companyNames = useMemo(() => {
    return (
      companies?.map((company: Company) => ({
        label: company.name || "",
        value: company.id || "",
        domain: company.domain || "",
      })) ?? []
    );
  }, [companies]);
  const ProspectLocationOptions = useMemo(
    () =>
      ProspectAddressOptions.map((address) => ({
        value: address.city,
        label: `${address.city}, ${address.state}`,
      })),
    [],
  );

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters({
          prospectName: value,
          // Mantener otros filtros sin cambios si es necesario
        });
      }, 500),
    [setFilters],
  );

  const filterFields = useMemo(
    () => [
      {
        name: "location",
        label: "Location",
        options: ProspectLocationOptions,
      },
      {
        name: "companyDomain",
        label: "Company",
        options: companyNames,
      },
      {
        name: "jobTitle",
        label: "Job Title",
        options: ProspectJobTitleOptions,
      },
      {
        name: "industry",
        label: "Industry",
        options: CompanyIndustryOptions,
      },
    ],
    [companyNames, ProspectLocationOptions],
  );

  useEffect(() => {
    if (companies === undefined) {
      dispatch(getProspectFinderCompanies({ searchTerm: "a" }));
    }
  }, [companies, dispatch]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Searchbar
        sx={{ width: "450px" }}
        placeholder="Search for a prospect"
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          debouncedSetFilters(value);
        }}
      />
      {filterFields.map(({ label, name, options }) => (
        <Dropdown
          key={name}
          isSearchable
          label={label}
          placeholder={label}
          options={[{ label: "All", value: "" }, ...(options || [])]}
          isPending={name === "company" && isCompaniesLoading}
          onChange={(selectedOption) => {
            console.log("Changing", name, selectedOption?.value);

            setFilters({
              [name]: selectedOption?.value,
              ...filters,
            });
          }}
          isClearable
        />
      ))}
    </Box>
  );
}
