import { useEffect } from "react";
import { Box, ButtonBase, Theme, useTheme } from "@mui/material";
import DealsHeader from "@/common/components/deals-card/DealsHeader";
import Heatmap from "./HeatMap";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/redux/store";
import { CogOutline } from "heroicons-react";
import LeadFlowCard from "./LeadFlowCard";
import { useAppDispatch } from "@/redux/store";
import { getLeadFlowConfig } from "@/redux/reducers/leadFlow/slices/configLeadFlow";
import { getLeadReports, getLeads, getLeadsCount } from "@/redux/selectors";
import { getLeadsList } from "@/redux/reducers/leadFlow/slices/listLeads";
import { getLeadFlowReports } from "@/redux/reducers/leadFlow/slices/getReports";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import routes from "@/common/constants/routes";
import EmptyState from "@/common/components/empty-state/EmptyState";
import { FunnelIcon } from "@heroicons/react/24/outline";

export default function LeadFlowPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const leadCount = useAppSelector(getLeadsCount);
  const isLoadingLeads = useAppSelector(({ leadFlow }) => leadFlow.listLeads.loading);
  const leadsCards = useAppSelector(getLeads);
  const leadFlowReports = useAppSelector(getLeadReports);
  const isLoadingLeadFlowReports = useAppSelector(({ leadFlow }) => leadFlow.getReports.loading);
  const leadFlowConfig = useAppSelector(({ leadFlow }) => leadFlow.configLeadFlow.configuration);
  const isLoadingLeadFlowConfig = useAppSelector(({ leadFlow }) => leadFlow.configLeadFlow.loading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (leadFlowConfig === undefined && !isLoadingLeadFlowConfig) {
      dispatch(getLeadFlowConfig());
    }
  }, [dispatch, isLoadingLeadFlowConfig, leadFlowConfig, navigate]);

  useEffect(() => {
    if (leadsCards === undefined && !isLoadingLeads) {
      dispatch(getLeadsList());
    }
  }, [dispatch, isLoadingLeads, leadsCards]);

  useEffect(() => {
    if (leadFlowReports === undefined && !isLoadingLeadFlowReports) {
      dispatch(getLeadFlowReports());
    }
  }, [dispatch, isLoadingLeadFlowReports, leadFlowReports]);

  if (isLoadingLeadFlowConfig || leadFlowConfig === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <main>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ fontSize: "24px", fontWeight: "bold", color: "#000000" }}>{t("leadFlow.title")}</Box>
        <ButtonBase
          sx={(theme: Theme) => ({
            p: 1.2,
            bgcolor: "white",
            borderRadius: 2,
            border: 1,
            borderColor: theme.palette.commonColors.light,
          })}
          onClick={() => navigate(routes.leadFlow.setup.path)}
        >
          <CogOutline />
        </ButtonBase>
      </Box>
      <Box>
        {leadFlowConfig === null && (
          <EmptyState
            title="Lead flow not configured yet"
            description="Begin creating a flow by clicking the Configure Lead Flow button above."
            Icon={FunnelIcon}
          />
        )}

        {leadFlowConfig !== null && (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Heatmap />

            <Box
              sx={(theme) => ({
                flex: "none",
                borderRadius: 2,
                flexDirection: "column",
                display: "flex",
                width: 224,
                backgroundColor: theme.palette.midnight[20],
                border: "1px solid #E7EBED",
                transition: "all .3s",
                padding: "4px",
                gap: 1,
                height: "824px",
              })}
            >
              <DealsHeader title="Booking" color={theme.palette.midnight.p} count={leadCount} />
              <Box
                sx={{
                  height: "738px",
                  flex: "none",
                  flexDirection: "column",
                  display: "flex",
                  overflowY: "hidden",
                  ":hover": {
                    overflowY: "auto",
                  },
                  "&::-webkit-scrollbar": {
                    width: 0,
                  },
                  gap: 1,
                }}
              >
                {leadsCards &&
                  leadsCards.map((lead) => (
                    <LeadFlowCard
                      key={lead.id}
                      title={lead.contact ? `${lead.contact.firstName} ${lead.contact.lastName}` : ""}
                      lastActivityType="SMS"
                      heatScore={0}
                    />
                  ))}
                <LoadingSpinner loading={leadsCards === undefined} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </main>
  );
}
