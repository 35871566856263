import { DataGrid, GridColDef, GridDeleteIcon } from "@mui/x-data-grid";
import { Avatar, Typography, Box, FormControl, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import { mockRoles } from "../../mock";
import AddUser from "../create";
import { useState, useMemo } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { createColumnHelper, CellContext } from "@tanstack/react-table";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { usePagination } from "@/common/components/pagination/usePagination";
import TsTable from "@/common/components/ts-table";
import SearchBar from "@/common/components/searchbar/Searchbar";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { deleteUserFromTeam, updateTeamUser } from "@/redux/reducers/settings/team";
import { KnTeamUserRole, UpdateTeamUserRequest, UpdateTeamUserRequestUserRoleEnum } from "@/services/generated";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { useAuth0 } from "@auth0/auth0-react";

const columnHelper = createColumnHelper<TeamUser>();

interface TeamUser extends KnTeamUserRole {
  delete: string;
}

export default function UserManagementTable() {
  const { user } = useAuth0();
  const users = useAppSelector(({ settings }) => settings.team.users);
  const loadingUpdateUser = useAppSelector(({ settings }) => settings.team.loadingUpdateUser); // Selecciona el estado de carga
  const { onPaginationChange, pagination } = usePagination();
  const { deleteConfirm } = useConfirmModal();
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleRoleChange = (user: TeamUser, newRole: { label: string; value: UpdateTeamUserRequestUserRoleEnum }) => {
    const updateRequest: UpdateTeamUserRequest = {
      userId: user.user_id || "",
      userRole: newRole.value,
      teamId: user.team_id || "",
    };

    dispatch(updateTeamUser(updateRequest));
  };

  const handleDelete = async (id: string | undefined) => {
    try {
      await deleteConfirm({
        textDiv: (
          <>
            <Typography variant="body1">Are you sure you want to delete this user from this team?</Typography>
            <Typography variant="body1">This action cannot be undone.</Typography>
          </>
        ),
      });
      dispatch(deleteUserFromTeam(id || ""));
    } catch {}
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("firstName", {
        header: ({ table }: any) => (
          <div style={{ display: "flex", gap: "18px", alignItems: "center", marginLeft: "16px" }}>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
            <Typography variant="body3" fontWeight="600">
              USER FULL NAME
            </Typography>
          </div>
        ),
        cell: (info) => {
          const row = info.row;
          return (
            <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
              <Typography variant="body2">{`${info.getValue()} ${row.original.lastName}`}</Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor("calendarConnected", {
        header: () => (
          <Typography variant="body3" fontWeight="600">
            Calendar Connected
          </Typography>
        ),
        cell: (info) => <Typography variant="body2">{info.getValue() ? "Yes" : "No"}</Typography>,
      }),
      columnHelper.accessor("email", {
        header: () => (
          <Typography variant="body3" fontWeight="600">
            Email
          </Typography>
        ),
        cell: (info) => <Typography variant="body2">{info.getValue()}</Typography>,
      }),
      columnHelper.accessor("userRole", {
        header: () => (
          <Typography variant="body3" fontWeight="600">
            Role
          </Typography>
        ),
        cell: (info) => (
          <Dropdown
            label="Role"
            placeholder="Select a role"
            isDisabled={user?.team_user_role !== "SALES_MANAGER"}
            options={mockRoles?.map((role) => ({
              label: role.roleName,
              value: role.roleCode,
            }))}
            defaultValue={info.getValue()}
            value={info.getValue()}
            onChange={(newValue) => handleRoleChange(info.row.original, newValue)}
          />
        ),
      }),
      columnHelper.accessor("delete", {
        header: () => <Typography variant="body3" fontWeight="600"></Typography>,
        cell: (info) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <IconButton aria-label="delete" onClick={() => handleDelete(info.row.original.user_id)}>
              <GridDeleteIcon color="error" />
            </IconButton>
          </Box>
        ),
      }),
    ],
    [],
  );

  const table = useReactTable<any>({
    data: users || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: users.length,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <>
      <Box sx={{ display: "flex", flex: 1, gap: "16px", flexDirection: "column", position: "relative" }}>
        {loadingUpdateUser && <LoadingSpinner />}
        {!loadingUpdateUser && (
          <>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "0.2px",
              }}
            >
              26 paid users of 100
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "50%" }}>
                <SearchBar sx={{ flexGrow: 1 }} placeholder="Search" />
              </Box>
              <Box>
                <AddUser toggleDrawer={toggleDrawer} isOpen={isOpen} />
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <TsTable table={table} handleCellClick={() => null} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
