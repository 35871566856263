import React from "react";
import { Box, Typography } from "@mui/material";

export default function PageHeader({
  title,
  badge,
  actions,
  filters,
}: {
  title: string;
  badge?: React.ReactNode;
  actions?: React.ReactNode;
  filters?: React.ReactNode;
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px", height: "48px" }}>
          <Typography fontSize="24px" fontWeight="bold">
            {title}
          </Typography>
          <Box sx={{ display: "block", marginLeft: "8px" }}> {badge}</Box>
        </Box>
        {actions}
      </Box>

      {filters}
    </Box>
  );
}
