import Services from "@/services";
import {
  KnFilterOptionOperatorEnum,
  KnFirestoreImportJob,
  KnFirestoreImportJobImportJobTypeEnum,
} from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataImportFiltersInput } from "@/pages/data-import";

type State = {
  data?: KnFirestoreImportJob[];
  importType: KnFirestoreImportJobImportJobTypeEnum;
  loading: boolean;
};

const initialState: State = {
  importType: "" as KnFirestoreImportJobImportJobTypeEnum,
  loading: false,
};

export const getImportData = createAsyncThunk(
  "dataImport/getImportData",
  async (input: DataImportFiltersInput, { rejectWithValue, dispatch }) => {
    try {
      const filters = [];
      if (input.objectType) {
        filters.push({
          property: "importJobType",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Equal,
              values: [input.objectType],
            },
          ],
        });
      }
      const response = await Services.DataImport.lookupFirestoreImportJobs({
        filters,
        pageSize: 10,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const dataImportListSlice = createSlice({
  name: "dataImport/list",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getImportData.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getImportData.fulfilled, (state, { payload }) => {
        state.data = payload.results;
        state.loading = false;
      })
      .addCase(getImportData.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
  reducers: {
    setImportType: (state, action) => {
      state.importType = action.payload;
    },
  },
});

export const { setImportType } = dataImportListSlice.actions;

export default dataImportListSlice.reducer;
