import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import * as Yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import { updateTeamAiVoiceTwilio } from "@/redux/reducers/settings/team-ai-voice";
import { ReactComponent as TwilioImage } from "@/common/assets/svg/settings/twilio.svg";
import CloseIcon from "@mui/icons-material/Close";
import SalesAi from "@/common/assets/svg/salesai_logo.svg";
import { ArrowsRightLeftIcon } from "@heroicons/react/16/solid";

const schema = Yup.object({
  twilioAccountSid: Yup.string().required(),
  twilioAuthToken: Yup.string().required(),
});

interface Prop {
  isOpen: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TwilioDialog(prop: Prop) {
  const open = prop.isOpen;
  const handleClose = prop.handleClose;

  const dispatch = useAppDispatch();
  const teamAiVoice = useAppSelector(({ settings }) => settings.teamAiVoice.active);
  const { control, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      twilioAccountSid: teamAiVoice.twilioAccountSid || "",
      twilioAuthToken: "",
    },
  });

  const onSubmit: SubmitHandler<Yup.InferType<typeof schema>> = (data) => {
    if (!data) {
      return;
    }
    dispatch(
      updateTeamAiVoiceTwilio({
        twilio_account_sid: data.twilioAccountSid,
        twilio_auth_token: data.twilioAuthToken,
      }),
    );
    handleClose();
  };

  const onError: SubmitErrorHandler<Yup.InferType<typeof schema>> = (errors) => {
    console.log(errors);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#1A2135",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          Add Integration
        </Typography>
        <Box
          onClick={handleClose}
          sx={{
            display: "flex",
            alignSelf: "center",
          }}
        >
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#1A2135",
            }}
          />
        </Box>
      </DialogTitle>

      <Box
        sx={{
          background: "#E0E0E0",
          height: "1px",
          flexShrink: 0,
          alignSelf: "stretch",
        }}
      />
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Images />

        <Title />

        <DescriptionContainer />

        <Controller
          name="twilioAccountSid"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Account SID" aria-required infoIcon />
              <Input {...field} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="twilioAuthToken"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Auth Token" aria-required infoIcon />
              <Input type="password" {...field} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Box
          sx={{
            display: "flex",
            gap: "2px",
          }}
        >
          <Typography
            sx={{ color: "#1A2135", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "20px" }}
          >
            Don’t have an account?
          </Typography>
          <a
            href="/"
            style={{
              textDecoration: "none",
              color: "#2C72E2",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              letterSpacing: "0.2px",
            }}
          >
            Click Here
          </a>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Button size="small" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(onSubmit, onError)}>
            Connect
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const Images = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      alignSelf: "stretch",
    }}
  >
    <img
      alt="SalesAI Logo"
      src={SalesAi}
      style={{
        display: "flex",
        width: "42px",
        height: "42px",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
    <ArrowsRightLeftIcon
      style={{
        display: "flex",
        width: "24px",
        height: "24px",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
    <TwilioImage
      style={{
        display: "flex",
        width: "42px",
        height: "42px",

        justifyContent: "center",
        alignItems: "center",
        gap: "6.4px",
        borderRadius: "3.048px",
      }}
    />
  </Box>
);

const Title = () => (
  <Typography
    sx={{
      color: "#1A2135",
      fontWeight: 400,
      fontSize: "18px",
      textAlign: "center",
      marginTop: "16px",
    }}
  >
    Adding integration with{" "}
    <span
      style={{
        fontWeight: 600,
      }}
    >
      Twilio
    </span>
  </Typography>
);

const DescriptionContainer = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "4px",
      alignSelf: "stretch",
      background: "#F2F4F8",
      borderRadius: "8px",
      padding: "8px",
    }}
  >
    <Typography sx={{ color: "#1A2135", fontSize: "14px" }}>
      Enter your account’s SID and auth token below to complete the integration of your{" "}
      <span
        style={{
          fontWeight: 600,
        }}
      >
        Twilio
      </span>{" "}
      account.
    </Typography>
    <a
      href="/"
      style={{
        textDecoration: "none",
        color: "#2C72E2",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.2px",
      }}
    >
      What is a Twilio account SID and where can I find it?
    </a>{" "}
  </Box>
);
