import { Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Dropdown from "@/common/components/dropdown";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useMemo } from "react";
import { getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";

export default function DealFlowStateChangeForm() {
  const methods = useFormContext();
  const { control } = methods;
  const dispatch = useAppDispatch();
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines) || [];
  const selectedPipelineId = methods.watch("event.dealFlowId");

  useEffect(() => {
    dispatch(getPipelines());
  }, [dispatch]);

  const pipelineOptions = useMemo(() => {
    return pipelines.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
  }, [pipelines]);

  const pipelineStageOptions = useMemo(() => {
    const pipelineStages = pipelines.find((p) => p.id === selectedPipelineId)?.stages || [];
    return pipelineStages.map((s) => {
      return {
        label: s.name,
        value: s.id,
      };
    });
  }, [pipelines, selectedPipelineId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        Pipeline
      </Typography>
      {/*<Controller*/}
      {/*  name="event.dealFlow"*/}
      {/*  control={control}*/}
      {/*  render={({ field, fieldState: { error } }) => (*/}
      {/*    <>*/}
      {/*      {dealFlowOptions.map((option) => {*/}
      {/*        return (*/}
      {/*          <label*/}
      {/*            key={option.value}*/}
      {/*            htmlFor={option.value}*/}
      {/*            style={{ cursor: "pointer", width: "100%", marginTop: "10px" }}*/}
      {/*          >*/}
      {/*            <div style={{ display: "flex", gap: "5px" }}>*/}
      {/*              <input*/}
      {/*                type="radio"*/}
      {/*                className="radio"*/}
      {/*                id={option.value}*/}
      {/*                value={option.value}*/}
      {/*                checked={option.value === field.value}*/}
      {/*              />*/}
      {/*              <Typography variant="body2">{option.label}</Typography>*/}
      {/*            </div>*/}
      {/*          </label>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*/>*/}
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="event.dealFlowId"
          control={control}
          render={({ field }) => {
            return (
              <Dropdown
                label="Deal Flow Pipeline"
                options={pipelineOptions}
                onChange={(option) => {
                  field.onChange(option.value);
                }}
                value={field.value}
              />
            );
          }}
        />
      </FormControl>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        State Change
      </Typography>
      <Controller
        name="event.dealStageChangeType"
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <Dropdown
              label="Deal Flow Pipeline"
              options={pipelineStageOptions}
              onChange={(option) => {
                field.onChange(option.value);
              }}
              value={field.value}
            />
          );
        }}
      />
    </div>
  );
}
