import Avatar from "@/common/components/avatar";
import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import debounce from "@/common/functions";
import DrawerLayout from "@/layouts/DrawerLayout";
import { ActionButtons } from "@/pages/inbox/send-new/ActionButtons";
import {
  closeSendNewMessageDrawer,
  openSendNewMessageDrawer,
  openViewDrawer,
} from "@/redux/reducers/inbox/slices/getInboxData";
import { getProspectsByLastName } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";

const schema = Yup.object({
  prospectId: Yup.string(),
});

export type Form = Yup.InferType<typeof schema>;

export default function SendNewMessage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const createSmsPending = useAppSelector(({ inbox }) => inbox.getInboxData.createSmsPending);
  const sendNewMessageDrawerIsOpen = useAppSelector(({ inbox }) => inbox.getInboxData.sendNewMessageDrawerIsOpen);
  const { prospects, loading } = useAppSelector(({ prospects }) => prospects.listProspects);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      prospectId: "",
    },
  });
  const { control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<Form> = (data) => {
    // On select a prospect to send new message
    // 1. Set the current prospect
    // 2. Open the view drawer
    // 3. Close the send new message drawer
    if (!data || !prospects) return;
    const prospect = prospects.find((prospect) => prospect.id === data.prospectId);
    dispatch(setCurrentProspect(prospect));
    dispatch(openViewDrawer());
    dispatch(closeSendNewMessageDrawer());
  };

  const handleInputChangeContact = useMemo(
    () =>
      debounce((value) => {
        if (value.length < 1) return;
        dispatch(getProspectsByLastName({ lastName: value }));
      }, 500),
    [dispatch],
  );

  const contactOptions = useMemo(
    () =>
      (prospects || []).map((contact) => ({
        icon: () => <Avatar src={""} size="small" />,
        value: contact.id!,
        label: contact.firstName! + " " + contact.lastName!,
      })),
    [prospects],
  );

  const MessageComposer = () => (
    <Box role="presentation">
      <Box sx={{ display: "flex", height: "90vh", flexDirection: "column", justifyContent: "space-between" }}>
        <FormControl sx={{ flexGrow: 1 }}>
          <Controller
            name="prospectId"
            control={control}
            render={({ field }) => {
              return (
                <>
                  <FormLabel label="Select Prospect" aria-required />
                  <Dropdown
                    placeholder="Select Prospect"
                    isSearchable
                    onInputChange={handleInputChangeContact}
                    isPending={loading}
                    options={contactOptions}
                    value={field.value}
                    onChange={(option) => field.onChange(option.value)}
                  />
                </>
              );
            }}
          />
        </FormControl>

        <ActionButtons sendText="Select" onSubmit={onSubmit} />
      </Box>
    </Box>
  );

  return (
    <>
      {createSmsPending && <FullLoadingOverlay loading={true} overlay={true} />}

      <Button variant="primary" onClick={() => dispatch(openSendNewMessageDrawer())}>
        {t("inbox.newMessage")}
      </Button>
      <Drawer open={sendNewMessageDrawerIsOpen} onClose={() => dispatch(closeSendNewMessageDrawer())} anchor="right">
        <DrawerLayout
          title="Send New Message"
          testid="newProspectForm"
          closeDrawer={() => dispatch(closeSendNewMessageDrawer())}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <MessageComposer />
            </FormProvider>
          </form>
        </DrawerLayout>
      </Drawer>
    </>
  );
}
