import { Box, useTheme } from "@mui/material";
import { Table, flexRender } from "@tanstack/react-table";
import ServerPagination from "@/common/components/pagination/ServerPagination";
import EmptyState from "../empty-state/EmptyState";
import routes from "@/common/constants/routes";
import { useLocation } from "react-router-dom";

interface HasId {
  id: string | number;
}

interface TableProps<T extends HasId> {
  table: Table<T>;
  handleCellClick?: (id: string | number) => void;
  notFoundProps?: {
    Icon: React.ElementType;
    title: string;
    description?: string | React.ReactNode;
  };
}

export default function TsTable<T extends HasId>(props: TableProps<T>) {
  const location = useLocation();
  const { table, handleCellClick, notFoundProps } = props;

  const theme = useTheme();
  const rows = table.getRowModel().rows;

  if (rows.length === 0 && notFoundProps) {
    return notFoundProps ? <EmptyState {...notFoundProps} /> : null;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <table
        style={{
          width: "100%",
          border: `1px solid ${theme.palette.commonColors.light}`,
          borderRadius: "8px",
        }}
      >
        <thead
          style={{
            backgroundColor: "#f5f5f5",
            color: "#000",
            fontWeight: "bold",
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <th
                    key={header.id}
                    style={{
                      padding: "12px 16px",
                      background: "#F0F0F0",
                      textTransform: "uppercase",
                      fontWeight: 400,
                      fontSize: "14px",
                      borderTopLeftRadius: index === 0 ? "8px" : "0",
                      borderTopRightRadius: index === headerGroup.headers.length - 1 ? "8px" : "0",
                    }}
                    align="left"
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, cellIndex) => (
                <td
                  onClick={() => {
                    if (location.pathname === routes.triggers.path && cell.column.id === "status") return;
                    if (cell.column.id === "actions") return;
                    if (cell.column.id === "checkbox") return;
                    if (!handleCellClick) return;
                    handleCellClick(row.original.id);
                  }}
                  style={{
                    cursor: "pointer",
                    padding: "12px 16px",
                    fontWeight: 400,
                    fontSize: "14px",
                    background: row.index % 2 === 0 ? "#fff" : "#FBFBFB",
                    borderBottomLeftRadius: cellIndex === 0 ? "8px" : "0",
                    borderBottomRightRadius: cellIndex === row.getVisibleCells().length - 1 ? "8px" : "0",
                    height: "64px",
                  }}
                  key={cell.id}
                  align="left"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <ServerPagination tableLib={table} />
    </Box>
  );
}
