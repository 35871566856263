export default {
  urlWebapp: window.env?.REACT_APP_URL_WEBAPP,
  urlApiGateway: window.env?.REACT_APP_URL_API_GATEWAY,
  urlApiWebhook: window.env?.REACT_APP_URL_API_WEBHOOK,
  auth0Domain: window.env?.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: window.env?.REACT_APP_AUTH0_CLIENT_ID,
  auth0Audience: window.env?.REACT_APP_AUTH0_AUDIENCE,
  userflowToken: window.env?.REACT_APP_USERFLOW_TOKEN,
  stripePublicUrl: window.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  flagAiVoice: window.env?.REACT_APP_AI_VOICE,
  flagAiVoiceFlowPrompt: window.env?.REACT_APP_AI_VOICE_FLOW_PROMPT,
  flagAiVoiceFlowFaq: window.env?.REACT_APP_AI_VOICE_FLOW_FAQ,
  flagAiVoiceFlowQualifyAndBook: window.env?.REACT_APP_AI_VOICE_FLOW_QUALIFY_AND_BOOK,
  flagAiVoiceFlowQualifyAndLiveTransfer: window.env?.REACT_APP_AI_VOICE_FLOW_QUALIFY_AND_LIVE_TRANSFER,
  flagAiVoiceFlowBook: window.env?.REACT_APP_AI_VOICE_FLOW_BOOK,
  flagAiVoiceFlowConfirm: window.env?.REACT_APP_AI_VOICE_FLOW_CONFIRM,
  flagOutreach: window.env?.REACT_APP_OUTREACH,
  flagIntegrationCrm: window.env?.REACT_APP_INTEGRATION_CRM,
  flagIntegrationWebhook: window.env?.REACT_APP_INTEGRATION_WEBHOOK,
  flagProspectFinder: window.env?.REACT_APP_PROSPECT_FINDER,
};
