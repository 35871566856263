import Switch from "@/common/components/switch";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { ChangeEvent, useState } from "react";

type LeadQualificationCardProps = {
  label: string;
  description: string;
  children?: JSX.Element;
  required?: boolean;
  expanded?: boolean;
  onSetRequired?: (value: boolean) => void;
  sx?: SxProps<Theme>;
};

export default function LeadQualificationCard({
  label,
  description,
  children,
  onSetRequired,
  required,
  expanded,
  sx,
}: LeadQualificationCardProps) {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(expanded || required);

  return (
    <Box
      onClick={() => setIsExpanded(true)}
      sx={{
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.border.default}`,
        borderRadius: "8px",
        padding: "16px 24px",
        width: isExpanded ? "100%" : 234,
        cursor: "pointer",
        transition: "order 0.3s ease",
        gap: 2,
        "&:hover": {
          borderColor: theme.palette.border.focus,
        },
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography fontSize={14} fontWeight={600} color={"#1A2135"}>
            {label}
          </Typography>
          <Typography fontSize={14} fontWeight={400} color={theme.palette.txt.placeholder}>
            {description}
          </Typography>
        </Box>
        {isExpanded && (
          <Box
            onClick={(event) => {
              event.stopPropagation();
              setIsExpanded(false);
            }}
          >
            <XMarkIcon width={24} />
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: children ? "space-between" : "flex-end", alignItems: "center" }}>
        {isExpanded && (
          <>
            {children}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>Nice to Have</Typography>
              <Switch
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onSetRequired?.(!!event?.target.checked);
                }}
                value={!!required}
              />
              <Typography>Must Have</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
