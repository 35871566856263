import { Box, Typography } from "@mui/material";
import React from "react";
import Integration from "@/common/components/integration";
import { Logo, LogoSize } from "@/common/components/logo";
import { TitleAndSubtitleBox } from "@/pages/settings/items";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  authorizeGmailUrl,
  authorizeGoogleCalendarUrl,
  authorizeGraphUrl,
  authorizeOutlookUrl,
} from "@/redux/reducers/oauth/authorize";
import {
  deauthorizeUserGmail,
  deauthorizeUserOutlook,
  deauthorizeGoogleCalendarUrl,
  deauthorizeGraphUrl,
} from "@/redux/reducers/oauth/deauthorize";

export default function Integrations() {
  const dispatch = useAppDispatch();
  const {
    isConnectedUserGmail,
    isConnectedUserGmailLoading,
    isConnectedUserOutlook,
    isConnectedUserOutlookLoading,
    isGoogleCalendarAuthorized,
    isGoogleCalendarAuthorizedLoading,
    isOutlookCalendarAuthorized,
    isOutlookCalendarAuthorizedLoading,
  } = useAppSelector(({ oauth }) => oauth);

  const handleConnectGmail = () => {
    dispatch(authorizeGmailUrl(false));
  };
  const handleDisconnectGmail = () => {
    dispatch(deauthorizeUserGmail());
  };

  const handleConnectOutlookMail = () => {
    dispatch(authorizeOutlookUrl(false));
  };
  const handleDisconnectOutlookMail = () => {
    dispatch(deauthorizeUserOutlook());
  };

  const handleConnectGoogleCalendar = () => {
    dispatch(authorizeGoogleCalendarUrl());
  };
  const handleDisconnectGoogleCalendar = () => {
    dispatch(deauthorizeGoogleCalendarUrl());
  };

  const handleConnectOutlookCalendar = () => {
    dispatch(authorizeGraphUrl());
  };
  const handleDisconnectOutlookCalendar = () => {
    dispatch(deauthorizeGraphUrl());
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <TitleAndSubtitleBox title="Integrations" subtitle="Manage your current integrated accounts and applications" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.2px",
          }}
        >
          Email
        </Typography>
        <Box
          sx={{
            display: "grid",
            gap: "16px",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <Integration
            logo={<Logo.Gmail size={LogoSize.Small} />}
            name="Gmail"
            connected={isConnectedUserGmail}
            isLoading={isConnectedUserGmailLoading}
            connect={handleConnectGmail}
            disconnect={handleDisconnectGmail}
          />
          <Integration
            logo={<Logo.Outlook size={LogoSize.Small} />}
            name="Outlook"
            connected={isConnectedUserOutlook}
            isLoading={isConnectedUserOutlookLoading}
            connect={handleConnectOutlookMail}
            disconnect={handleDisconnectOutlookMail}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.2px",
          }}
        >
          Calendar
        </Typography>
        <Box
          sx={{
            display: "grid",
            gap: "16px",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <Integration
            logo={<Logo.GoogleCalendar size={LogoSize.Small} />}
            name="Google Calendar"
            connected={isGoogleCalendarAuthorized || false}
            isLoading={isGoogleCalendarAuthorizedLoading || false}
            connect={handleConnectGoogleCalendar}
            disconnect={handleDisconnectGoogleCalendar}
          />
          <Integration
            logo={<Logo.Outlook size={LogoSize.Small} />}
            name="Outlook Calendar"
            connected={isOutlookCalendarAuthorized || false}
            isLoading={isOutlookCalendarAuthorizedLoading || false}
            connect={handleConnectOutlookCalendar}
            disconnect={handleDisconnectOutlookCalendar}
          />
        </Box>
      </Box>
    </Box>
  );
}
