import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TabContext from "@mui/lab/TabContext";
import TabList from "@/common/components/tab-list";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import AiLearningCompanyDetailsPage from "@/pages/ai-learning/company-details";
import AiLearningAiPersonaPage from "@/pages/ai-learning/ai-persona";
import { BuildingOfficeIcon, CurrencyDollarIcon, UsersIcon } from "@heroicons/react/24/outline";
import Button from "@/common/components/button";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import {
  AiCompanyDetailsCompanyHeadcountEnum,
  AiPersonaRequestResponsivenessEnum,
  AiPersonaResponsivenessEnum,
  AiSalesProcessObjection,
  AiSalesProcessObjectionObjectionTypeEnum,
} from "@/services/generated";
import { getCompanyDetails } from "@/redux/reducers/ai-learning/company-details/get";
import { persistCompanyDetails } from "@/redux/reducers/ai-learning/company-details/persist";
import { getAiPersona } from "@/redux/reducers/ai-learning/ai-persona/get";
import { toast } from "react-toastify";
import { getSalesProcess } from "@/redux/reducers/ai-learning/sales-process/get";
import AiLearningSalesProcessPage from "@/pages/ai-learning/sales-process";
import { persistAiPersona } from "@/redux/reducers/ai-learning/ai-persona/persist";
import { persistSalesProcess } from "@/redux/reducers/ai-learning/sales-process/persist";
import { ObjectSchema } from "yup";
import PageHeader from "@/common/components/page-header/PageHeader";

type FormCompanyDetails = {
  companyName: string;
  companyHeadcount: AiCompanyDetailsCompanyHeadcountEnum;
  companyValueProposition: string;
  linkToFaq: string | undefined;
  linkToKnowledgeBase: string | undefined;
};

const schemaCompanyDetails: ObjectSchema<FormCompanyDetails> = Yup.object({
  companyName: Yup.string().label("Company Name").required(),
  companyHeadcount: Yup.mixed<AiCompanyDetailsCompanyHeadcountEnum>().label("Company Headcount").required(),
  companyValueProposition: Yup.string().label("Company Value Proposition").required(),
  linkToFaq: Yup.string().label("Link to FAQ").url(),
  linkToKnowledgeBase: Yup.string().label("Link to Knowledge Base").url(),
});

const schemaAiPersona = Yup.object({
  aiAgentName: Yup.string().label("AI Agent Name").required(),
  engagementAndResponsiveness: Yup.mixed<AiPersonaRequestResponsivenessEnum>()
    .label("Engagement and Responsiveness")
    .required(),
  laidBackOrAssertive: Yup.number().label("Laid Back or Assertive").required(),
  friendlyOrProfessional: Yup.number().label("Friendly or Professional").required(),
  shortOrVerbose: Yup.number().label("Short or Verbose").required(),
  humorousOrSerious: Yup.number().label("Humorous or Serious").required(),
});

type FormObjection = {
  id?: string;
  objectionType: AiSalesProcessObjectionObjectionTypeEnum;
  objection?: string;
  objectionHandle: string;
};

const schemaObjection: ObjectSchema<FormObjection> = Yup.object({
  id: Yup.string(),
  objectionType: Yup.mixed<AiSalesProcessObjectionObjectionTypeEnum>().label("Objection Type").required(),
  objection: Yup.string().label("Objection"),
  objectionHandle: Yup.string().label("Objection Handling").required(),
});

type FormSalesProcess = {
  objections: FormObjection[];
};

const schemaSalesProcess: ObjectSchema<FormSalesProcess> = Yup.object({
  objections: Yup.array().of(schemaObjection).required(),
});

const schema = Yup.object({
  companyDetailsId: Yup.string(),
  companyDetails: schemaCompanyDetails,
  aiPersonaId: Yup.string(),
  aiPersona: schemaAiPersona.required(),
  salesProcess: schemaSalesProcess.required(),
});

export type AiLearningFormType = Yup.InferType<typeof schema>;

export default function AiLearningPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState("companyDetails");
  const [isRemoved, setIsRemoved] = useState(false);
  const companyDetails = useAppSelector(({ companyDetails }) => companyDetails);
  const aiPersona = useAppSelector(({ aiPersona }) => aiPersona);
  const salesProcess = useAppSelector(({ salesProcess }) => salesProcess);

  const methods = useForm<AiLearningFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      companyDetails: {
        companyName: "",
      },
      aiPersona: {
        laidBackOrAssertive: 0,
        friendlyOrProfessional: 0,
        shortOrVerbose: 0,
        humorousOrSerious: 0,
      },
      salesProcess: {
        objections: [],
      },
    },
  });
  const { control, reset, formState, handleSubmit } = methods;

  useEffect(() => {
    dispatch(getCompanyDetails());
    dispatch(getAiPersona());
    dispatch(getSalesProcess());
  }, [dispatch]);

  useEffect(() => {
    if (companyDetails) {
      reset({
        companyDetailsId: companyDetails.id || "",
        companyDetails: {
          companyName: companyDetails.companyName || "",
          companyHeadcount: companyDetails.companyHeadcount || undefined,
          companyValueProposition: companyDetails.companyValueProposition || "",
          linkToFaq: companyDetails.linkToFaq || "",
          linkToKnowledgeBase: companyDetails.linkToKnowledgeBase || "",
        },
        aiPersonaId: aiPersona.id || "",
        aiPersona: {
          aiAgentName: aiPersona.aiAgentName || "",
          engagementAndResponsiveness: aiPersona.engagementAndResponsiveness || AiPersonaResponsivenessEnum.Immediate,
          laidBackOrAssertive: aiPersona.laidBackOrAssertive,
          friendlyOrProfessional: aiPersona.friendlyOrProfessional,
          shortOrVerbose: aiPersona.shortOrVerbose,
          humorousOrSerious: aiPersona.humorousOrSerious,
        },
        salesProcess: {
          objections: salesProcess.objections.map((dto: AiSalesProcessObjection): FormObjection => {
            return {
              id: dto.id || "",
              objectionType: dto.objection_type || AiSalesProcessObjectionObjectionTypeEnum.Other,
              objection: dto.objection || "",
              objectionHandle: dto.objection_strategy || "",
            };
          }),
        },
      });
    }
  }, [reset, companyDetails, aiPersona, salesProcess]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
  };

  const handleRemove = (index: number) => {
    setIsRemoved(true);
  };

  const onSubmit: SubmitHandler<AiLearningFormType> = (data) => {
    if (!data) {
      return;
    }
    if (formState.dirtyFields.companyDetails) {
      dispatch(
        persistCompanyDetails({
          id: data.companyDetailsId,
          request: {
            company_name: data.companyDetails.companyName,
            company_headcount: data.companyDetails.companyHeadcount,
            company_value_proposition: data.companyDetails.companyValueProposition,
            link_to_faq: data.companyDetails.linkToFaq,
            link_to_knowledge_base: data.companyDetails.linkToKnowledgeBase,
          },
        }),
      );
    }
    if (formState.dirtyFields.aiPersona) {
      dispatch(
        persistAiPersona({
          id: data.aiPersonaId,
          request: {
            agent_name: data.aiPersona.aiAgentName,
            responsiveness: data.aiPersona.engagementAndResponsiveness,
            friendly_or_professional: data.aiPersona.friendlyOrProfessional,
            humorous_or_serious: data.aiPersona.humorousOrSerious,
            laid_back_or_assertive: data.aiPersona.laidBackOrAssertive,
            short_or_verbose: data.aiPersona.shortOrVerbose,
          },
        }),
      );
    }
    if (formState.dirtyFields.salesProcess || isRemoved) {
      dispatch(
        persistSalesProcess({
          objections: data.salesProcess.objections.map((form: FormObjection): AiSalesProcessObjection => {
            return {
              id: form.id,
              objection_type: form.objectionType,
              objection: form.objection,
              objection_strategy: form.objectionHandle,
            };
          }),
        }),
      );
      setIsRemoved(false);
    }

    toastSuccess();
  };

  const toastSuccess = () => {
    toast.success("Saved!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        backgroundColor: "#05CF74",
        color: "#1A2135",
        fontSize: "14px",
        height: "40px",
        padding: "0px 15px",
      },
    });
  };

  const onError: SubmitErrorHandler<AiLearningFormType> = (errors) => {
    console.log(errors);
    if (errors.companyDetails) {
      setTabValue("companyDetails");
      return;
    }
    if (errors.aiPersona) {
      setTabValue("aiPersona");
      return;
    }
    if (errors.salesProcess) {
      setTabValue("salesProcess");
      return;
    }
  };

  if (companyDetails.loading || aiPersona.loading) {
    return <Box>Loading...</Box>;
  }

  return (
    <FormProvider {...methods}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <PageHeader
          title="AI Learning"
          actions={
            <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
              Save
            </Button>
          }
        />
        <Box
          sx={(theme) => ({
            background: theme.palette.common.white,
            borderRadius: "8px",
          })}
        >
          <TabContext value={tabValue}>
            <TabList onChange={handleTabChange} indicatorColor="primary" variant="fullWidth">
              <Tab
                iconPosition="start"
                icon={<BuildingOfficeIcon width={24} />}
                label={t("aiLearning.companyDetails.title")}
                value={"companyDetails"}
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                iconPosition="start"
                icon={<UsersIcon width={24} />}
                label={t("aiLearning.aiPersona.title")}
                value={"aiPersona"}
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                iconPosition="start"
                icon={<CurrencyDollarIcon width={24} />}
                label={t("aiLearning.salesProcess.title")}
                value={"salesProcess"}
                sx={{ textTransform: "capitalize" }}
              />
            </TabList>

            <TabPanel value="companyDetails">
              <AiLearningCompanyDetailsPage />
            </TabPanel>
            <TabPanel value="aiPersona">
              <AiLearningAiPersonaPage />
            </TabPanel>
            <TabPanel value="salesProcess">
              <AiLearningSalesProcessPage control={control} onRemove={handleRemove} />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </FormProvider>
  );
}
