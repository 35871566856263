import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { CallDirectionSelectOptions } from "@/redux/reducers/ai-voice/helpers";
import { CALL_FLOW_TYPE_OPTIONS } from "../../constants";
import { AiVoiceCallFlowType } from "@/types/ai-voice";
import { AiAgentFiltersInput } from "..";

type AiAgentFiltersProps = {
  filters: AiAgentFiltersInput;
  setFilters: (filters: AiAgentFiltersInput) => void;
};

export default function AiAgentFilters({ filters, setFilters }: AiAgentFiltersProps) {
  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr auto auto",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Searchbar
          placeholder="Search for an agent"
          value={filters.agentName}
          onChange={(event) => {
            setFilters({ ...filters, agentName: event.target.value });
          }}
        />

        <Box sx={{ minWidth: 200, gridColumn: "3 / 4" }}>
          <Dropdown
            label="Direction"
            placeholder="Direction"
            value={filters.callDirection}
            options={[
              {
                label: "All",
                value: "",
              },
              ...CallDirectionSelectOptions,
            ]}
            onChange={(option) => {
              setFilters({ ...filters, callDirection: option.value });
            }}
          />
        </Box>

        <Box sx={{ minWidth: 200, gridColumn: "4 / 5" }}>
          <Dropdown
            label="Call Flow"
            placeholder="Call Flow"
            value={filters.callFlow}
            options={[
              {
                label: "All",
                value: "",
              },
              ...Object.keys(CALL_FLOW_TYPE_OPTIONS).map((callFlowType: AiVoiceCallFlowType) => ({
                value: callFlowType,
                label: CALL_FLOW_TYPE_OPTIONS[callFlowType].label,
              })),
            ]}
            onChange={(option) => {
              setFilters({ ...filters, callFlow: option.value });
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
