import { KnFirestoreImportJob } from "@/services/generated";

export enum DataImportObject {
  prospects = "prospects",
  company = "company",
  product = "product",
}

export interface IDataImport {
  name: string;
  object: DataImportObject;
  newRecords: number;
  updatedRecords: number;
  errorCount: number;
  importedOn: string;
}

export enum StatusChipVariants {
  prospects = "prospects",
  company = "company",
  product = "product",
}

export type DataImportJob = KnFirestoreImportJob & {
  id: string;
  // ...
};
