import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ProspectStep } from "./steps/ProspectsStep";
import { useFormContext, useWatch } from "react-hook-form";
import { DataImportFormFields, DataImportSchema } from "./types";

export default function ImportCard({ type, title, image, description }: ProspectStep) {
  const { importType } = useWatch<DataImportFormFields>();
  const { setValue } = useFormContext<DataImportSchema>();

  const selected = importType === type;

  const handleImportType = () => {
    setValue("importType", type);
  };

  return (
    <Box style={{ width: "204px" }} onClick={handleImportType}>
      <Box
        sx={{
          padding: "16px",
          borderStyle: "solid",
          borderRadius: "8px",
          textAlign: "center",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          borderColor: selected ? "#2C72E2" : "#E7EBED",
          borderWidth: selected ? "2px" : "1px",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <input type="radio" className="radio" checked={selected} onChange={() => handleImportType()} />
        </Box>
        {image}
        <Typography
          sx={{
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#6B747B",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}
