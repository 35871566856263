import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";
import { AxiosPromise, AxiosResponse } from "axios";
import { KnLookupResponseMessageDTO } from "@/services/generated";

export const lookupMessageThreads = createAsyncThunk(
  "inbox/lookupMessageThreads",
  async (prospectId: string): Promise<AxiosResponse<KnLookupResponseMessageDTO>> => {
    try {
      const messageThreads = await Services.Inbox.lookupOutreachThreads({
        pageSize: 10,
        page: 0,
        sortDirection: "desc",
        sortBy: "updatedAt",
        filters: [
          {
            property: "fromUserId",
            options: [
              {
                operator: "equal",
                values: [prospectId],
              },
            ],
          },
        ],
      });

      return messageThreads;
    } catch (error) {
      return error;
    }
  },
);

export const lookupMessages = createAsyncThunk("inbox/lookupMessages", async (prospectId: string) => {
  try {
    const messages = await Services.Inbox.lookupMessages({
      pageSize: 10,
      page: 0,
      sortDirection: "asc",
      sortBy: "createdAt",
      filters: [
        {
          property: "type",
          options: [
            {
              operator: "equal",
              values: ["sms", "email"],
            },
          ],
        },
        {
          property: "prospectId",
          options: [
            {
              operator: "equal",
              values: [prospectId],
            },
          ],
        },
      ],
    });

    return messages;
  } catch (error) {
    return error;
  }
});
