import { Stack } from "@mui/material";
import Button from "@/common/components/button";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import DrawerLayout from "@/layouts/DrawerLayout";
import { SyntheticEvent, useEffect, useMemo } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import AttachmentInput from "@/common/components/attachment/Attachment";
import Input from "@/common/components/input/Input";
import { CustomDatePicker } from "@/common/components/custom-date-picker/CustomDatePicker";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { TaskRequestTypeEnum } from "@/services/generated";
import { TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TimePickerInput } from "@/common/components/time-picker";
import { getProspectsByLastName, getProspects } from "@/redux/reducers/prospects/slices/listProspects";
import { getDealsList } from "@/redux/reducers/deals/slices/listDeals";
import { Deal } from "@/redux/reducers/dealFlow/slices/types";
import DrawerButtonsLayout from "@/layouts/DrawerButtonsLayout";
import { closeCreateTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import debounce from "@/common/functions";
import Avatar from "@/common/components/avatar";

type TaskFormProps = {
  onSubmit: any;
  drawerTitle: string;
  actionButtonLabel?: string;
  isLoading?: boolean;
};

export default function CreateTaskFormValues(props: TaskFormProps) {
  const { onSubmit, drawerTitle, isLoading, actionButtonLabel } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const methods = useFormContext();
  const { control, handleSubmit, watch, clearErrors } = methods;
  const users = useAppSelector(({ team }) => team.listUsers.users);
  const deals = useAppSelector(({ deals }) => deals.listDeals.deals);
  const { prospects, loading } = useAppSelector(({ prospects }) => prospects.listProspects);
  const contacts = prospects;

  useEffect(() => {
    if (users === undefined) {
      dispatch(getTeamUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (deals === undefined) {
      dispatch(getDealsList());
    }
  }, [dispatch, deals]);

  useEffect(() => {
    if (prospects === undefined) {
      dispatch(getProspects());
    }
  }, [dispatch, prospects]);

  const handleInputChangeContact = useMemo(
    () =>
      debounce((value) => {
        if (value.length < 1) return;
        dispatch(getProspectsByLastName({ lastName: value }));
      }, 500),
    [dispatch],
  );

  const contactOptions = useMemo(
    () =>
      (contacts || []).map((contact) => ({
        icon: () => <Avatar src={""} size="small" />,
        value: contact.id!,
        label: contact.firstName! + " " + contact.lastName!,
      })),
    [contacts],
  );

  return (
    <form
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();
        clearErrors();
        handleSubmit(onSubmit)();
      }}
    >
      <DrawerLayout title={drawerTitle} status={watch("status")} closeDrawer={() => dispatch(closeCreateTaskDrawer())}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }: { field: any; fieldState: any }) => (
                <>
                  <FormLabel aria-required label="Task Type" />
                  <Dropdown
                    label="Task Type"
                    options={TaskTypeSelectOptions}
                    {...field}
                    error={fieldState.error?.label?.message}
                  />
                </>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="assignee"
              control={control}
              render={({ field, fieldState }: { field: any; fieldState: any }) => (
                <>
                  <FormLabel aria-required label="Assignee" />
                  <Dropdown
                    label="Assignee"
                    options={(users || []).map((user) => ({
                      label: `${user.firstName!} ${user.lastName!}`,
                      value: user.user_id!,
                    }))}
                    error={fieldState.error?.label?.message}
                    {...field}
                  />
                </>
              )}
            />
          </FormControl>
        </Stack>
        {watch("type")?.value === TaskRequestTypeEnum.Other && (
          <FormControl sx={{ width: "50%" }}>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Please Specify" />
                  <Input type="text" placeholder="Description" {...field} error={fieldState?.error?.message} />
                </>
              )}
            />
          </FormControl>
        )}
        <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="prospect"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel aria-required label="Prospect" />
                  <Dropdown
                    label="Prospect"
                    isSearchable
                    onInputChange={handleInputChangeContact}
                    isPending={loading}
                    options={contactOptions}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="deal"
              control={control}
              render={({ field, fieldState }: { field: any; fieldState: any }) => (
                <>
                  <FormLabel label="Deal" />
                  <Dropdown
                    label="Deal"
                    isDisabled={!watch("prospect")}
                    options={(deals || []).map((deal: Deal) => ({
                      label: deal.name,
                      value: deal.id!,
                    }))}
                    {...field}
                    error={fieldState?.error?.value?.message}
                  />
                </>
              )}
            />
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="dueDate"
              control={control}
              render={({ field, fieldState }: { field: any; fieldState: any }) => (
                <>
                  <FormLabel aria-required label="Due Date" />
                  <CustomDatePicker {...field} error={fieldState?.error?.message} />
                </>
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <FormLabel aria-required label="Due Time" />
            <Controller
              name="dueTime"
              control={control}
              render={({ field, fieldState }: { field: any; fieldState: any }) => (
                <TimePickerInput {...field} dueDate={methods.watch("dueDate")} error={fieldState?.error?.message} />
              )}
            />
          </FormControl>
        </Stack>
        <FormControl sx={{ width: "50%" }}>
          <Controller
            name="attachments"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Attachments" />
                <AttachmentInput {...field} />
              </>
            )}
          />
        </FormControl>

        <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
          <FormControl style={{ width: "50%" }}>{/* missing attachment input component */}</FormControl>
        </Stack>
      </DrawerLayout>
      <DrawerButtonsLayout>
        <Button data-testid="closeTaskDrawer" variant="secondary" fullWidth onClick={closeCreateTaskDrawer}>
          {t("tasks.cancel")}
        </Button>

        <Button disabled={isLoading} loading={isLoading} type="submit" variant="primary" fullWidth>
          {actionButtonLabel}
        </Button>
      </DrawerButtonsLayout>
    </form>
  );
}
