import Box from "@mui/material/Box";

import { useForm } from "react-hook-form";
import { useAppDispatch } from "@/redux/store";

import { useEffect, useMemo } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { KnCompanyImportJobImportJobTypeEnum } from "@/services/generated";
import { TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { DataImportFiltersInput } from "@/pages/data-import";
import { debounce } from "lodash";

export const ImportObjectTypeOptions = [
  { label: "Contact", value: KnCompanyImportJobImportJobTypeEnum.Contact },
  { label: "Company", value: KnCompanyImportJobImportJobTypeEnum.Company },
  { label: "Product", value: KnCompanyImportJobImportJobTypeEnum.Product },
];

type DataImportFiltersProps = {
  filters: DataImportFiltersInput;
  setFilters: (
    filters: DataImportFiltersInput | ((prevFilters: DataImportFiltersInput) => DataImportFiltersInput),
  ) => void;
};

export default function DataImportFilters({ filters, setFilters }: DataImportFiltersProps) {
  const { control, watch } = useForm({});
  const watchedFields = watch();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.values(watchedFields).every((field) => field === undefined)) return;
    // handleFilterSubmit(watchedFields);
  }, [watchedFields]);

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr auto auto",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Box sx={{ gridColumn: "1 / 2" }}>
        <Searchbar placeholder="Search" />
      </Box>

      <Box sx={{ gridColumn: "3 / 4", minWidth: 200 }}>
        <Dropdown
          label="Object Type"
          options={ImportObjectTypeOptions}
          onChange={(option) => {
            setFilters({ ...filters, objectType: option.value });
          }}
        />
      </Box>

      <Box sx={{ gridColumn: "4 / 5", minWidth: 200 }}>
        <Dropdown
          label="Aug 01 - Sept 05"
          options={TaskTypeSelectOptions}
          onChange={() => {
            console.log("Aug 01 - Sept 05");
          }}
        />
      </Box>
    </Box>
  );
}
