import TableActionButton from "./ActionButton";
import StatusChip from "@/common/components/status-chip";
import TaskTableType from "./type";
import { Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import { TaskRequestStatusEnum } from "@/services/generated";
import dayjs from "dayjs";

const columnHelper = createColumnHelper<any>();

export const TaskColumns = [
  columnHelper.accessor("checkbox", {
    header: ({ table }) => (
      <div
        style={{
          display: "flex",
          gap: "17px",
          alignItems: "center",
          margin: "0 auto",

          maxWidth: "0px",
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </div>
    ),

    cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex",
            gap: "17px",
            alignItems: "center",
            margin: "0 auto",
            width: "0px",
            maxWidth: "0px",
          }}
        >
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  }),

  columnHelper.accessor("type", {
    header: () => <Typography variant="body3">Type</Typography>,

    cell: (info) => {
      const type = info.getValue();

      return (
        <div style={{ maxWidth: "350px", cursor: "pointer" }}>
          <TaskTableType
            action={type}
            prospectName={info.row.original.prospect?.firstName + " " + info.row.original.prospect?.lastName}
          />
        </div>
      );
    },
  }),

  columnHelper.accessor("assignee.name", {
    header: () => <Typography variant="body3">Assignee</Typography>,
    cell: (info) => {
      const assignee = info.getValue();
      return (
        <div style={{ cursor: "pointer" }}>
          <Typography variant="body2">{assignee || "Unassigned"}</Typography>
        </div>
      );
    },
  }),
  columnHelper.accessor("dueDate", {
    header: () => (
      <Typography style={{ cursor: "pointer" }} variant="body3">
        Due Date
      </Typography>
    ),
    cell: (info) => {
      const dueDate = info.getValue();
      return (
        <div style={{ cursor: "pointer" }}>
          <Typography variant="body2">{dayjs(dueDate).format("MMM DD, YYYY h:mm A")}</Typography>
        </div>
      );
    },
  }),
  columnHelper.accessor("status", {
    header: () => <Typography variant="body3">Status</Typography>,
    cell: (info) => {
      const status = info.getValue() || TaskRequestStatusEnum.Due;

      return (
        <div style={{ cursor: "pointer" }}>
          <StatusChip status={status as TaskRequestStatusEnum} type="task" />
        </div>
      );
    },
  }),

  columnHelper.display({
    id: "actions",
    cell: (props) => <TableActionButton row={props.row} />,
  }),
];
