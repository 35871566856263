import { Controller, useForm } from "react-hook-form";
import Input from "@/common/components/input";
import { Box, FormControl, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUpload from "@/common/components/file-upload";
import React, { useEffect } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import { setDraftTeam } from "@/redux/reducers/settings/team";
import { setDraftAiVoice } from "@/redux/reducers/settings/team-ai-voice";
import ToolTip from "@/common/components/tool-tip";
import { ReactComponent as InfoIcon } from "@/common/assets/svg/settings/information-circle.svg";
import _ from "lodash";

const schema = Yup.object({
  name: Yup.string(),
  location: Yup.string(),
  domain: Yup.string(),
  twilioAccountSid: Yup.string(),
  transferPhoneNumberDefault: Yup.string(),
  transferPhoneNumberSales: Yup.string(),
  transferPhoneNumberBilling: Yup.string(),
  transferPhoneNumberSupport: Yup.string(),
  logoImgUrl: Yup.string(),
  media: Yup.mixed(),
}).required();

export default function BasicInformation(props: any) {
  const theme = useTheme();
  const team = useAppSelector(({ settings }) => settings.team.team);
  const teamPhoneSMS = useAppSelector(({ settings }) => settings.team.teamPhoneSMS);
  const teamAiVoice = useAppSelector(({ settings }) => settings.teamAiVoice.active);
  const dispatch = useAppDispatch();
  const { control, reset, watch, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...team,
      twilioAccountSid: teamAiVoice.twilioAccountSid || "",
      transferPhoneNumberDefault: teamAiVoice.transferPhoneNumberDefault || "",
      transferPhoneNumberSales: teamAiVoice.transferPhoneNumberSales || "",
      transferPhoneNumberBilling: teamAiVoice.transferPhoneNumberBilling || "",
      transferPhoneNumberSupport: teamAiVoice.transferPhoneNumberSupport || "",
    },
  });

  const data = watch();

  useEffect(() => {
    if (formState.isDirty) {
      dispatch(setDraftTeam(data));
      dispatch(setDraftAiVoice(data));
    }
  }, [data, dispatch, formState]);

  const handleFileSelect = (file: File) => {
    setValue("media", file, { shouldDirty: true });
  };

  const transferInput = (
    field:
      | "transferPhoneNumberDefault"
      | "transferPhoneNumberSales"
      | "transferPhoneNumberSupport"
      | "transferPhoneNumberBilling",
    label: string,
  ) => {
    return (
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormLabel label={label} />
              <ToolTip title={`Tooltip about this transfer number`} placement={"right"}>
                <InfoIcon
                  height="18.803px"
                  width="20px"
                  style={{ marginBottom: "8px" }}
                  color={theme.palette.icon.informative}
                />
              </ToolTip>
            </Box>
            <Input
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error?.message}
              placeholder={label}
            />
          </FormControl>
        )}
      />
    );
  };

  return (
    <Box
      sx={{
        display: "grid",
        gap: "32px",
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Company Name" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="location"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Company Location" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="domain"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Domain" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <FormControl>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormLabel label="Company Phone SMS" />
          <ToolTip
            title={`Complete Brand and Campaign Registration to receive an A2P compliant phone number for sending SMS.`}
            placement={"right"}
          >
            <InfoIcon
              height="18.803px"
              width="20px"
              style={{ marginBottom: "8px" }}
              color={theme.palette.icon.informative}
            />
          </ToolTip>
        </Box>
        <Input
          value={teamPhoneSMS}
          placeholder="Complete Brand and Campaign Registration to receive a number"
          disabled
        />
      </FormControl>
      {transferInput("transferPhoneNumberDefault", "Phone Transfer")}
      {transferInput("transferPhoneNumberSales", "Phone Sales")}
      {transferInput("transferPhoneNumberBilling", "Phone Billing")}
      {transferInput("transferPhoneNumberSupport", "Phone Support")}
      <FileUpload label="Company Logo" onFileSelect={handleFileSelect} iconPlaceholderUrl={team?.logoImgUrl} />
    </Box>
  );
}
