/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";

export const authorizeCRMUrl = createAsyncThunk("oauth/authorizeCRMUrl", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await Services.Crm.hubspotAuthorizeUrl();
    window.open(response.data, "_self");
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const authorizeGmailUrl = createAsyncThunk(
  "oauth/authorizeGmailUrl",
  async (shared: boolean, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.OutreachOauth.googleAuthorizeUrl({
        redirectUri: `${window.location.origin}/account/oauth-callback/gmail${shared ? "-shared" : ""}`,
      });
      console.log(response);
      window.open(response.data, "_self");
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const authorizeOutlookUrl = createAsyncThunk(
  "oauth/authorizeOutlookUrl",
  async (shared: boolean, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.OutreachOauth.googleAuthorizeUrl({
        redirectUri: `${window.location.origin}/account/oauth-callback/outlook-mail${shared ? "-shared" : ""}`,
      });
      console.log(response);
      window.open(response.data, "_self");
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const authorizeGoogleCalendarUrl = createAsyncThunk(
  "oauth/authorizeGoogleCalendarUrl",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Calendar.calendarGoogleAuthorizeUrl();
      console.log(response);
      window.open(response.data, "_self");
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const authorizeGraphUrl = createAsyncThunk(
  "oauth/authorizeGraphUrl",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Calendar.graphAuthorizeUrl();
      window.open(response.data, "_self");
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
