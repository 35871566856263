import { FormControl, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import Checkbox from "@/common/components/checkbox/Checkbox";
import { useAppSelector } from "@/redux/store";
import { getSequences } from "@/redux/selectors/sequences";

export default function CancelSequenceForm() {
  const { control, watch } = useFormContext();
  const sequences = useAppSelector(getSequences);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography variant="body3">Cancel sequence</Typography>

      <FormControl>
        <Controller
          name="action.type"
          control={control}
          render={({ field }) => (
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Checkbox
                {...field}
                label="[sequence_name]"
                checked={field.value}
                style={{ margin: "0px 15px 0px 10px" }}
              />

              <Typography style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", color: "#6B747B" }}>
                By checking this option you agreed on canceling the selected sequence. <br />
                This action cannot be undone
              </Typography>
            </div>
          )}
        />
      </FormControl>
    </div>
  );
}
