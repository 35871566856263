import { Typography } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { Deal } from "@/redux/reducers/dealFlow/slices/types";
import { useAppSelector } from "@/redux/store";
import { DealFlowOptions } from "../../../UpdateTrigger";

// TODO Convert to Option value/label pairs
enum MeetingStateOptions {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export const MeetingStateChangesType = () => {
  const methods = useFormContext();
  const { control, setValue } = methods;
  const meetingStateOptions = Object.values(MeetingStateOptions);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        Meeting State
      </Typography>
      <Controller
        name="event.meetingState"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            {meetingStateOptions.map((subtype) => {
              return (
                <label key={subtype} htmlFor={subtype} style={{ cursor: "pointer", width: "100%", marginTop: "10px" }}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <input
                      type="radio"
                      className="radio"
                      id={subtype}
                      value={subtype}
                      checked={subtype === field.value}
                      onChange={field.onChange}
                    />
                    <Typography variant="body2">{subtype}</Typography>
                  </div>
                </label>
              );
            })}
          </>
        )}
      />
    </div>
  );
};
