import { Control, Controller } from "react-hook-form";
import Input from "@/common/components/input";
import { Box, FormControl, Theme, Typography } from "@mui/material";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { BrandRegistrationFormValues } from "@/redux/reducers/settings/brand-registration/mapper";

export const StateSelectOptions = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const CountrySelectOptions = [{ label: "United States", value: "US" }];
interface FormProps {
  control: Control<BrandRegistrationFormValues>;
}
export default function Location({ control }: FormProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        borderRadius: "6px",
        padding: "16px",
        backgroundColor: theme.palette.midnight[20],
        display: "grid",
        gap: "16px",
      })}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "28px",
          letterSpacing: "0.2px",
        }}
      >
        Location
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: "32px",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <Controller
          name="businessAddress"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Address" />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="businessAddress2"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Address 2" />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name="businessCity"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business City" />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={"businessState"}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business State/Region" aria-required />
              <Dropdown
                required
                options={StateSelectOptions}
                value={field.value.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name="businessPostalCode"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Postal Code" />
              <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={"businessCountry"}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label="Business Country" aria-required />
              <Dropdown
                label="Business Country"
                required
                options={CountrySelectOptions}
                value={field.value.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}
