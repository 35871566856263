import * as React from "react";
import { ReactComponent as Edit } from "@/common/assets/svg/edit.svg";
import { ReactComponent as Duplicated } from "@/common/assets/svg/duplicated.svg";
import { ReactComponent as Delete } from "@/common/assets/svg/delete_trigger.svg";
import { useNavigate } from "react-router";
import { useAppDispatch } from "@/redux/store";
import { createSequence, updateSequence } from "@/redux/reducers/sequences/slices/createSequence";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import routes from "@/common/constants/routes";
import MenuOptions from "@/common/components/menu-options/MenuOptions";

const options = [
  {
    label: "Edit Sequence",
    value: "edit",
    icon: <Edit />,
  },
  {
    label: "Duplicate Sequence",
    value: "duplicateSequence",
    icon: <Duplicated />,
  },
  {
    label: "Delete Sequence",
    value: "delete",
    icon: <Delete />,
  },
];

export default function TableActionButton(props: any) {
  const { params } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { deleteConfirm } = useConfirmModal();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    navigate(routes.sequence.update.with({ id: params.row.original.id }));
  };

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        if (option.value === "delete") {
          deleteConfirm()
            .then(() => {
              dispatch(
                updateSequence({
                  id: params.row.original.id,
                  name: params.row.original.name,
                  status: "DELETED",
                }),
              );
            })
            .catch(() => {
              return;
            });

          handleClose();
          return;
        }
        if (option.value === "duplicateSequence") {
          dispatch(createSequence(params.row.original));
          return;
        }

        handleNavigate();
        handleClose();
      }}
    />
  );
}
