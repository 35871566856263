import { Divider, Stack, Typography } from "@mui/material";
import Button from "@/common/components/button";
import { useNavigate } from "react-router-dom";
import Stepper from "@/common/components/stepper/Stepper";
import { useForm, FormProvider } from "react-hook-form";
import React from "react";
import { Badge } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ArrowRightOutline } from "heroicons-react";
import { ListByEventTypeEventEnum } from "@/services/generated";
import { createTrigger } from "@/redux/reducers/triggers/slices/createTrigger";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import routes from "@/common/constants/routes";
import { ObjectStep } from "@/pages/triggers/edit/steps/ObjectStep";
import OverviewStep from "@/pages/triggers/edit/steps/OverviewStep";
import ActionStep from "@/pages/triggers/edit/steps/action";
import LeadTriggers from "@/pages/triggers/edit/steps/event/lead";
import DealTriggers from "@/pages/triggers/edit/steps/event/deal";

export enum TriggersType {
  LEAD = "LEAD",
  DEAL = "DEAL",
}

export enum DealFlowOptions {
  ALL = "All",
  SPECIFIC_DEAL_FLOW = "Specific Deal Flow",
}

export type Item = {
  checked?: boolean;
  image: JSX.Element;
  title: string;
  description: string;
  value: TriggersType;
};

export default function NewTrigger() {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { createTriggerPending } = useAppSelector(({ triggers }) => triggers.listTriggers);

  type TriggerFormType = {
    name: string;
    type: TriggersType;
    event: {
      eventType: ListByEventTypeEventEnum | "";
      eventTriggerType: TriggersType | "";
      dealFlowId: string;
      dealFlowIdType: DealFlowOptions;
    };
    action: {
      actionType: string;
      receiver: string[];
      message: string;
    };
    deleted: boolean;
  };

  const methods = useForm<TriggerFormType>({
    defaultValues: {
      name: "",
      type: TriggersType.LEAD,
      event: {
        eventType: "",
        eventTriggerType: TriggersType.DEAL,
        dealFlowIdType: DealFlowOptions.ALL,
        dealFlowId: DealFlowOptions.ALL,
      },
      action: {
        actionType: "",
        receiver: [],
        message: "",
      },
      deleted: false,
    },
  });

  const { getValues } = methods;

  const [activeStep, setActiveStep] = React.useState<{
    idx: number;
    label: string;
  }>({ idx: 0, label: "Type" });

  const handleCancelImport = () => {
    navigate(routes.triggers.table.path);
  };

  const onSubmit = (data: TriggerFormType) => {
    try {
      dispatch(createTrigger(data)).then(() => {
        navigate(routes.triggers.table.path);
      });
    } catch (error) {
      return;
    }
  };

  const goToNextStep = () => {
    if (activeStep.idx === 0 && !getValues("name")) {
      // setError("name", "Name is required");
      return null;
    }

    if (activeStep.idx === 3) return;
    setActiveStep((prevActiveStep) => {
      return { ...prevActiveStep, idx: prevActiveStep.idx + 1 };
    });
  };

  const goToPreviousStep = () => {
    if (activeStep.idx === 0) return;
    setActiveStep((prevActiveStep) => {
      return { ...prevActiveStep, idx: prevActiveStep.idx - 1 };
    });
  };

  const { watch } = methods;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
          <Typography fontSize="24px" fontWeight="bold">
            Add New Trigger
          </Typography>
          <Badge
            badgeContent={400}
            max={999}
            color="primary"
            sx={{
              background: theme.palette.mint.p,
              color: theme.palette.txt.default,
            }}
          />
        </div>

        <Button data-testid="openTaskDrawer" variant="secondary" onClick={handleCancelImport}>
          Cancel
        </Button>
      </Stack>

      <FullLoadingOverlay loading={createTriggerPending} overlay />

      <Stack
        direction="column"
        style={{
          background: "#fff",
          padding: "20px",
        }}
      >
        <div>
          <Stepper
            steps={[
              { idx: 0, label: "Object" },
              { idx: 1, label: "Event" },
              { idx: 2, label: "Action" },
              { idx: 3, label: "Overview" },
            ]}
            activeStep={activeStep.idx}
          />
        </div>

        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Stack
              direction="column"
              style={{
                background: "#fff",
                padding: "42px 24px",
                maxWidth: "1500px",
                margin: "0 auto",
              }}
            >
              {activeStep.idx === 0 && <ObjectStep />}
              {activeStep.idx === 1 ? watch("type") === TriggersType.LEAD ? <LeadTriggers /> : <DealTriggers /> : null}
              {activeStep.idx === 2 && <ActionStep />}
              {activeStep.idx === 3 && <OverviewStep />}
            </Stack>

            <Divider style={{ margin: "20px 0px" }} />

            <div
              style={{
                display: "flex",
                justifyContent: activeStep.idx === 0 ? "flex-end" : "space-between",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              {activeStep.idx > 0 && (
                <Button variant="secondary" type="button" onClick={goToPreviousStep}>
                  Back
                </Button>
              )}

              {activeStep.idx < 3 && (
                <Button
                  variant="primary"
                  type="button"
                  onClick={goToNextStep}
                  disabled={
                    (activeStep.idx === 0 && !watch("name")) ||
                    (activeStep.idx === 1 && !watch("event.eventType")) ||
                    (activeStep.idx === 2 && !watch("action.actionType"))
                  }
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Next
                    <ArrowRightOutline size={16} style={{ marginLeft: "8px" }} />
                  </span>
                </Button>
              )}
              {activeStep.idx === 3 && (
                <Button variant="primary" type="submit">
                  Create Trigger
                  <ArrowRightOutline size={16} style={{ marginLeft: "8px" }} />
                </Button>
              )}
            </div>
          </FormProvider>
        </form>
      </Stack>
    </>
  );
}
