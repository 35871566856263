import { Box, Divider, Typography } from "@mui/material";
import Button from "@/common/components/button";
import IconButton from "@/common/components/icon-button";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Summary from "@/common/components/summary/Summary";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input/Input";
import FormControl from "@mui/material/FormControl";
import { useEffect, useMemo, useState } from "react";
import SequenceAction from "@/common/components/sequence-action/SequenceAction";
import { SequenceConnection } from "@/common/components/sequence-connection";
import { SequenceAction as SequenceActionInterface } from "@/types/sequence";
import CreateMessage from "../create-message/CreateMessage";
import { getCurrentSequence, getSequences } from "@/redux/selectors/sequences";
import {
  createSequence,
  createStepTemplate,
  removeSequenceStepTemplate,
  updateSequence,
  updateStepTemplateBehavior,
  updateStepTemplateInfo,
} from "@/redux/reducers/sequences/slices/createSequence";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { StepTemplateRequestCreateTypeEnum } from "@/services/generated";
import toast from "@/lib/toast";
import { TrashIcon } from "@heroicons/react/24/outline";
import StatusBadge from "../sequence-status";
import SequenceVersions from "../versions";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { setCurrentSequence } from "@/redux/reducers/sequences/slices/listSequences";
import Services from "@/services";
import { sortByParentIds } from "@/redux/reducers/sequences/slices/helpers";
import { ReactComponent as SaveIcon } from "@/common/assets/svg/save.svg";
import { ReactComponent as LogIcon } from "@/common/assets/svg/log.svg";
import routes from "@/common/constants/routes";
import CreateAiVoice from "@/pages/sequence-builder/create-ai-voice/CreateAiVoice";
import DraftTemplateEmail from "@/pages/sequence-builder/send-email/DraftTemplateEmail";

interface summaryProps {
  totalSteps: number;
  daysToComplete: number;
  automation: number;
}

const getSummarySequenceData = ({ totalSteps, daysToComplete, automation }: summaryProps) => [
  { title: "Total Steps", value: totalSteps.toString() },
  { title: "Days to Complete", value: daysToComplete.toString() },
  { title: "Automation", value: automation.toString() + "%" },
];

const getDaysToComplete = (steps: SequenceActionInterface[]) => {
  return steps.reduce((acc: number, item: SequenceActionInterface) => {
    return acc + (item.delay || 0);
  }, 0);
};

export default function CreateSequencePage() {
  const currentSequence = useAppSelector(getCurrentSequence);
  const sequences = useAppSelector(getSequences);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // TODO: Refactor and use `useFieldsArray` for actions and add form validations
  const formMethods = useForm({
    defaultValues: {
      name: "",
    },
  });
  const { control, setValue, getValues, watch } = formMethods;
  const [actions, setActions] = useState<SequenceActionInterface[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenAiVoice, setDrawerOpenAiVoice] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [versionsDrawerOpen, setVersionsDrawerOpen] = useState<boolean>(false);
  const { deleteConfirm } = useConfirmModal();
  const [isLoadingCurrentSequence, setIsLoadingCurrentSequence] = useState<boolean>(false);

  const params = useParams();

  const originalSequence = useMemo(() => {
    return sequences?.find((sequence) => sequence.id === currentSequence?.originalSequenceTemplateId);
  }, [currentSequence, sequences]);

  const canUpdateSequence = useMemo(
    () => !currentSequence || (currentSequence?.status && !["ACTIVE", "INACTIVE"].includes(currentSequence?.status)),
    [currentSequence],
  );

  const sequenceName = watch("name");
  const totalSteps = actions.length;
  const daysToComplete = useMemo(() => getDaysToComplete(actions), [actions]);

  const { loading: pendingCreateSequence } = useAppSelector(({ sequences }) => sequences.createSequence);

  const summarySequenceData = useMemo(() => {
    const automation =
      Math.ceil(
        (actions.filter((action) => !["CALL", "MANUAL", undefined].includes(action.actionType)).length / totalSteps) *
          100,
      ) || 0;
    return getSummarySequenceData({ totalSteps, daysToComplete, automation });
  }, [totalSteps, actions, daysToComplete]);

  const currentStep = useMemo(() => actions[currentStepIndex], [actions, currentStepIndex]);

  useEffect(() => {
    async function fetchCurrentSequence() {
      if (!params.id) {
        return;
      }
      setIsLoadingCurrentSequence(true);
      try {
        const response = await Services.SequenceTemplates.getSequenceTemplate(params.id);
        // copy agent id from response
        if (response.status === 200 && response.data.sequenceTemplate && response.data.steps) {
          dispatch(
            setCurrentSequence({
              sequence: {
                id: response.data.sequenceTemplate.id!,
                status: response.data.sequenceTemplate.status!,
                name: response.data.sequenceTemplate.name!,
                createdAt: response.data.sequenceTemplate.createdAt!,
                updatedAt: response.data.sequenceTemplate.updatedAt!,
                originalSequenceTemplateId: response.data.sequenceTemplate.originalSequenceTemplateId,
                steps: (response.data.steps || []).sort(sortByParentIds).map((step) => ({
                  id: step.id!,
                  delay: step.delayInMillis! / (1000 * 60 * 60 * 24),
                  actionType: step.type!,
                  content: step.messageTemplate,
                  aiAgentId: step.agentId,
                  parentIds: step.parentsIds!,
                })),
              },
            }),
          );
          navigate(routes.sequence.update.with({ id: response.data.sequenceTemplate.id }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingCurrentSequence(false);
      }
    }
    fetchCurrentSequence();
  }, [dispatch, navigate, params.id]);

  useEffect(() => {
    const steps = (currentSequence?.steps as SequenceActionInterface[]) || [];
    if (actions.length === 0 && currentSequence?.steps?.length) {
      setActions(steps);
    }
    setValue("name", currentSequence?.name || "");
  }, [currentSequence, actions.length, setValue]);

  const handleCancel = () => {
    navigate(routes.sequence.path);
  };

  const handleDelete = () => {
    if (!currentSequence) {
      return;
    }
    deleteConfirm({})
      .then(() => {
        dispatch(updateSequence({ ...currentSequence, status: "DELETED" }));
        toast.success(`Sequence deleted successfully!`);
        navigate(routes.sequence.path);
      })
      .catch(() => {
        return;
      });
  };

  const handleActivate = () => {
    if (!currentSequence) {
      return;
    }
    const newSequence = getValues();
    dispatch(updateSequence({ ...newSequence, id: currentSequence.id, status: "ACTIVE" }));
    toast.success(`Sequence activated successfully!`);
  };

  const handleSave = () => {
    const newSequence = getValues();
    const willCreateSequence = !currentSequence;
    if (willCreateSequence) {
      dispatch(createSequence(newSequence))
        .unwrap()
        .then((sequence) => {
          if (!sequence) {
            return;
          }
          navigate(routes.sequence.update.with({ id: sequence.id }));
          toast.success(`Sequence ${willCreateSequence ? "created" : "updated"}  successfully`);
        });
    } else {
      dispatch(updateSequence({ id: currentSequence.id, status: currentSequence.status, ...newSequence })).then(() => {
        navigate(routes.sequence.path);
        toast.success(`Sequence ${willCreateSequence ? "created" : "updated"}  successfully`);
      });
    }
  };

  const handleAddStep = (index: number) => {
    if (!actions[index].id) {
      return;
    }
    const newSequenceData = [...actions];
    const newStepParentId = actions[index].id;
    const newStep = newStepParentId ? { parentIds: [newStepParentId] } : {};
    newSequenceData.splice(index + 1, 0, newStep);
    setActions(newSequenceData);
  };

  const handleSelectActionType = (index: number, actionType: SequenceActionInterface["actionType"]) => {
    setCurrentStepIndex(index);
    if (actionType === "CALL") {
      const newSequenceData = [...actions];
      if (!newSequenceData[index]) {
        newSequenceData[index] = {};
      }
      newSequenceData[index].actionType = actionType;
      setActions(newSequenceData);
      setDrawerOpenAiVoice(true);
      return;
    }
    const newSequenceData = [...actions];
    if (!newSequenceData[index]) {
      newSequenceData[index] = {};
    }
    newSequenceData[index].actionType = actionType;
    setActions(newSequenceData);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleCloseDrawerAiVoice = () => {
    setDrawerOpenAiVoice(false);
  };

  const handleAddAction = async (data: SequenceActionInterface) => {
    setDrawerOpen(false);
    setDrawerOpenAiVoice(false);
    if (!currentSequence?.id) {
      return;
    }

    const newSequenceData: SequenceActionInterface[] = [...actions];
    const newActionData: SequenceActionInterface = { ...currentStep, ...data };
    if (currentStepIndex !== -1) {
      newSequenceData[currentStepIndex] = newActionData;
    } else {
      newSequenceData.push(newActionData);
    }

    if (currentStep.id) {
      // Duplicate
      newSequenceData[currentStepIndex] = newActionData;
      let response;
      if (newActionData.actionType === "CALL") {
        // TODO update call
        response = await dispatch(
          updateStepTemplateInfo({
            id: currentStep.id,
            type: newActionData.actionType,
            agentId: newActionData.aiAgentId,
          }),
        ).unwrap();
        // TODO update `actions`
      } else {
        response = await dispatch(
          updateStepTemplateInfo({
            id: currentStep.id,
            type: newActionData.actionType,
            messageTemplate: newActionData.content,
          }),
        ).unwrap();
      }
      const newStepId = response.id;
      if (!newStepId) {
        return;
      }
      newSequenceData[currentStepIndex].id = newStepId;
    } else {
      let response;
      if (newActionData.actionType === "CALL") {
        response = await dispatch(
          createStepTemplate({
            sequenceTemplateId: currentSequence.id,
            type: newActionData.actionType,
            agentId: newActionData.aiAgentId,
            parentsIds: newActionData.parentIds,
          }),
        ).unwrap();
      } else {
        response = await dispatch(
          createStepTemplate({
            sequenceTemplateId: currentSequence.id,
            type: newActionData.actionType,
            messageTemplate: newActionData.content,
            subjectTemplate: newActionData.subject,
            parentsIds: newActionData.parentIds,
          }),
        ).unwrap();
      }
      const newStepId = response.id;
      if (!newStepId) {
        return;
      }
      newSequenceData[currentStepIndex].id = newStepId;
      const nextStep = newSequenceData[currentStepIndex + 1];
      if (nextStep) {
        // TODO: Error here when adding mid sequence?
        newSequenceData[currentStepIndex + 1].parentIds = [newStepId];
        dispatch(
          updateStepTemplateBehavior({
            stepTemplateId: newSequenceData[currentStepIndex + 1].id,
            parentsIds: [newStepId],
          }),
        );
      }
    }

    setActions(newSequenceData);
  };

  const handleUpdateAction = (id: string) => {
    const index = actions.findIndex((action) => action.id === id);
    const step = actions[index];
    setCurrentStepIndex(index);
    if (step.actionType === "CALL") {
      setDrawerOpenAiVoice(true);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleMoveUp = (id: string) => {
    const index = actions.findIndex((item) => item.id === id);
    if (index > 0) {
      const newSequenceData = [...actions];
      const previousStep = newSequenceData[index - 1];
      const actualStep = newSequenceData[index];
      newSequenceData[index] = previousStep;
      newSequenceData[index - 1] = actualStep;
      dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: previousStep.id,
          parentsIds: actualStep.parentIds,
        }),
      );
      dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: actualStep.id,
          parentsIds: previousStep.parentIds,
        }),
      );
      setActions(newSequenceData);
    }
  };

  const handleMoveDown = (id: string) => {
    const index = actions.findIndex((item) => item.id === id);
    if (index < actions.length - 1) {
      const newSequenceData = [...actions];
      const previousStep = newSequenceData[index + 1];
      const actualStep = newSequenceData[index];
      newSequenceData[index] = previousStep;
      newSequenceData[index + 1] = actualStep;
      dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: previousStep.id,
          parentsIds: actualStep.parentIds,
        }),
      );
      dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: actualStep.id,
          parentsIds: previousStep.parentIds,
        }),
      );
      setActions(newSequenceData);
    }
  };

  const handleDuplicateAction = async (id: string) => {
    if (!currentSequence?.id) {
      return;
    }
    const index = actions.findIndex((item) => item.id === id);
    const newSequenceData = [...actions];
    const currentAction = newSequenceData[index];
    const response = await dispatch(
      createStepTemplate({
        sequenceTemplateId: currentSequence.id,
        type: currentAction.actionType as StepTemplateRequestCreateTypeEnum,
        delayInMillis: (currentAction.delay || 0) * (1000 * 60 * 60 * 24),
        messageTemplate: currentAction.content,
        parentsIds: [id],
      }),
    );
    const newStepId = (response.payload as any).id;
    const nextStep = newSequenceData[index + 1];
    if (nextStep) {
      newSequenceData[index + 1] = { ...nextStep, parentIds: [newStepId] };
      await dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: newSequenceData[index + 1].id,
          parentsIds: [newStepId],
        }),
      );
    }

    newSequenceData.splice(index + 1, 0, { ...currentAction, parentIds: [id], id: newStepId });
    setActions(newSequenceData);
  };

  const handleSetDelay = async (id: string, delay: number) => {
    if (!currentSequence?.id) {
      return;
    }
    const index = actions.findIndex((item) => item.id === id);
    const newSequenceData = [...actions];
    const currentAction = newSequenceData[index];
    await dispatch(
      updateStepTemplateInfo({
        id,
        type: currentAction.actionType as StepTemplateRequestCreateTypeEnum,
        delayInMillis: delay * (1000 * 60 * 60 * 24),
        messageTemplate: currentAction.content,
      }),
    );
    newSequenceData[index] = { ...currentAction, delay };
    setActions(newSequenceData);
    toast.success("Step delay updated successfully");
  };

  const handleRemoveAction = async (id: string) => {
    await dispatch(removeSequenceStepTemplate(id));
    const index = actions.findIndex((item) => item.id === id);
    const newSequenceData = [...actions];
    const nextStep = newSequenceData[index - 1];
    const parentIds = newSequenceData[index].parentIds;
    if (nextStep) {
      await dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: nextStep.id,
          parentsIds: parentIds,
        }),
      );
      newSequenceData[index - 1] = { ...nextStep, parentIds };
    }
    newSequenceData.splice(index, 1);
    setActions(newSequenceData);
    toast.success("Step Template Removed Successfuly.");
  };

  const handleRemoveDraftAction = (index: number) => {
    const newSequenceData = [...actions];
    newSequenceData.splice(index, 1);
    setActions(newSequenceData);
  };

  return (
    <FormProvider {...formMethods}>
      <FullLoadingOverlay loading={pendingCreateSequence || isLoadingCurrentSequence} overlay={true} />
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginY: "15px" }}>
        <Box
          sx={{
            display: "flex",
            gap: "25px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000000",
            }}
          >
            Sequence Builder
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          {currentSequence && (
            <>
              <IconButton variant="danger" onClick={handleDelete}>
                <TrashIcon width={24} />
              </IconButton>
              <IconButton
                variant="secondary"
                onClick={handleSave}
                disabled={!canUpdateSequence || (!!currentSequence && actions.length === 0) || !sequenceName}
              >
                <SaveIcon />
              </IconButton>
              <IconButton variant="secondary" onClick={() => setVersionsDrawerOpen(true)}>
                <LogIcon width={24} />
              </IconButton>
            </>
          )}

          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {currentSequence && (
            <Button variant="primary" disabled={!actions.length || !canUpdateSequence} onClick={handleActivate}>
              Activate
            </Button>
          )}
          {!currentSequence && (
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={!canUpdateSequence || (!!currentSequence && actions.length === 0) || !sequenceName}
            >
              {actions.length === 0 ? "Next" : "Create Sequence"}
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentSequence && (
          <Box
            sx={{
              display: "flex",
              marginTop: 3,
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                letterSpacing: "0.2px",
                color: "#1A2135",
                marginBottom: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Summary
              {currentSequence?.status && <StatusBadge status={currentSequence.status} />}
            </Typography>
            <Summary title="Summary" data={summarySequenceData} />
          </Box>
        )}

        <Box sx={{ width: "100%", maxWidth: "654px", marginTop: 3 }}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="name"
              control={control}
              disabled={!canUpdateSequence}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Sequence Title" />
                  <Input {...field} placeholder="Type here your sequence name" error={fieldState?.error?.message} />
                </>
              )}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            maxWidth: "774px",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {actions.map((action, index) => (
            <Box key={action.id}>
              <SequenceAction
                {...action}
                aiAgentId={action.aiAgentId}
                canClose
                canUpdateSequence={canUpdateSequence}
                onSelectActionType={(type) => handleSelectActionType(index, type)}
                onUpdatedAction={() => action.id && handleUpdateAction(action.id)}
                onMoveUp={handleMoveUp}
                onMoveDown={handleMoveDown}
                onDuplicateAction={handleDuplicateAction}
                onSetDelay={handleSetDelay}
                onRemoveAction={handleRemoveAction}
                onRemoveDraftAction={() => handleRemoveDraftAction(index)}
                index={index}
              />
              <Box sx={{ mt: 1 }}>
                <SequenceConnection
                  canUpdateSequence={canUpdateSequence}
                  isLastOne={index + 1 === actions.length}
                  onClick={() => handleAddStep(index)}
                />
              </Box>
            </Box>
          ))}

          {currentSequence && actions.length === 0 && (
            <SequenceAction index={0} onSelectActionType={(type) => handleSelectActionType(0, type)} />
          )}
        </Box>
      </Box>

      {originalSequence && (
        <SequenceVersions
          open={versionsDrawerOpen}
          sequence={originalSequence}
          onClose={() => setVersionsDrawerOpen(false)}
        />
      )}

      {drawerOpen && (
        <DraftTemplateEmail
          isNew={!currentStep?.id}
          messageType={currentStep?.actionType}
          isOpen={drawerOpen}
          closeDrawer={handleCloseDrawer}
          onAddAction={handleAddAction}
          content={currentStep?.content}
          subject={currentStep?.subject}
        />
      )}

      {drawerOpenAiVoice && (
        <CreateAiVoice
          isOpen={drawerOpenAiVoice}
          closeDrawer={handleCloseDrawerAiVoice}
          onAddAction={handleAddAction}
        />
      )}
    </FormProvider>
  );
}
