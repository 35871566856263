import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTeamAiVoiceSettings } from "./get";
import {
  AiVoiceTeamSettings,
  AiVoiceTransferRequest,
  AiVoiceTwilioRequest,
  KnTeamUserRole,
} from "@/services/generated";
import { AxiosResponse } from "axios";
import Services from "@/services";
import { toast } from "react-toastify";

interface AiVoiceSettings {
  twilioAccountSid: string | null;
  transferPhoneNumberDefault: string | null;
  transferPhoneNumberSales: string | null;
  transferPhoneNumberSupport: string | null;
  transferPhoneNumberBilling: string | null;
}

type State = {
  active: AiVoiceSettings;
  draft: AiVoiceSettings;
  loading: boolean;
  error: string | null;
  users: KnTeamUserRole[];
  joinError: string | null;
};

const initialState: State = {
  active: {
    twilioAccountSid: null,
    transferPhoneNumberDefault: null,
    transferPhoneNumberSales: null,
    transferPhoneNumberSupport: null,
    transferPhoneNumberBilling: null,
  },
  draft: {
    twilioAccountSid: null,
    transferPhoneNumberDefault: null,
    transferPhoneNumberSales: null,
    transferPhoneNumberSupport: null,
    transferPhoneNumberBilling: null,
  },
  loading: false,
  error: null,
  users: [],
  joinError: null,
};

export const updateTeamAiVoice = createAsyncThunk(
  "settings/updateTeamAiVoice",
  async (_, { getState, rejectWithValue, dispatch }): Promise<AxiosResponse<AiVoiceTeamSettings>> => {
    const s = getState() as any;
    const state = s.settings.teamAiVoice.draft;
    try {
      const request: AiVoiceTransferRequest = {
        transfer_phone_number_default: state.transferPhoneNumberDefault || undefined,
        transfer_phone_number_sales: state.transferPhoneNumberSales || undefined,
        transfer_phone_number_support: state.transferPhoneNumberSupport || undefined,
        transfer_phone_number_billing: state.transferPhoneNumberBilling || undefined,
      };
      return await Services.AiVoice.upsertAiVoiceTeamSettings(request);
    } catch (error: any) {
      return error;
    }
  },
);

export const updateTeamAiVoiceTwilio = createAsyncThunk(
  "settings/updateTeamAiVoice",
  async (request: AiVoiceTwilioRequest, { rejectWithValue, dispatch }): Promise<AxiosResponse<AiVoiceTeamSettings>> => {
    try {
      const response = await Services.AiVoice.upsertAiVoiceTwilio(request);
      toast.success("Twilio authorized successfully");
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const deauthorizeAiVoiceTwilio = createAsyncThunk(
  "oauth/deauthorizeAiVoiceTwilio",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.AiVoice.deauthorizeAiVoiceTwilio();
      toast.success("Twilio deauthorized successfully");
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const teamAiVoiceSlice = createSlice({
  name: "teamAiVoice",
  initialState,
  reducers: {
    set: (state, action) => {
      state.active = {
        ...action.payload,
      };
    },
    setDraftAiVoice: (state, { payload }) => {
      state.draft = {
        ...payload,
      };
    },
    setDraftTwilio: (state, { payload }) => {
      state.draft = {
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamAiVoiceSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamAiVoiceSettings.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.active = {
            twilioAccountSid: payload.data.twilio_account_sid || null,
            transferPhoneNumberDefault: payload.data.transfer_phone_number_default || null,
            transferPhoneNumberSales: payload.data.transfer_phone_number_sales || null,
            transferPhoneNumberSupport: payload.data.transfer_phone_number_support || null,
            transferPhoneNumberBilling: payload.data.transfer_phone_number_billing || null,
          };
        }
        state.loading = false;
      })
      .addCase(updateTeamAiVoice.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.active = {
            twilioAccountSid: payload.data.twilio_account_sid || null,
            transferPhoneNumberDefault: payload.data.transfer_phone_number_default || null,
            transferPhoneNumberSales: payload.data.transfer_phone_number_sales || null,
            transferPhoneNumberSupport: payload.data.transfer_phone_number_support || null,
            transferPhoneNumberBilling: payload.data.transfer_phone_number_billing || null,
          };
        }
        state.loading = false;
      })
      .addCase(deauthorizeAiVoiceTwilio.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.active = {
            ...state.active,
            twilioAccountSid: payload.data.twilio_account_sid || null,
            // twilioAuthToken: null,
          };
        }
        state.loading = false;
      });
  },
});

export const { set, setDraftAiVoice } = teamAiVoiceSlice.actions;

export default teamAiVoiceSlice.reducer;
