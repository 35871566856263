import { Typography } from "@mui/material";
import TableActionButton from "./ActionButton";
import { Company } from "@/redux/reducers/prospects/companies/slices/types";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import { createColumnHelper } from "@tanstack/react-table";
import TableDate from "@/common/components/table-date";

const columnHelper = createColumnHelper<Company>();

export const CompaniesTableColumns = [
  columnHelper.accessor("name", {
    header: ({ table }: any) => (
      <div style={{ display: "flex", gap: "18px", alignItems: "center", marginLeft: "16px" }}>
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
        <Typography variant="body3" fontWeight="600">
          Company Name
        </Typography>
      </div>
    ),

    cell: (info) => {
      const row = info.row;
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <Typography variant="body2">{info.getValue()}</Typography>
        </div>
      );
    },
  }),

  columnHelper.accessor("industry", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        industry
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor((row) => row.revenue, {
    id: "revenue",
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Revenue
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor("headcount", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Head Count
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Created Date
      </Typography>
    ),
    cell: (info) => <TableDate nanos={info.getValue()} />,
  }),

  columnHelper.display({
    id: "actions",
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Actions
      </Typography>
    ),
    cell: (props) => <TableActionButton params={props} />,
  }),
];
