import { Typography } from "@mui/material";

import { ReactComponent as Qrcode } from "@/common/assets/svg/onboarding/qr-code.svg";
import { ReactComponent as UserGroup } from "@/common/assets/svg/onboarding/user-group.svg";

import Box from "@mui/material/Box";

import { useAppSelector } from "@/redux/store";
import { useEffect, useState } from "react";
import OnboardCard from "../../OnboardCard";
import { CompanyForm } from "./CompanyForm";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { setDraftTeam } from "@/redux/reducers/settings/team";

type TeamTypeOption = { title: string; subtitle: string; img: JSX.Element; value: "invite" | "create" };

const TEAM_OPTIONS: TeamTypeOption[] = [
  {
    title: "Invite Code",
    img: <Qrcode />,
    value: "invite",
    subtitle: "Input the invite code for your team sent via email",
  },

  {
    title: "Create a company",
    img: <UserGroup />,
    value: "create",
    subtitle: "Please add company call-summary for company set up",
  },
];

export default function CompanySettings() {
  const [companyFormType, setCompanyFormType] = useState<TeamTypeOption["value"]>();
  const team = useAppSelector(({ settings }) => settings.team.team);
  const loading = useAppSelector(({ settings }) => settings.team.loading);

  useEffect(() => {
    if (team) {
      setCompanyFormType("create");
    }
  }, [team]);

  return (
    <>
      <FullLoadingOverlay loading={loading} overlay />
      {!team && (
        <>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "700",
              lineHeight: "20px",
            }}
          >
            Choose a Team Option
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "16px",
            }}
          >
            {TEAM_OPTIONS.map((option) => (
              <OnboardCard
                {...option}
                key={option.title}
                onChange={() => {
                  setDraftTeam(null);
                  setCompanyFormType(option.value);
                }}
                selected={option.value === companyFormType}
              />
            ))}
          </Box>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          paddingTop: "16px",
        }}
      >
        {companyFormType && <CompanyForm type={companyFormType} />}
      </Box>
    </>
  );
}
