import { components } from "react-select";
import { Box } from "@mui/material";

export const MultiValue = (props: any) => {
  return (
    <components.MultiValue
      {...props}
      components={{
        ...props.components,
        Remove: (removeProps) => {
          return null;
        },
        Container: (containerProps) => {
          return <Box {...containerProps} />;
        },
      }}
    >
      <Box
        sx={{
          background: "white",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.2px",

          "&:after": {
            content: '","',
          },
        }}
      >
        {props.data.label}
      </Box>
    </components.MultiValue>
  );
};
