import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import StatusChip from "@/common/components/status-chip";
import Accordion from "../accordion/Accordion";
import Avatar from "../avatar/Avatar";
import { ReactComponent as EnvelopeIcon } from "../../assets/svg/envelope.svg";
import { ReactComponent as Fire } from "../../assets/svg/deals/fire.svg";
import { Phone } from "heroicons-react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { dateFormatMs } from "@/common/functions/dateFormat";
import { ConsentLevel } from "@/common/constants/consent-level";
import { useEffect } from "react";
import FormLabel from "../form-label/FormLabel";
import Dropdown from "../dropdown/Dropdown";

import { getSequencesByContact } from "@/redux/reducers/sequences/slices/listSequences";
import { SequenceResponseStatusEnum } from "@/services/generated";
import { getDealsListByContactId } from "@/redux/reducers/deals/slices/listDeals";
import { setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { getProspects } from "@/redux/reducers/prospects/slices/listProspects";
interface ProspectInfoTypeProps extends Prospect {
  isInbox?: boolean;
}

export const TitleAndSubtitle = ({
  title,
  subtitle,
  alternativeColor = null,
  customWidth = null,
}: {
  title?: string;
  subtitle?: string | JSX.Element;
  alternativeColor?: string | null;
  customWidth?: string | null;
}) => {
  return (
    <Box
      sx={{
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "6px",
        display: "inline-flex",
        width: customWidth || "100%",
      }}
    >
      <Typography variant="body2" fontSize="12px" fontWeight="600" textTransform="uppercase">
        {title || "N/A"}
      </Typography>
      {alternativeColor ? (
        <Typography variant="body2" color={alternativeColor}>
          {subtitle || "N/A"}
        </Typography>
      ) : (
        <Typography variant="body2">{subtitle || "N/A"}</Typography>
      )}
    </Box>
  );
};

const ProspectInfo = ({ isInbox = false }: ProspectInfoTypeProps) => {
  const theme = useTheme();
  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);
  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const dispatch = useAppDispatch();
  const prospectSequence = useAppSelector(({ sequences }) => sequences.listSequences.prospectSequence);
  const prospectDeals = useAppSelector(({ deals }) => deals.listDeals.dealsByContactId);

  const { firstName, lastName, email, phoneNumber, companyName, jobTitle, createdAt, consentLevel, consentSources } =
    currentProspect || {};

  const dateCreated = dateFormatMs(createdAt ? createdAt / 1000 : 0);

  useEffect(() => {
    if (!prospects) dispatch(getProspects());
  }, [currentProspect, dispatch, prospects]);

  useEffect(() => {
    if (currentProspect?.id) {
      dispatch(getDealsListByContactId(currentProspect?.id));
      dispatch(getSequencesByContact(currentProspect?.id));
    }
  }, [currentProspect, dispatch]);

  if (!prospects) return null;

  return (
    <Box
      sx={({ palette }) => ({
        background: isInbox ? palette.midnight[20] : null,
        width: "100%",
        overflowY: isInbox ? "scroll" : null,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "inline-flex",
        gap: "24px",
        padding: isInbox ? "0px 10px" : "0px",
        height: isInbox ? "100%" : "90vh",
      })}
    >
      <Box sx={{ width: "100%" }}>
        <FormLabel label="Prospect" />
        <Dropdown
          label="Select a prospect"
          options={prospects?.map((prospect) => ({
            label: `${prospect.firstName} ${prospect.lastName}`,
            value: prospect.id,
          }))}
          value={currentProspect?.id}
          onChange={({ value }) => {
            const newProspect = prospects.find((prospect) => prospect.id === value) as Prospect;
            dispatch(setCurrentProspect(newProspect));
          }}
        />
      </Box>
      <Accordion summaryTitle="Prospect" defaultExpanded id="Prospect-info">
        <Box
          style={{
            alignSelf: "stretch",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "16px",
            display: "flex",
            paddingLeft: "4px",
          }}
        >
          <Box style={{ justifyContent: "flex-start", alignItems: "center", gap: "8px", display: "flex" }}>
            <Avatar label={`${firstName} ${lastName}`} size="small" />
            <Typography variant="body2">
              {firstName} {lastName}
            </Typography>
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
            }}
          >
            <Box
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "inline-flex",
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  gap: "2px",
                  fontSize: "20px",
                  backgroundColor: theme.palette.border.default,
                  padding: "4px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px;",
                })}
              >
                <EnvelopeIcon
                  style={{
                    height: "16px",
                    width: "16px",
                    color: theme.palette.midnight.p,
                  }}
                />
              </Box>
              <Typography variant="body2">{email}</Typography>
            </Box>
            <Box
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "inline-flex",
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  gap: "2px",
                  fontSize: "20px",
                  backgroundColor: theme.palette.border.default,
                  padding: "4px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                })}
              >
                <Phone
                  style={{
                    height: "16px",
                    width: "16px",
                    color: theme.palette.midnight.p,
                  }}
                />
              </Box>
              <Typography variant="body2">{phoneNumber}</Typography>
            </Box>
          </Box>
          <TitleAndSubtitle title="company" subtitle={companyName || ""} />
          <TitleAndSubtitle title="Job title" subtitle={jobTitle || ""} />

          <Box
            style={{
              alignSelf: "stretch",
              justifyContent: "space-between",
              alignItems: "flex-start",
              display: "inline-flex",
            }}
          >
            <TitleAndSubtitle title="DATE CREATED" subtitle={dateCreated} customWidth={"70%"} />
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "2px",
                display: "inline-flex",
              }}
            >
              <Typography width="100%" variant="body2" fontWeight="600" fontSize="12px" textTransform="uppercase">
                Opt In level
              </Typography>

              <StatusChip status={(consentLevel as ConsentLevel) || ConsentLevel.None} type="consent" />
            </Box>
          </Box>
          <TitleAndSubtitle title="Timezone" subtitle="(UTC-08:00) Pacific Time (US & Canada)" />
        </Box>
      </Accordion>

      {prospectDeals?.length > 0 ? (
        <Accordion summaryTitle="Deal" defaultExpanded id="deal">
          <Box
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
              paddingLeft: "4px",
            }}
          >
            <TitleAndSubtitle
              title="Deal name"
              subtitle="ACME Campaign"
              alternativeColor={theme.palette.link.default}
            />
            <Box
              style={{
                alignSelf: "stretch",
                justifyContent: "space-evenly",
                alignItems: "flex-start",
                gap: 12,
                display: "flex",
              }}
            >
              <TitleAndSubtitle title="Value" subtitle="$607.00" />
              <Box
                sx={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "6px",
                  display: "inline-flex",
                  width: "100%",
                }}
              >
                <Typography variant="body2" fontSize="12px" fontWeight="600" textTransform="uppercase">
                  Heat Score
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Fire />
                  <Typography variant="body2">5.0</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                alignSelf: "stretch",
                justifyContent: "space-evenly",
                alignItems: "flex-start",
                gap: 12,
                display: "flex",
              }}
            >
              <TitleAndSubtitle title="PIPELINE" subtitle="Inbound" />
              <TitleAndSubtitle title="Stage" subtitle="Won" />
            </Box>
            <TitleAndSubtitle title="product" subtitle="SalesAi Yearly" />
          </Box>
        </Accordion>
      ) : null}

      {prospectSequence?.length > 0 ? (
        <Accordion summaryTitle="Sequence" defaultExpanded id="Sequence-info">
          <Box
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
              paddingLeft: "4px",
            }}
          >
            <Box
              style={{
                alignSelf: "stretch",
                justifyContent: "space-between",
                alignItems: "flex-start",
                display: "inline-flex",
              }}
            >
              <TitleAndSubtitle
                title={prospectSequence[0].name}
                subtitle={prospectSequence[0].name}
                customWidth={isInbox ? null : "80%"}
              />
              <StatusChip status={SequenceResponseStatusEnum.InProgress} type="sequence" />
            </Box>
            <TitleAndSubtitle title="LAST ACTIVITY" subtitle={prospectSequence[0].triggeredAt} />
            <TitleAndSubtitle title="PROGRESS OF SEQUENCE" subtitle="XX out of YY" />
          </Box>
        </Accordion>
      ) : null}
    </Box>
  );
};

export default ProspectInfo;
