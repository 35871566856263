import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProspectFinder } from "./types";
import { KnBqContact, KnLookupRequestSortDirectionEnum } from "@/services/generated";
import toast from "@/lib/toast";

type State = {
  contacts?: ProspectFinder[];
  currentContact?: ProspectFinder | null;
  viewDrawerIsOpen?: boolean;
  editDrawerIsOpen?: boolean;
  totalCount?: number;
  loading: boolean;
  error: string | null;
  companies?: any[];
  isCompaniesLoading?: boolean;
};

const initialState: State = { loading: false, error: null, isCompaniesLoading: false };

type Filters = {
  corporateCity?: string;
  corporateState?: string;
  companyDomain?: string;
  jobTitle?: string;
  industry?: string;
  firstName?: string;
  lastName?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: KnLookupRequestSortDirectionEnum;
};
export const getProspectFinderCompanies = createAsyncThunk(
  "prospects/finder/getProspectFinderCompanies",
  async (filters: { searchTerm: string }) => {
    try {
      const response = await Services.ProspectFinder.keywordSearchKnCompaniesByName({ searchTerm: filters.searchTerm });
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const getProspectFinderContacts = createAsyncThunk(
  "prospects/finder/getProspectFinderContacts",
  async (filters: Filters) => {
    try {
      const filtersToRequest = Object.keys(filters)
        .filter((field: string) => !["page", "pageSize", "sortBy", "sortDirection"].includes(field))
        .map((field: string) => {
          const values = `${filters[field as keyof Filters]}`;
          if (values === "") return null;

          return {
            property: field,
            options: [
              {
                operator: "equal",
                values: [`${filters[field as keyof Filters]}`],
              },
            ],
          };
        })
        .filter((filter) => filter !== null);
      if (!filtersToRequest.length) return;
      const response = await Services.ProspectFinder.lookupKnProspects({
        filters: filtersToRequest as any,
        page: filters?.page ?? 0,
        pageSize: filters?.pageSize ?? 20,
        sortBy: filters?.sortBy ?? "id",
        sortDirection: filters?.sortDirection ?? KnLookupRequestSortDirectionEnum.Desc,
      });
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const createContactFromProspectFinder = createAsyncThunk(
  "prospects/finder/createContactFromProspectFinder",
  async (prospect: KnBqContact) => {
    try {
      const response = await Services.ProspectFinder.createContactFromProspectFinder({
        contact: prospect,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
);

const listProspectFinderSlice = createSlice({
  name: "listProspectFinder",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProspectFinderContacts.fulfilled, (state, { payload }) => {
      if (payload?.data?.results) {
        state.contacts = payload.data.results as ProspectFinder[];
        state.loading = false;
        state.totalCount = payload.data.results.length + 100; // TODO: hardcoded value until we have a proper count
      }
    });

    builder.addCase(createContactFromProspectFinder.fulfilled, (state) => {
      toast.success("Contact created successfully");
    });

    builder.addCase(createContactFromProspectFinder.rejected, (state) => {
      toast.error("Error creating contact");
    });

    builder.addCase(getProspectFinderContacts.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getProspectFinderContacts.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getProspectFinderCompanies.pending, (state) => {
      state.isCompaniesLoading = true;
    });
    builder.addCase(getProspectFinderCompanies.rejected, (state) => {
      state.isCompaniesLoading = false;
    });
    builder.addCase(getProspectFinderCompanies.fulfilled, (state, { payload }) => {
      if (payload?.data?.results) {
        state.companies = payload.data.results;
        state.isCompaniesLoading = false;
      }
    });
  },
  reducers: {
    openViewDrawer: (state) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewDrawer: (state) => {
      state.viewDrawerIsOpen = false;
    },
    openEditDrawer: (state) => {
      state.editDrawerIsOpen = true;
    },
    closeEditDrawer: (state) => {
      state.editDrawerIsOpen = false;
    },
  },
});

export const { openViewDrawer, closeViewDrawer, openEditDrawer, closeEditDrawer } = listProspectFinderSlice.actions;

export default listProspectFinderSlice.reducer;
