import { Controller, useForm } from "react-hook-form";
import Input from "@/common/components/input";
import { Box, FormControl } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUpload from "@/common/components/file-upload";
import React, { useEffect } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import { KnUser } from "@/services/generated";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setDraft, setPrevImg } from "@/redux/reducers/settings/user";

const schema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
  phone: Yup.string(),
  imgUrl: Yup.string(),
  media: Yup.mixed(),
  prevImg: Yup.mixed(),
}).required();

export default function BasicInformation(props: any) {
  const user = useAppSelector(({ settings }) => settings.user.user);
  const dispatch = useAppDispatch();
  const { control, reset, formState, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: user || {},
  });
  const data = watch();

  useEffect(() => {
    reset(user || {});
  }, [user, reset]);

  useEffect(() => {
    if (formState.isDirty) {
      dispatch(setDraft({ ...data, prevImg: data.media }));
      dispatch(setPrevImg(data.media));
    }
  }, [data, dispatch, formState]);

  const handleFileSelect = (file: File) => {
    setValue("media", file, { shouldDirty: true });
  };
  return (
    <Box
      sx={{
        display: "grid",
        gap: "32px",
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="First Name" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Last Name" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Email" aria-required />
            <Input {...field} error={fieldState.error?.message} disabled />
          </FormControl>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Phone" />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <FileUpload
        prevFile={user?.prevImg}
        label="Profile Picture"
        onFileSelect={handleFileSelect}
        iconPlaceholderUrl={user?.imgUrl}
      />
    </Box>
  );
}
