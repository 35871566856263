import Drawer from "@mui/material/Drawer";
import { closeViewDrawer } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch } from "@/redux/store";
import DrawerLayout from "@/layouts/DrawerLayout";
import { Box, Stack } from "@mui/material";
import ProspectInfo from "@/common/components/prospect-info/ProspectInfo";
import { useAppSelector } from "@/redux/store";

export default function ViewProspectDrawer() {
  const dispatch = useAppDispatch();
  const { viewDrawerIsOpen, currentProspect } = useAppSelector(({ prospects }) => prospects.listProspects);

  const toggleDrawer = () => dispatch(closeViewDrawer());

  const ViewProspect = () => (
    <DrawerLayout customWidth="420px" title={"Prospect Information"} testid="viewProspect" closeDrawer={toggleDrawer}>
      <Stack sx={{ padding: "0px 12px 16px 12px", borderRadius: "8px" }}>
        <Box>
          <ProspectInfo {...currentProspect} />
        </Box>
      </Stack>
    </DrawerLayout>
  );

  return (
    <Drawer open={viewDrawerIsOpen} onClose={() => dispatch(closeViewDrawer())} anchor="right">
      <ViewProspect />
    </Drawer>
  );
}
