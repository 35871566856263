import DatePicker from "react-multi-date-picker";
import { useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

type CalendarRangeProps = {
  isRange?: boolean;
  value: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  error?: string;
};

export function CustomDatePicker(props: CalendarRangeProps) {
  const { isRange, value, error, onChange = () => null, disabled } = props;
  const theme = useTheme();

  return (
    <>
      {isRange ? (
        <DatePicker
          onChange={onChange}
          value={dayjs(value).format("YYYY-MM-DD")}
          range
          dateSeparator=" to "
          placeholder="Select Date Range"
          arrow={false}
          disabled={disabled}
        />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MuiDatePicker
            value={value ? dayjs(value) : null}
            onChange={onChange}
            disabled={disabled}
            format="YYYY-MM-DD"
            slotProps={{
              field: { clearable: true },
              day: {
                sx: {
                  "&.MuiPickersDay-root:hover": {
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.midnight.p,
                  },
                  "&.MuiPickersDay-root:focus": {
                    backgroundColor: theme.palette.midnight.p,
                    color: theme.palette.common.white,
                  },
                  "&.MuiPickersDay-root.Mui-selected": {
                    backgroundColor: theme.palette.midnight.p,
                    color: theme.palette.common.white,
                  },
                  borderRadius: "8px",
                },
              },
            }}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "12px 16px",
                color: "#6B747B",

                "&:hover": {
                  background: "#F8F8F8",
                },

                "&:focus": {
                  background: "#F8F8F8",
                },
              },

              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",

                "& fieldset": {
                  borderColor: error ? theme.palette.commonColors.danger : "#E7EBED",
                },
                "&:hover fieldset": {
                  color: "#6B747B",
                  transition: "all 0.3s",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "#E7EBED",
                },

                // disabled?
                "&.Mui-disabled": {
                  backgroundColor: "hsl(0, 0%, 95%)",
                  "& .MuiOutlinedInput-input": {
                    color: "#6B747B",
                  },
                  "& fieldset": {
                    borderColor: "#E7EBED",
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      )}

      {error && (
        <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
          This field is required
        </Typography>
      )}
    </>
  );
}
