import Drawer from "@mui/material/Drawer";
import DrawerLayout from "@/layouts/DrawerLayout";
import { Sequence } from "@/types/sequence";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import StatusBadge from "../sequence-status";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { createNewVersionSequenceTemplate } from "@/redux/reducers/sequences/slices/createSequence";
import { useMemo } from "react";
import toast from "@/lib/toast";
import routes from "@/common/constants/routes";

interface SequenceVersionsProps {
  sequence: Sequence;
  open?: boolean;
  onClose?: () => void;
}
export default function SequenceVersions({ open = false, onClose, sequence }: SequenceVersionsProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sequences = useAppSelector((selector) => selector.sequences.listSequences.sequences);

  const lasterSequenceTemplateId = useMemo(() => {
    return sequences?.find(({ id }) => sequence.id === id)?.latestSequenceTemplateId;
  }, [sequence, sequences]);

  const versionsStatuses = useMemo(() => {
    return sequence.versions?.map((version) => version.status) || [];
  }, [sequence]);

  const handleClose = () => {
    onClose?.();
  };

  const handleNewDraft = async () => {
    try {
      if (!lasterSequenceTemplateId) {
        return;
      }
      const newVersion = await dispatch(createNewVersionSequenceTemplate(lasterSequenceTemplateId)).unwrap();
      if (newVersion.id) {
        handleNavigate(newVersion.id);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleNavigate = (id: string) => {
    navigate(routes.sequence.update.with({ id: id }));
    handleClose();
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <DrawerLayout title="Versions" closeDrawer={handleClose}>
        <Box sx={{ gap: 3, height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <TableContainer sx={{ mt: 2, border: "1px solid #E7EBED", borderRadius: "8px", overflow: "hidden" }}>
            <Table>
              <TableHead sx={{ backgroundColor: theme.palette.tableRow.heading }}>
                <TableRow sx={{ textTransform: "uppercase" }}>
                  <TableCell sx={{ fontWeight: 600 }}>Version</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sequence.versions
                  ? sequence.versions.map((version) => (
                      <TableRow
                        key={version.id}
                        onClick={() => handleNavigate(version.id)}
                        sx={{
                          cursor: "pointer",
                          "&:nth-of-type(odd)": {
                            backgroundColor: "#FFFFFF",
                          },
                          "&:nth-of-type(even)": {
                            backgroundColor: "#FBFBFB",
                          },
                        }}
                      >
                        <TableCell>
                          {version.name} - Version {version.version}
                        </TableCell>
                        <TableCell>{version.status && <StatusBadge status={version.status} />}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", gap: 1, alignSelf: "end" }}>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            {!versionsStatuses.includes("DRAFT") && !versionsStatuses.includes("INACTIVE") && (
              <Button variant="primary" onClick={handleNewDraft}>
                Create New Draft
              </Button>
            )}
          </Box>
        </Box>
      </DrawerLayout>
    </Drawer>
  );
}
