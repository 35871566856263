import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CompanyFilters from "./filters";
import CreateNewCompany from "./create/CreateCompanyDrawer";
import Button from "@/common/components/button/Button";
import EditCompanyDrawer from "./edit";
import { useNavigate } from "react-router";
import { Badge } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getCompanies } from "@/redux/reducers/prospects/companies/slices/listCompanies";
import { usePagination } from "@/common/components/pagination/usePagination";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { CompaniesTableColumns } from "./table/Columns";
import { ReactComponent as NoCompaniesSVG } from "@/common/assets/svg/Companies/zero-companies-graph.svg";
import TsTable from "@/common/components/ts-table";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import routes from "@/common/constants/routes";
import PageHeader from "@/common/components/page-header/PageHeader";

type filterType = {
  searchString?: string;
};

export default function ProspectsCompanies() {
  const [filters, setFilters] = useState<filterType>({});
  const { companies, totalCount, loading } = useAppSelector(({ companies }) => companies.listCompanies);
  const { pendingCreateCompany } = useAppSelector(({ companies }) => companies.listCompanies);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { onPaginationChange, pagination } = usePagination();

  const handleFilters = (newFilters: filterType) => setFilters((filters) => ({ ...filters, ...newFilters }));

  useEffect(() => {
    dispatch(
      getCompanies({
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,
      }),
    );
  }, [dispatch, pagination.pageIndex, pagination.pageSize]);

  const handleNavigate = () => {
    navigate(routes.dataImport.import.path);
  };

  const table = useReactTable<any>({
    data: companies || [],
    columns: CompaniesTableColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: totalCount,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  const Company = () => (
    <Box
      sx={{
        borderRadius: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <TsTable
        table={table}
        handleCellClick={() => null}
        notFoundProps={{
          title: "No Companies Created yet!",
          description: "Begin creating an agent by clicking the Create New Company button above.",
          Icon: NoCompaniesSVG,
        }}
      />
    </Box>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <FullLoadingOverlay loading={pendingCreateCompany} overlay={true} />

          <PageHeader
            title="Companies"
            badge={<Badge badgeContent={126} max={999} color="primary" />}
            actions={
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Button variant="secondary" onClick={handleNavigate}>
                  Import Company
                </Button>
                <CreateNewCompany />
              </Box>
            }
            filters={<CompanyFilters onSetFilters={handleFilters} />}
          />

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <EditCompanyDrawer />
            <Company />
          </Box>
        </>
      )}
    </div>
  );
}
