import { Stack } from "@mui/material";
import Button from "@/common/components/button";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import DrawerLayout from "@/layouts/DrawerLayout";
import { SyntheticEvent, useEffect, useMemo } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import AttachmentInput from "@/common/components/attachment/Attachment";
import Input from "@/common/components/input/Input";
import { CustomDatePicker } from "@/common/components/custom-date-picker/CustomDatePicker";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { getProspectsByLastName, getProspects } from "@/redux/reducers/prospects/slices/listProspects";
import { TaskRequestTypeEnum } from "@/services/generated";
import { TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { ErrorMessage } from "@hookform/error-message";
import { TimePickerInput } from "@/common/components/time-picker";
import { getDealsList } from "@/redux/reducers/deals/slices/listDeals";
import { Deal } from "@/redux/reducers/dealFlow/slices/types";
import { Typography } from "@mui/material";
import { TrashOutline } from "heroicons-react";
import { deleteTask } from "@/redux/reducers/tasks/slices/listTask";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import DrawerButtonsLayout from "@/layouts/DrawerButtonsLayout";
import debounce from "@/common/functions";
import Avatar from "@/common/components/avatar";

type TaskFormProps = {
  toggleDrawer: () => void;
  onSubmit: any;
  drawerTitle: string;
  actionButtonLabel?: string;
  isLoading?: boolean;
};

const errorMessageStyle = {
  color: "red",
  fontSize: "12px",
  margin: "0px",
  marginLeft: "8px",
};

export default function TaskForm(props: TaskFormProps) {
  const { toggleDrawer, onSubmit, drawerTitle, isLoading, actionButtonLabel } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const users = useAppSelector(({ team }) => team.listUsers.users);
  const deals = useAppSelector(({ deals }) => deals.listDeals.deals);
  const currentTask = useAppSelector(({ tasks }) => tasks.listTask.currentTask);
  const { deleteConfirm } = useConfirmModal();
  const methods = useFormContext();
  const { prospects, loading } = useAppSelector(({ prospects }) => prospects.listProspects);
  const contacts = prospects;

  const { control, handleSubmit, watch, clearErrors } = methods;

  useEffect(() => {
    if (users === undefined) {
      dispatch(getTeamUsers());
    }
  }, [users, dispatch]);

  useEffect(() => {
    if (prospects === undefined) {
      dispatch(getProspects());
    }
  }, [prospects, dispatch]);

  useEffect(() => {
    if (deals === undefined) {
      dispatch(getDealsList());
    }
  }, [dispatch, deals]);

  const handleInputChangeContact = useMemo(
    () =>
      debounce((value) => {
        if (value.length < 1) return;
        dispatch(getProspectsByLastName({ lastName: value }));
      }, 500),
    [dispatch],
  );

  const contactOptions = useMemo(
    () =>
      (contacts || []).map((contact) => ({
        icon: () => <Avatar src={""} size="small" />,
        value: contact.id!,
        label: contact.firstName! + " " + contact.lastName!,
      })),
    [contacts],
  );

  const handleDeleteTask = (taskId: string | undefined) => {
    if (!taskId) return;
    dispatch(deleteTask(taskId));
  };

  return (
    <form
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();
        clearErrors();
        handleSubmit(onSubmit)();
      }}
    >
      <DrawerLayout title={drawerTitle} closeDrawer={toggleDrawer} status={watch("status")}>
        {!currentTask ? (
          <LoadingSpinner loading={true} />
        ) : (
          <>
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <FormLabel aria-required label="Task Type" />
                        <Dropdown
                          label="Task Type"
                          options={TaskTypeSelectOptions}
                          defaultValue={field.value}
                          onChange={(value) => {
                            field.onChange(value.value);
                          }}
                          value={field.value}
                        />
                        <ErrorMessage errors={methods.formState.errors} name="type" as="p" style={errorMessageStyle} />
                      </>
                    );
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="assignee"
                  control={control}
                  render={({ field }) => {
                    const options = users?.map((user) => ({
                      label: `${user.firstName!} ${user.lastName!}`,
                      value: user.user_id!,
                    }));

                    return (
                      <>
                        <FormLabel aria-required label="Assignee" />
                        <Dropdown
                          label="Assignee"
                          options={options || []}
                          {...field}
                          onChange={(value) => {
                            field.onChange(value.value);
                          }}
                        />
                        <ErrorMessage
                          errors={methods.formState.errors}
                          name="assignee"
                          as="p"
                          style={errorMessageStyle}
                        />
                      </>
                    );
                  }}
                />
              </FormControl>
            </Stack>
            {watch("type") === TaskRequestTypeEnum.Other && (
              <FormControl sx={{ width: "50%" }}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel aria-required label="Please Specify" />
                      <Input type="text" placeholder="Description" {...field} />
                      <ErrorMessage
                        errors={methods.formState.errors}
                        name="description"
                        as="p"
                        style={errorMessageStyle}
                      />
                    </>
                  )}
                />
              </FormControl>
            )}
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="prospect"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel aria-required label="Prospect" />
                      <Dropdown
                        label="Prospect"
                        isSearchable
                        onInputChange={handleInputChangeContact}
                        isPending={loading}
                        options={contactOptions}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </>
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="deal"
                  control={control}
                  render={({ field }) => {
                    const options = deals?.map((deal: Deal) => ({
                      label: deal.name!,
                      value: deal.id!,
                    }));
                    return (
                      <>
                        <FormLabel aria-required label="Deal" />
                        <Dropdown
                          label="Deal"
                          options={options || []}
                          {...field}
                          onChange={(value) => {
                            field.onChange(value.value);
                          }}
                        />
                        <ErrorMessage errors={methods.formState.errors} name="deal" as="p" style={errorMessageStyle} />
                      </>
                    );
                  }}
                />
              </FormControl>
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="dueDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <FormLabel aria-required label="Due Date" />
                        <CustomDatePicker {...field} />
                        <ErrorMessage
                          errors={methods.formState.errors}
                          name="dueDate"
                          as="p"
                          style={errorMessageStyle}
                        />
                      </>
                    );
                  }}
                />
              </FormControl>

              <FormControl sx={{ width: "100%" }}>
                <FormLabel aria-required label="Due Time" />
                <Controller
                  name="dueTime"
                  control={control}
                  render={({ field }) => {
                    return <TimePickerInput {...field} dueDate={methods.watch("dueDate")} />;
                  }}
                />
                <ErrorMessage errors={methods.formState.errors} name="dueTime" as="p" style={errorMessageStyle} />
              </FormControl>
            </Stack>
            <FormControl sx={{ width: "50%" }}>
              <Controller
                name="attachments"
                control={control}
                render={({ field }) => (
                  <>
                    <FormLabel label="Attachments" />
                    <AttachmentInput {...field} />
                    <ErrorMessage
                      errors={methods.formState.errors}
                      name="attachments"
                      as="p"
                      style={errorMessageStyle}
                    />
                  </>
                )}
              />
            </FormControl>
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
              <FormControl style={{ width: "50%" }}>{/* missing attachment input component */}</FormControl>
            </Stack>
          </>
        )}
      </DrawerLayout>
      <DrawerButtonsLayout>
        <Button
          type="button"
          onClick={() =>
            deleteConfirm({
              textDiv: (
                <>
                  <Typography variant="body1">Are you sure you want to delete this task?</Typography>
                  <Typography variant="body1">This action cannot be undone.</Typography>
                </>
              ),
            })
              .then(() => {
                handleDeleteTask(currentTask?.id);
              })
              .catch(() => {
                console.log("cancelled");
              })
          }
          variant="secondary"
          sx={{
            color: "red",
            borderColor: "red",

            "&:focus": {
              color: "red",
              borderColor: "red",
            },
            "&:hover": {
              color: "red",
              borderColor: "red",
              backgroundColor: "rgba(255, 0, 0, 0.1)",
            },
          }}
        >
          <TrashOutline size={18} />
        </Button>

        <Button data-testid="closeTaskDrawer" variant="secondary" fullWidth onClick={toggleDrawer}>
          {t("tasks.cancel")}
        </Button>

        <Button disabled={isLoading} loading={isLoading} type="submit" variant="primary" fullWidth>
          {actionButtonLabel}
        </Button>
      </DrawerButtonsLayout>
    </form>
  );
}
