import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Product, ProductStatus } from "@/types/product-library";
import { KnProduct, ProductRequestCreate } from "@/services/generated";
import { ProductRequestUpdate } from "@/services/generated";

type State = {
  products?: Product[];
  loading: boolean;
  error: string | null;

  currentProduct: Product | null;

  pendingCreateProduct: boolean;
  pendingUpdateProduct: boolean;

  createProductDrawerOpen: boolean;
};

const initialState: State = {
  products: undefined,
  loading: false,
  error: null,

  currentProduct: null,
  pendingCreateProduct: false,
  pendingUpdateProduct: false,
  createProductDrawerOpen: false,
};

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (filters: { name?: string; status?: ProductStatus } = { name: "", status: "ACTIVE" }, { rejectWithValue }) => {
    try {
      const status = [filters.status || "ACTIVE"];
      const response = await Services.Products.getProducts(filters.name || "", status);

      const products: Product[] = response.data.map((product: KnProduct) => ({
        id: product.id!,
        months: product.months!,
        description: product.description!,
        name: product.name!,
        price: product.price!,
        status: product.status!,
        feature: product.feature || [],
        valueProp: product.valueProp || [],
      }));
      return products;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateProduct = createAsyncThunk(
  "productLibrary/updateProduct",
  async (data: ProductRequestUpdate, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Products.updateProduct(data);
      if (response.status === 200) {
        dispatch(updateProductById({ id: data.id!, data }));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createProduct = createAsyncThunk(
  "productLibrary/createProduct",
  async (newProduct: ProductRequestCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Products.createProduct(newProduct);

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const listProductsSlice = createSlice({
  name: "listProducts",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.products = payload;
      state.loading = false;
    });

    builder.addCase(createProduct.pending, (state, { payload }) => {
      state.pendingCreateProduct = true;
      state.error = null;
    });

    builder.addCase(createProduct.fulfilled, (state, { payload }) => {
      state.pendingCreateProduct = false;
      state.error = null;
      state.createProductDrawerOpen = false;
    });

    builder.addCase(createProduct.rejected, (state, { payload }) => {
      state.pendingCreateProduct = false;
    });

    builder.addCase(updateProduct.pending, (state, { payload }) => {
      state.pendingUpdateProduct = true;
      state.error = null;
    });

    builder.addCase(updateProduct.fulfilled, (state, { payload }) => {
      state.pendingUpdateProduct = false;
      state.error = null;
    });

    builder.addCase(updateProduct.rejected, (state, { payload }) => {
      state.pendingUpdateProduct = false;
    });
  },
  reducers: {
    openCreateProductDrawer: (state, action) => {
      state.createProductDrawerOpen = true;
      state.currentProduct = action.payload.product;
    },
    closeCreateProductDrawer: (state) => {
      state.createProductDrawerOpen = false;
      state.currentProduct = null;
    },

    updateProductStatus: (state, action) => {
      const { name, status } = action.payload;
      state.products = state.products?.map((product) => {
        if (product.name === name) {
          return { ...product, status };
        } else {
          return product;
        }
      });
    },
    updateProductById: (state, action) => {
      const { id, data } = action.payload;
      state.products = state.products?.map((product) => {
        if (product.id === id) {
          return data;
        } else {
          return product;
        }
      });
    },
    deleteProductById: (state, action) => {
      const { id } = action.payload;
      state.products = state.products?.filter((product) => product.id !== id);
    },
  },
});

export const {
  openCreateProductDrawer,
  closeCreateProductDrawer,
  updateProductStatus,
  updateProductById,
  deleteProductById,
} = listProductsSlice.actions;

export default listProductsSlice.reducer;
