import { Box, Typography } from "@mui/material";
import Integration, { IntegrationProps } from "@/common/components/integration";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { authorizeCRMUrl, authorizeGmailUrl, authorizeOutlookUrl } from "@/redux/reducers/oauth/authorize";
import { deauthorizeCRMUrl, deauthorizeEmailConsent } from "@/redux/reducers/oauth/deauthorize";
import { ReactComponent as TwilioImage } from "@/common/assets/svg/settings/twilio.svg";
import { ReactComponent as HubspotImage } from "@/common/assets/svg/settings/hubspot.svg";
import { ReactComponent as WebhookImage } from "@/common/assets/svg/settings/webhooks-seeklogo 1.svg";
import { useTheme } from "@mui/material";
import React, { useMemo } from "react";
import TwilioDialog from "@/pages/settings/company/crm/twilio/TwilioDialog";
import { deauthorizeAiVoiceTwilio } from "@/redux/reducers/settings/team-ai-voice";
import WebhookDialog from "@/pages/settings/company/crm/webhook/WebhookDialog";
import { Logo, LogoSize } from "@/common/components/logo";
import { ConsentDTOEmailProviderEnum } from "@/services/generated";
import config from "@/common/constants/config";

const logoStyle = {
  display: "flex",
  width: "32px",
  height: "32px",
  padding: "2px",
  justifyContent: "center",
  alignItems: "center",
  gap: "6.4px",
  borderRadius: "3.048px",
};

export function CrmBlock() {
  const [twilioDialogIsOpen, setTwilioDialogIsOpen] = React.useState(false);
  const [webDialogIsOpen, setWebDialogIsOpen] = React.useState(false);
  const { isCRMHubspotAuthorized } = useAppSelector(({ oauth }) => oauth);
  const { teamConsentList } = useAppSelector(({ oauth }) => oauth);
  const teamAiVoice = useAppSelector(({ settings }) => settings.teamAiVoice.active);
  const isTwilioAuthorized = !!teamAiVoice.twilioAccountSid;
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const emailExisting: IntegrationProps[] = useMemo(() => {
    return teamConsentList.map(({ consent, consentLoading }) => {
      if (consent.emailProvider === ConsentDTOEmailProviderEnum.Gmail) {
        return {
          name: "Gmail",
          desc: consent.emailAddress,
          connected: true,
          disconnect: () => dispatch(deauthorizeEmailConsent(consent.id!)),
          logo: <Logo.Gmail size={LogoSize.Small} />,
          isLoading: consentLoading,
        };
      }
      return {
        name: "Outlook",
        desc: consent.emailAddress,
        connected: true,
        disconnect: () => dispatch(deauthorizeEmailConsent(consent.id!)),
        logo: <Logo.Gmail size={LogoSize.Small} />,
        isLoading: consentLoading,
      };
    });
  }, [dispatch, teamConsentList]);

  const communications: IntegrationProps[] = useMemo(
    () => [
      ...emailExisting,
      {
        name: "Gmail",
        connected: false,
        connect: () => dispatch(authorizeGmailUrl(true)),
        logo: <Logo.Gmail size={LogoSize.Small} />,
      },
      {
        name: "Outlook",
        connected: false,
        connect: () => dispatch(authorizeOutlookUrl(true)),
        logo: <Logo.Outlook size={LogoSize.Small} />,
      },
      {
        name: "Twilio",
        connected: isTwilioAuthorized || false,
        connect: () => setTwilioDialogIsOpen(true),
        disconnect: () => dispatch(deauthorizeAiVoiceTwilio()),
        logo: <TwilioImage style={logoStyle} />,
      },
    ],
    [dispatch, isTwilioAuthorized, emailExisting],
  );

  const crmBlocks = useMemo(() => {
    if (config.flagIntegrationCrm) {
      return [
        {
          title: "Hubspot",
          connected: isCRMHubspotAuthorized || false,
          connect: () => dispatch(authorizeCRMUrl()),
          disconnect: () => dispatch(deauthorizeCRMUrl()),
          logo: (
            <HubspotImage
              style={{
                ...logoStyle,
                padding: "6.4px",
                backgroundColor: "#FF7A59",
              }}
            />
          ),
        },
        // {
        //   title: "Salesforce",
        //   connected: false,
        //   logo: (
        //     <SalesForceImage
        //       style={{
        //         ...logoStyle,
        //         backgroundColor: theme.palette.midnight[20],
        //       }}
        //     />
        //   ),
        // },
      ];
    }
    return [];
  }, [isCRMHubspotAuthorized, theme.palette.midnight, dispatch]);

  const inboundLeads = useMemo(() => {
    if (config.flagIntegrationCrm) {
      return [
        // {
        //   title: "Google Ads",
        //   connected: false,
        //   logo: (
        //     <GoogleAdsImage
        //       style={{
        //         ...logoStyle,
        //         backgroundColor: theme.palette.midnight[20],
        //       }}
        //     />
        //   ),
        // },
        // {
        //   title: "Facebook Lead Ads",
        //   connected: false,
        //   logo: (
        //     <FacebookImage
        //       style={{
        //         ...logoStyle,
        //         backgroundColor: theme.palette.midnight[20],
        //       }}
        //     />
        //   ),
        // },
        {
          title: "Webhook",
          connected: false,
          connect: () => setWebDialogIsOpen(true),
          disconnect: () => () => null,
          logo: <WebhookImage style={logoStyle} />,
        },
      ];
    }
    return [];
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.2px",
          }}
        >
          Communications
        </Typography>
        <Box
          sx={{
            display: "grid",
            gap: "16px",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          {communications.map((block, index) => (
            <Integration
              key={index}
              logo={block.logo}
              name={block.name}
              desc={block.desc}
              connected={block.connected}
              connect={block.connect}
              disconnect={block.disconnect}
              isLoading={block.isLoading}
            />
          ))}
        </Box>
      </Box>

      {crmBlocks.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.2px",
            }}
          >
            CRM
          </Typography>
          <Box
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            {crmBlocks.map((block, index) => (
              <Integration
                key={index}
                logo={block.logo}
                name={block.title}
                connected={block.connected}
                connect={block.connect}
                disconnect={block.disconnect}
              />
            ))}
          </Box>
        </Box>
      )}
      {inboundLeads.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.2px",
            }}
          >
            Inbound Leads
          </Typography>
          <Box
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            {inboundLeads.map((block, index) => (
              <Integration
                key={index}
                logo={block.logo}
                name={block.title}
                connected={block.connected}
                connect={block.connect}
                disconnect={block.disconnect}
              />
            ))}
          </Box>
        </Box>
      )}
      <TwilioDialog isOpen={twilioDialogIsOpen} handleClose={() => setTwilioDialogIsOpen(false)} />
      <WebhookDialog isOpen={webDialogIsOpen} handleClose={() => setWebDialogIsOpen(false)} />
    </Box>
  );
}

export default CrmBlock;
