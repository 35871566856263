import { Table } from "@tanstack/react-table";
import { Sequence } from "@/types/sequence";
import { useAppSelector } from "@/redux/store";
import TsTable from "@/common/components/ts-table";
import SequenceVersions from "../versions";
import { useMemo, useState } from "react";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";

type SequenceTableProps = {
  table: Table<Sequence>;
};

export default function SequenceTable({ table }: SequenceTableProps) {
  const sequences = useAppSelector((selector) => selector.sequences.listSequences.sequences);
  const sequencesLoading = useAppSelector((selector) => selector.sequences.listSequences.loading);
  const [currentSequenceId, setCurrentSequenceId] = useState<string>();

  const currentSequence = useMemo(() => {
    return (sequences || []).find((sequence) => sequence.id === currentSequenceId);
  }, [currentSequenceId, sequences]);

  const handleCellClick = async (id: string) => {
    setCurrentSequenceId(id);
  };

  return (
    <>
      <FullLoadingOverlay loading={sequencesLoading} />
      {sequences !== undefined && (
        <TsTable
          table={table}
          handleCellClick={handleCellClick}
          notFoundProps={{
            title: "No sequences found",
            description: "Begin creating an agent by clicking the Create Sequence button above.",
            Icon: SquaresPlusIcon,
          }}
        />
      )}
      {currentSequence && (
        <SequenceVersions
          open={!!currentSequence}
          sequence={currentSequence}
          onClose={() => setCurrentSequenceId(undefined)}
        />
      )}
    </>
  );
}
