import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Services from "@/services";
import { CallLog, KnCallMessage, KnFilter, KnFilterOptionOperatorEnum } from "@/services/generated";
import { CallLogFiltersInput } from "@/pages/ai-voice/call-logs";
import { sendCall } from "./send-call";
import { getCallSummary } from "@/redux/reducers/ai-voice/getCallSummary";

export interface RowCallLog extends CallLog {
  id: string;
}

type CallDetails = KnCallMessage[];

type State = {
  call: RowCallLog | null;
  callSummary: CallDetails | null;
  callLogs: RowCallLog[] | undefined;
  loading: boolean;
  error: string | null;
  sendCallPending?: boolean;
};

const initialState: State = {
  call: null,
  callSummary: null,
  callLogs: [],
  loading: true,
  error: null,
  sendCallPending: false,
};

export const lookupCalls = createAsyncThunk(
  "aiVoice/listCalls",
  async (filters: CallLogFiltersInput, { rejectWithValue, dispatch }) => {
    try {
      const knFilters: KnFilter[] = [];
      if (filters.callOutcome) {
        knFilters.push({
          property: "callOutcome",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Equal,
              values: [filters.callOutcome],
            },
          ],
        });
      }
      if (filters.assignee) {
        knFilters.push({
          property: "userId",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Equal,
              values: [filters.assignee],
            },
          ],
        });
      }
      if (filters.search) {
        knFilters.push({
          property: "search",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Equal,
              values: [filters.search],
            },
          ],
        });
      }
      const response = await Services.AiVoice.lookupCalls({ filters: knFilters });
      if (!response.data || response.status !== 200) {
        return [];
      }
      const rows: RowCallLog[] =
        response.data.results?.map((l) => {
          return {
            id: l.call_id!,
            ...l,
          };
        }) || [];
      return rows;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const listCalls = createSlice({
  name: "listCalls",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(lookupCalls.pending, (state) => {
        state.loading = true;
      })
      .addCase(lookupCalls.fulfilled, (state, { payload }) => {
        state.callLogs = payload;
        state.loading = false;
      })
      .addCase(getCallSummary.fulfilled, (state, { payload }) => {
        state.callSummary = payload.data;
        state.loading = false;
      })
      .addCase(sendCall.pending, (state) => {
        state.sendCallPending = true;
      })
      .addCase(sendCall.fulfilled, (state) => {
        state.sendCallPending = false;
      })
      .addCase(sendCall.rejected, (state) => {
        state.sendCallPending = false;
      });
  },
  reducers: {
    setCall: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.call = state.callLogs?.find((c) => c.id === id) || null;
    },
  },
});

export const { setCall } = listCalls.actions;

export default listCalls.reducer;
