import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { useFormContext } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getPipelineDetails, getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import FormLabel from "@/common/components/form-label/FormLabel";

interface Option {
  label: string;
  value: string;
}

export default function SetDealFlowStageForm() {
  const { control, watch } = useFormContext();
  const dispatch = useAppDispatch();
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines) || [];
  const pipelineId = watch("action.pipelineId");

  useEffect(() => {
    dispatch(getPipelines());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPipelineDetails(pipelineId));
  }, [dispatch, pipelineId]);

  const pipelineOptions: Option[] = useMemo(() => {
    return pipelines.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
  }, [pipelines]);

  const pipelineStageOptions: Option[] = useMemo(() => {
    const pipelineStages = pipelines.find((p) => p.id === pipelineId)?.stages || [];
    return pipelineStages.map((s) => {
      return {
        label: s.name,
        value: s.id,
      };
    });
  }, [pipelines, pipelineId]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.pipelineId"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <FormLabel aria-required label="Select Pipeline" />
              <Dropdown
                label="Select Pipeline"
                options={pipelineOptions}
                value={field.value}
                onChange={(option) => field.onChange(option.value)}
                error={fieldState.error?.message}
              />
            </>
          )}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.stageId"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <FormLabel aria-required label="Select Pipeline Stage" />
              <Dropdown
                label="Select Pipeline Stage"
                options={pipelineStageOptions}
                value={field.value}
                onChange={(option) => field.onChange(option.value)}
                error={fieldState.error?.message}
              />
            </>
          )}
        />
      </FormControl>
    </div>
  );
}
