import { Box } from "@mui/material";
import Column from "../column/Column";
import DealsTile from "@/common/components/deals-card/DealsHeader";
import { DealCard, PipelineStage } from "@/redux/reducers/dealFlow/slices/types";
import { ReactComponent as Spinner } from "@/common/assets/svg/spinner.svg";

type KanbanBoardProps = {
  cards: DealCard[];
  stages: PipelineStage[];
  loadingCards?: boolean;
};

export default function KanbanBoard({ cards, stages, loadingCards }: KanbanBoardProps) {
  if (loadingCards) {
    return <Spinner style={{ textAlign: "center", width: "100%" }} />;
  }
  return (
    <Box
      sx={{
        overflowX: "auto",
        display: "flex",
        gap: 3,
        mt: 2,
        pb: 1,
      }}
    >
      {stages.map((stage) => {
        const items = cards.filter((card) => card.stage.id === stage.id);
        const total = items.reduce((a, b) => a + b.price || 0, 0);
        // TODO: Replace it with frontend\apps\sales-ai\src\common\components\kanban-column
        return (
          <Column
            key={stage.id}
            header={<DealsTile title={stage.name} color={stage.color} total={total} count={items.length} />}
            stage={stage}
            items={items}
          />
        );
      })}
    </Box>
  );
}
