import { RootState } from "@/redux/store";
import Services from "@/services";
import { SequenceResponse } from "@/services/generated";
import { Sequence, SequenceFiltersType } from "@/types/sequence";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

type State = {
  sequences?: Sequence[];
  filters: SequenceFiltersType;
  loading: boolean;
  error: string | null;
  createDrawerIsOpen: boolean;
  currentSequence: Sequence | null;
  prospectSequence: SequenceResponse[];
};

const initialState: State = {
  loading: false,
  error: null,
  createDrawerIsOpen: false,
  currentSequence: null,
  filters: {},
  prospectSequence: [],
};

const DEFAULT_STATUS_FILTER: ("ACTIVE" | "DRAFT" | "INACTIVE")[] = ["ACTIVE", "DRAFT", "INACTIVE"];

export const getSequencesByContact = createAsyncThunk(
  "sequences/getSequencesByContact",
  async (contactId: string, { getState, rejectWithValue }) => {
    try {
      const response = await Services.Sequences.getSequencesByContact(contactId);

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getSequencesTemplates = createAsyncThunk(
  "sequences/getSequencesTemplates",
  async (_, { getState, rejectWithValue }) => {
    const appState = getState() as RootState;
    const sequenceFilters = appState.sequences.listSequences.filters;
    const { status = DEFAULT_STATUS_FILTER, name } = sequenceFilters;

    try {
      const response = await Services.SequenceTemplates.getSequenceTemplatesForUserGroupedByOriginal(name, status);

      const sequenceList: Sequence[] = sortBy(response.data, "updatedAt", "desc").map(
        ({
          sumProspectsEnrolled,
          originalSequenceTemplateId,
          name,
          createdBy,
          createdAt,
          sequenceTemplateVersionsList,
          latestStatus,
          updatedAt,
          latestSequenceTemplateId,
        }) => ({
          id: originalSequenceTemplateId!,
          prospectEnrolled: sumProspectsEnrolled!,
          status: latestStatus!,
          name: name!,
          createdBy: { name: createdBy!.name!, imageUrl: createdBy!.imageUrl! },
          createdAt: createdAt!,
          updatedAt: updatedAt!,
          latestSequenceTemplateId,
          versions: sortBy(sequenceTemplateVersionsList || [], "updatedAt", "desc").map((version) => ({
            id: version.id!,
            status: version.status!,
            name: version.name!,
            version: version.version,
            originalSequenceTemplateId,
            createdBy: { name: version.createdBy!.name!, imageUrl: version.createdBy!.imageUrl! },
            updatedAt: version.updatedAt!,
            createdAt: version.createdAt!,
          })),
        }),
      );
      return sequenceList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const listSequencesSlice = createSlice({
  name: "listSequences",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSequencesTemplates.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getSequencesTemplates.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.sequences = payload;
    });

    builder.addCase(getSequencesByContact.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getSequencesByContact.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.status === 200) state.prospectSequence = payload.data;
      else state.prospectSequence = [];
    });

    builder.addCase(getSequencesTemplates.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });
  },
  reducers: {
    setCurrentSequence: (state, action: PayloadAction<{ sequence: Sequence | null }>) => {
      state.currentSequence = action.payload.sequence;
    },
    updateCurrentSequence: (state, action: PayloadAction<{ sequence: Partial<Sequence> }>) => {
      const { sequence } = action.payload;
      if (state.currentSequence) {
        state.currentSequence = Object.assign(state.currentSequence, sequence);
      }
    },
    addNewSequence: (state, action: PayloadAction<{ sequence: Sequence }>) => {
      if (!state.sequences) {
        state.sequences = [action.payload.sequence];
      } else {
        state.sequences.push(action.payload.sequence);
      }
    },
    updateSequenceById: (state, action: PayloadAction<{ id: string; data: Partial<Sequence> }>) => {
      const { id, data } = action.payload;
      state.sequences = state.sequences?.map((sequence) => {
        if (sequence.id === id) {
          const newSequence: Sequence = { ...sequence, ...data };
          return newSequence;
        } else {
          return sequence;
        }
      });
    },
    deleteSequenceById: (state, action) => {
      const { id } = action.payload;
      state.sequences = state.sequences?.filter((sequence) => sequence.id !== id);
    },
    deleteSequenceVersionById: (state, action: PayloadAction<{ id: string; versionId: string }>) => {
      const { id, versionId } = action.payload;
      state.sequences = state.sequences?.map((sequence) => {
        if (sequence.id === id) {
          return { ...sequence, actions: (sequence.versions || []).filter((action) => action.id !== versionId) };
        } else {
          return sequence;
        }
      });
    },
    setSequenceFilters: (state, action: PayloadAction<SequenceFiltersType>) => {
      const newFilters = action.payload;
      state.filters = { ...state.filters, ...newFilters };
    },
  },
});

export const {
  addNewSequence,
  updateSequenceById,
  deleteSequenceById,
  setCurrentSequence,
  updateCurrentSequence,
  setSequenceFilters,
} = listSequencesSlice.actions;

export default listSequencesSlice.reducer;
