import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import ProspectFilters from "./filters";
import EditProspectDrawer from "@/pages/prospects/edit/EditProspectDrawer";
import ViewProspectDrawer from "./view/ViewProspectDrawer";
import Button from "@/common/components/button/Button";
import { useNavigate } from "react-router";
import { Badge } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getProspects, openEditDrawer } from "@/redux/reducers/prospects/slices/listProspects";
import { getCompanies } from "@/redux/reducers/prospects/companies/slices/listCompanies";
import { usePagination } from "@/common/components/pagination/usePagination";
import { ProspectColumns } from "./table/Columns";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { setCurrentProspect, openViewDrawer } from "@/redux/reducers/prospects/slices/listProspects";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { ReactComponent as NoProspectsSVG } from "@/common/assets/svg/Prospects/zero-prospect-graph.svg";
import TsTable from "@/common/components/ts-table";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import routes from "@/common/constants/routes";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import PageHeader from "@/common/components/page-header/PageHeader";

type filterType = {
  searchString?: string;
};

export default function ProspectsPage() {
  const { prospects, loading, totalCount, createProspectIsLoading, updateProspectIsLoading } = useAppSelector(
    ({ prospects }) => prospects.listProspects,
  );
  const companies = useAppSelector(({ companies }) => companies.listCompanies.companies);
  const users = useAppSelector(({ team }) => team.listUsers.users);
  const [filters, setFilters] = useState<filterType>({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleFilters = (newFilters: filterType) => setFilters((filters) => ({ ...filters, ...newFilters }));
  const { onPaginationChange, pagination } = usePagination();

  useEffect(() => {
    dispatch(
      getProspects({
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,

        ...filters,
      }),
    );
  }, [dispatch, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (companies === undefined) {
      dispatch(getCompanies());
    }
  }, [dispatch, companies]);

  useEffect(() => {
    dispatch(getTeamUsers());
  }, [dispatch]);

  const prospectsWithOwnerNames = useMemo(() => {
    return (
      prospects?.map((prospect) => {
        if (!prospect.ownerUserId) return prospect;

        const owner = users?.find((user) => user.user_id === prospect.ownerUserId);
        if (!owner) return prospect;

        return {
          ...prospect,
          ownerUserFullName: `${owner.firstName || ""} ${owner.lastName || ""}`.trim(),
        };
      }) || []
    );
  }, [users, prospects]);

  const handleNavigate = () => {
    navigate(routes.dataImport.import.path);
  };

  const handleCellClick = (id: any) => {
    const prospect = prospects?.find((prospect: Prospect) => prospect.id === id);
    dispatch(setCurrentProspect(prospect));
    dispatch(openViewDrawer());
  };

  const table = useReactTable<any>({
    data: prospectsWithOwnerNames || [],
    columns: ProspectColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: totalCount,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <>
      {loading ? (
        <FullLoadingOverlay loading={loading} />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <FullLoadingOverlay loading={createProspectIsLoading || updateProspectIsLoading} overlay={true} />
          <PageHeader
            title={t("prospects.title")}
            badge={<Badge badgeContent={602} max={999} color="primary" />}
            actions={
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Button variant="secondary" onClick={handleNavigate}>
                  Import Prospects
                </Button>
                <Button
                  data-testid="openProspectDrawer"
                  variant="primary"
                  onClick={() => {
                    dispatch(openEditDrawer());
                  }}
                >
                  {t("prospects.createProspectTitle")}
                </Button>
              </Box>
            }
            filters={<ProspectFilters onSetFilters={handleFilters} />}
          />

          <ViewProspectDrawer />
          <EditProspectDrawer />

          <TsTable
            table={table}
            handleCellClick={handleCellClick}
            notFoundProps={{
              title: "No prospects created yet!",
              description: "Begin creating an agent by clicking the Create New Prospect button above.",
              Icon: NoProspectsSVG,
            }}
          />
        </Box>
      )}
    </>
  );
}
