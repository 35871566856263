import { FormControl, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { useFormContext } from "react-hook-form";
import { getSequences } from "@/redux/selectors/sequences";
import { Sequence } from "@/types/sequence";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useMemo } from "react";
import { getSequencesTemplates } from "@/redux/reducers/sequences/slices/listSequences";
import SequenceStatus from "@/pages/sequence-builder/sequence-status";

export default function EnrollSequenceForm() {
  const dispatch = useAppDispatch();
  const { control } = useFormContext();
  const { sequences } = useAppSelector(({ sequences }) => sequences.listSequences);

  useEffect(() => {
    dispatch(getSequencesTemplates());
  }, [dispatch]);

  const getVersionActive = (sequence: Sequence): Sequence | null => {
    // TODO: API returns "Latest Status" on top level response Sequence, then uses that as Sequence.
    // if (sequence.status === "ACTIVE") {
    //   return sequence;
    // }
    return sequence.versions?.find((v) => v.status === "ACTIVE") || null;
  };

  const activeSequenceOptions = useMemo(() => {
    console.log(sequences);
    const activeSequences = sequences?.map((s) => getVersionActive(s)).filter((s) => s != null) || [];
    return activeSequences.map((sequence: Sequence) => ({
      label: sequence.name,
      value: sequence.id,
    }));
  }, [sequences]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography variant="body3">Enroll in sequence</Typography>

      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="action.sequenceId"
          control={control}
          render={({ field }) => {
            return (
              <>
                <FormLabel aria-required label="Select sequence" />
                <Dropdown
                  label="Select sequence"
                  options={activeSequenceOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </>
            );
          }}
        />
      </FormControl>
    </div>
  );
}
