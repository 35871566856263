import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { CustomDatePicker } from "@/common/components/custom-date-picker/CustomDatePicker";

const TaskTypeSelectOptions = [
  { label: "All", value: "all" },
  { label: "Email", value: "email" },
  { label: "Call", value: "call" },
  { label: "Meeting", value: "meeting" },
];

const TaskStatusSelectOptions = [
  { label: "All", value: "all" },
  { label: "Open", value: "open" },
  { label: "Closed", value: "closed" },
  { label: "Pending", value: "pending" },
];

type InboxFiltersType = {
  selectedTaskType?: string;
  selectedStatus?: string;
};

type InboxFilterProps = {
  onSetFilters: (filters: InboxFiltersType) => void;
};

export default function InboxFilters({ onSetFilters }: InboxFilterProps) {
  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr auto auto",
          alignItems: "center",
          gap: "16px",
          marginBottom: "16px",
        }}
      >
        <Box sx={{ gridColumn: "1 / 2" }}>
          <Searchbar placeholder="Search" />
        </Box>

        <Box sx={{ gridColumn: "3 / 4", minWidth: 200 }}>
          <Dropdown
            label="Activity Type"
            isMulti={true}
            placeholder="Activity Type"
            options={TaskTypeSelectOptions}
            onChange={(option) => {
              onSetFilters({ selectedTaskType: option.value });
            }}
          />
        </Box>

        <Box sx={{ gridColumn: "4 / 5", minWidth: 200 }}>
          <CustomDatePicker isRange onChange={(date) => console.log(date)} value={null} />
        </Box>
      </Box>
    </form>
  );
}
