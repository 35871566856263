import { Box, Typography, useTheme } from "@mui/material";
import { SequenceActionTypes } from "./SequenceActionTypes";
import { SequenceActionContent } from "./SequenceActionContent";
import { SequenceOptions } from "./SequenceOptions";
import { useEffect, useMemo, useState } from "react";
import { SequenceAction as SequenceActionInterface } from "@/types/sequence";
import { lookupAgents } from "@/redux/reducers/ai-voice/listAgents";
import { KnAiAgentCallDirectionEnum } from "@/services/generated";
import { useAppDispatch, useAppSelector } from "@/redux/store";

export interface SequenceActionProps extends SequenceActionInterface {
  index: number;
  canClose?: boolean;
  canUpdateSequence?: boolean;
  onSelectActionType?: (actionType: SequenceActionInterface["actionType"]) => void;
  onUpdatedAction?: () => void;
  onMoveUp?: (id: string) => void;
  onMoveDown?: (id: string) => void;
  onDuplicateAction?: (id: string) => void;
  onSetDelay?: (id: string, delay: number) => void;
  onRemoveAction?: (id: string) => void;
  onRemoveDraftAction?: () => void;
  aiAgentId?: string;
}

export default function SequenceAction(props: SequenceActionProps) {
  const theme = useTheme();
  const {
    id = "",
    index,
    delay = 0,
    actionType,
    canClose = false,
    canUpdateSequence,
    content = "",
    subject = "",
    aiAgentId,
    onSelectActionType,
    onUpdatedAction,
    onMoveUp,
    onMoveDown,
    onDuplicateAction,
    onSetDelay,
    onRemoveDraftAction,
    onRemoveAction,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const agentsList = useAppSelector(({ aiVoice }) => aiVoice.listAgents.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (agentsList === undefined) {
      dispatch(lookupAgents({ callDirection: KnAiAgentCallDirectionEnum.Outbound }));
    }
  }, [dispatch, agentsList]);

  const agent = useMemo(() => {
    if (!agentsList || !aiAgentId) {
      return null;
    }
    return agentsList.find((a) => a.agent_id === aiAgentId) || null;
  }, [agentsList, aiAgentId]);

  const handleSelectActionType = (type: SequenceActionInterface["actionType"]) => {
    onSelectActionType?.(type);
  };

  return (
    <Box sx={{ gap: "8px", display: "flex" }}>
      <Box
        sx={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          width: "654px",
          marginLeft: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ background: theme.palette.bg.inverse, padding: "4px 16px", borderRadius: "8px" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
                color: theme.palette.txt.default,
              }}
            >
              Step {index + 1}
            </Typography>
          </Box>
          {delay && delay > 0 ? (
            <Box sx={{ background: theme.palette.bg.inverse, padding: "4px 16px", borderRadius: "8px" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: theme.palette.txt.default,
                }}
              >
                Delay: {delay} days
              </Typography>
            </Box>
          ) : (
            "No Delay"
          )}
        </Box>

        {actionType ? (
          <SequenceActionContent
            actionType={actionType}
            setIsExpanded={setIsExpanded}
            expanded={isExpanded}
            subject={subject}
            content={content}
            agent={agent}
          />
        ) : (
          <SequenceActionTypes
            title="Actions"
            canClose={canClose}
            onClose={() => onRemoveDraftAction?.()}
            setActionType={handleSelectActionType}
          />
        )}
      </Box>
      {canUpdateSequence && actionType && isExpanded && (
        <SequenceOptions
          delay={delay}
          onUpdateAction={() => onUpdatedAction?.()}
          onMoveUp={() => onMoveUp?.(id)}
          onMoveDown={() => onMoveDown?.(id)}
          onDuplicateAction={() => onDuplicateAction?.(id)}
          onSetDelay={(delay) => {
            onSetDelay?.(id, delay);
          }}
          onRemoveAction={() => onRemoveAction?.(id)}
        />
      )}
    </Box>
  );
}
