import { Typography, useTheme } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as Contract } from "@/common/assets/svg/triggers/contact_created.svg";
import Box from "@mui/material/Box";
import { useAppSelector } from "@/redux/store";
import { useEffect } from "react";
import { useAppDispatch } from "@/redux/store";
import { getDealsList } from "@/redux/reducers/deals/slices/listDeals";
import { ListByEventTypeEventEnum } from "@/services/generated";
import DealFlowStateChangeForm from "@/pages/triggers/edit/steps/event/deal/DealFlowStateChange";

const eventTypeOptions = [
  {
    title: "Deal Flow State Change",
    img: <Contract />,
    value: ListByEventTypeEventEnum.DealFlowStateChange,
  },
  // {
  //   title: "deal Assignee Change",
  //   img: <Meeting />,
  //   value: ListByEventTypeEventEnum.DealAssigneeChange,
  // },
];

export default function Index() {
  const methods = useFormContext();
  const theme = useTheme();
  const { control } = methods;
  const deals = useAppSelector(({ deals }: any) => deals.listDeals.deals);
  const dispatch = useAppDispatch();
  const selectedEventType = methods.watch("event.eventType");

  useEffect(() => {
    if (deals === undefined) {
      dispatch(getDealsList() as any);
    }
  }, [dispatch, deals]);

  const Card = (props: { title: string; img: React.ReactNode; value: string }) => {
    return (
      <Controller
        name="event.eventType"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <label htmlFor={props.value} style={{ cursor: "pointer", width: "100%" }}>
            <div
              style={{
                borderRadius: "8px",
                padding: "10px",
                border: `${field.value === props.value ? `2px solid #1C73C1` : "1px solid #E0E0E0"}`,
              }}
            >
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Box
                  sx={{
                    background: theme.palette.mint.p,
                    padding: "4px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {props.img}
                </Box>

                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <div>
                    <Typography variant="body2">{props.title}</Typography>
                  </div>

                  <input
                    type="radio"
                    id={props.value}
                    {...field}
                    value={props.value}
                    checked={field.value === props.value}
                    onChange={() => {
                      field.onChange(props.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </label>
        )}
      />
    );
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        margin: "0 auto",
      }}
    >
      {eventTypeOptions.map(({ title, img, value }) => (
        <div key={title}>
          <Card title={title} img={img} value={value} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0px 40px",
            }}
          >
            {selectedEventType === ListByEventTypeEventEnum.DealFlowStateChange &&
            value === ListByEventTypeEventEnum.DealFlowStateChange ? (
              <DealFlowStateChangeForm />
            ) : null}
            {/*{selectedEventType === ListByEventTypeEventEnum.DealAssigneeChange &&*/}
            {/*value === ListByEventTypeEventEnum.DealAssigneeChange ? (*/}
            {/*  <DealAssigneeChangeForm />*/}
            {/*) : null}*/}
          </Box>
        </div>
      ))}
    </div>
  );
}
