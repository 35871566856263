import { Box } from "@mui/material";
import { ReactComponent as Search } from "../../assets/svg/magnifying_glass.svg";
import Input from "../input/Input";
import _ from "lodash";

type SearchBarProps = {
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  isOptional?: boolean;
  multiline?: boolean;
  type?: string;
  error?: string;
  sx?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

export default function SearchBar(props: SearchBarProps) {
  const { sx, placeholder, label, disabled, isOptional, multiline, type, error, onChange, value = "" } = props;

  return (
    <Box style={{ position: "relative" }} sx={sx}>
      <Input
        value={value}
        placeholder={placeholder}
        label={label}
        disabled={disabled}
        isOptional={isOptional}
        multiline={multiline}
        type={type}
        error={error}
        onChange={(e) => onChange?.(e)}
      />
      <Search
        style={{
          position: "absolute",
          right: "20px",
          top: "15px",
          width: "20px",
          marginRight: "12px",
          paddingLeft: "5px",
          background: "inherit",
        }}
      />
    </Box>
  );
}
