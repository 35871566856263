import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

const BorderedProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  borderRadius: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.midnight[20],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mint.p,
  },
}));

const LinearProgressBar = (props: any) => {
  return <BorderedProgressBar variant="determinate" {...props} />;
};

export default LinearProgressBar;
