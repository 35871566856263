import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { useAppDispatch } from "@/redux/store";
import { SequenceTemplateStatusType } from "@/types/sequence";
import { getSequencesTemplates, setSequenceFilters } from "@/redux/reducers/sequences/slices/listSequences";
import _ from "lodash";

const SEQUENCE_STATUS_SELECT_OPTIONS = [
  { label: "All", value: "ALL" },
  { label: "Draft", value: "DRAFT" },
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

export default function SequenceFilters({ filters, setFilters }: any) {
  const dispatch = useAppDispatch();

  const debounceGetSequencesTemplates = _.debounce(() => {
    dispatch(getSequencesTemplates());
  }, 500);

  const handleChangeStatus = (option: { value: SequenceTemplateStatusType | "ALL" }) => {
    if (option.value === "ALL") {
      dispatch(
        setSequenceFilters({
          status: undefined,
        }),
      );
      dispatch(getSequencesTemplates());
      return;
    }
    dispatch(setSequenceFilters({ status: [option.value] }));
    dispatch(getSequencesTemplates());
  };

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 2fr 1fr",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box sx={{ gridColumn: "1 / 2" }}>
          <Searchbar
            value={filters.name}
            onChange={(event) => {
              dispatch(setSequenceFilters({ name: event.target.value }));
              debounceGetSequencesTemplates();
            }}
            placeholder="Search for a sequence"
          />
        </Box>

        <Box sx={{ gridColumn: "3 / 4" }}>
          <Dropdown
            label="Status"
            placeholder="Status"
            options={SEQUENCE_STATUS_SELECT_OPTIONS}
            value={filters.status?.[0] || SEQUENCE_STATUS_SELECT_OPTIONS[0].value}
            onChange={handleChangeStatus}
          />
        </Box>
      </Box>
    </form>
  );
}
