import {
  Accordion as MuiAccordion,
  Typography,
  AccordionProps as MuiAccordionProps,
  Box,
  SvgIcon,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ChevronDown } from "heroicons-react";

export interface AccordionProps extends MuiAccordionProps {
  summaryTitle: string | React.ReactNode;
  icon?: React.ReactNode;
  id: string;
  disabledBorderBottom?: boolean;
  disabledSummary?: boolean;
  customElementIcon?: React.ReactNode;
  expanded?: boolean;
  handleChange?: () => void;
}

export default function Accordion(props: AccordionProps) {
  const {
    summaryTitle,
    icon,
    children,
    customElementIcon,
    disabledSummary,
    disabledBorderBottom = false,
    expanded,
    handleChange = () => null,
    ...rest
  } = props;

  return (
    <MuiAccordion
      onChange={handleChange}
      expanded={expanded}
      {...rest}
      disableGutters
      sx={{
        width: "100%",
        background: "transparent",
        border: "none",
        "&.MuiAccordion-root": {
          boxShadow: "none",
          border: "none",
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
          color: "#1A2135",
          width: "20px",
          height: "20px",
          display: "flex",
          alignItems: "center",
        },

        ...rest?.sx,
      }}
    >
      <AccordionSummary
        sx={(theme) => ({
          borderBottom: disabledBorderBottom ? null : `1px solid ${theme.palette.border.default}`,
          display: "flex",
          justifyContent: "space-between",
          "& .MuiAccordionSummary-content": {
            margin: "0px 0px",
          },
        })}
        expandIcon={<ChevronDown />}
        disabled={disabledSummary}
      >
        <Box sx={{ display: "flex", gap: "4px", justifyContent: "center", alignItems: "center" }}>
          <Box>
            {icon ? (
              <SvgIcon
                sx={(theme) => ({
                  backgroundColor: theme.palette.border.default,
                  borderRadius: "4px",
                  padding: "4px",
                  height: "16px",
                  width: "16px",
                  display: "flex",
                  alignItems: "flex-end",
                })}
              >
                {icon}
              </SvgIcon>
            ) : (
              customElementIcon
            )}
          </Box>
          {typeof summaryTitle === "string" ? (
            <Typography variant="body2" fontWeight="600">
              {summaryTitle}
            </Typography>
          ) : (
            summaryTitle
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0px",
          paddingTop: "12px",
          border: "none",
          borderBottom: "transparent",
        }}
      >
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
}
