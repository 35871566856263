import { Box } from "@mui/material";
import { selectInboxMessages } from "@/redux/selectors";
import SendNewMessage from "../send-new/SendNewMessage";
import InboxFilters from "../filters/InboxFilters";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import dayjs from "dayjs";
import { Message } from "@/types/inbox";
import { openViewDrawer } from "@/redux/reducers/inbox/slices/getInboxData";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import PageHeader from "@/common/components/page-header/PageHeader";
import { InboxRow } from "@/pages/inbox/list/InboxRow";
import { setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";

type InboxListProps = {
  cursor: number;
  itemsPerPage: number;
  totalRows: number;
};

const InboxList = (props: InboxListProps) => {
  const { cursor, itemsPerPage, totalRows } = props;

  const dispatch = useAppDispatch();
  const messages = useAppSelector(selectInboxMessages);
  const { t } = useTranslation();
  const prospectsInbox = useAppSelector(({ prospects }) => prospects.listProspects.prospectsInbox);

  const onInboxItemClick = async (message: Message) => {
    const prospectId = message.prospect_id;
    const prospect = prospectsInbox?.find((p) => p.id === prospectId);
    dispatch(setCurrentProspect(prospect));
    dispatch(openViewDrawer());
  };

  const dayLabel = (date: string) => {
    const today = dayjs();
    const messageDate = dayjs(date);

    if (today.isSame(messageDate, "day")) {
      return "TODAY";
    } else if (today.subtract(1, "day").isSame(messageDate, "day")) {
      return "YESTERDAY";
    } else {
      return messageDate.format("MMMM DD, YYYY");
    }
  };

  const DateLabel = ({ createdAt }: { createdAt: string }) => (
    <Box
      sx={{
        background: "#F0F0F0",
        padding: "8px 16px",
        borderRadius: "6px",
        marginBottom: "4px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.2px",
        color: "#1A2135",
      }}
    >
      {createdAt}
    </Box>
  );

  const groupedMessages = messages.reduce(
    (
      groups: {
        [key: string]: Message[];
      },
      message: Message,
    ) => {
      const dateLabel = message.createdAt ? dayLabel(message.createdAt) : "Unknown Date";
      if (!groups[dateLabel]) {
        groups[dateLabel] = [];
      }
      groups[dateLabel].push(message);
      return groups;
    },
    {},
  );

  return (
    <Box sx={{ width: "100%", background: "#F8F9FA" }}>
      <PageHeader
        title={t("inbox.title")}
        badge={<Badge badgeContent={128} max={999} color="primary" />}
        actions={<SendNewMessage />}
        filters={<InboxFilters onSetFilters={(filters) => console.log(filters)} />}
      />

      <Box sx={{ marginTop: "8px" }}>
        {Object.entries(groupedMessages).map(([date, messages]: [string, Message[]]) => (
          <div key={date}>
            <DateLabel createdAt={date} />
            {messages.map((message: Message) => (
              <InboxRow message={message} onClick={() => onInboxItemClick(message)} />
            ))}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default InboxList;
