import { components } from "react-select";

export const ValueContainer = ({ children, ...props }: any) => {
  return (
    <components.ValueContainer
      {...props}
      getStyles={(base: any) => ({
        ...base,
        display: "flex",
        padding: "0px",
      })}
    >
      {children}
    </components.ValueContainer>
  );
};
