import React from "react";
import { TabListProps as MuiTabListProps } from "@mui/lab";
import { Typography, useTheme } from "@mui/material";
import { dateFormatMs, dateFormatTs } from "@/common/functions/dateFormat";
import dayjs from "dayjs";

export interface TableDateProps extends MuiTabListProps {
  timestamp?: { seconds: number; nanos: number };
  nanos?: number;
  millis?: number;
  date?: string;
}

export default function TableDate(props: TableDateProps) {
  // TODO change to instanceof
  const date = props.date;
  if (date) {
    // "MMM DD, YYYY hh:mm A"
    return <Typography variant="body2">{dayjs(date).format("MMM DD, YYYY")}</Typography>;
  }

  const nanos = props.nanos;
  if (nanos) {
    return <Typography variant="body2">{dateFormatMs(nanos / 1000)}</Typography>;
  }

  const millis = props.millis;
  if (millis) {
    return <Typography variant="body2">{dateFormatMs(millis)}</Typography>;
  }

  const timestamp = props.timestamp;
  if (timestamp) {
    return <Typography variant="body2">{dateFormatTs(timestamp)}</Typography>;
  }

  throw Error();
}
