import { Typography, useTheme } from "@mui/material";
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { forwardRef } from "react";

export type CheckboxProps = {
  label?: string;
  error?: string;
  hasError?: boolean;
  disabled?: boolean;
  checked: boolean | "indeterminate" | undefined;
  icon?: JSX.Element;
  inputRef?: React.Ref<HTMLInputElement>;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>((props: CheckboxProps, ref) => {
  const { label = "", onChange, error, hasError = !!props.error, disabled, checked, style, ...rest } = props;
  const theme = useTheme();
  const checkboxProps: MuiCheckboxProps = { inputProps: { "aria-label": label } };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <>
          <FormControl>
            <MuiCheckbox
              {...checkboxProps}
              disableRipple
              icon={
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H16C17.933 0.5 19.5 2.067 19.5 4V16C19.5 17.933 17.933 19.5 16 19.5H4C2.067 19.5 0.5 17.933 0.5 16V4Z"
                    fill="white"
                    stroke={hasError ? theme.palette.error.main : "#E7EBED"}
                  />
                </svg>
              }
              sx={{
                marginRight: 1,
                color: hasError ? theme.palette.error.main : "#E7EBED",
                background: "#FFF",
                width: 16,
                height: 16,
                borderRadius: "4px",

                border: `1px solid ${hasError ? theme.palette.error.main : "#E7EBED"}`,
                "&.Mui-checked": {
                  color: hasError ? theme.palette.error.main : theme.palette.midnight.p,
                  border: `1px solid ${hasError ? theme.palette.error.main : theme.palette.midnight.p}`,
                },
                "& svg": {},

                ...style,
              }}
              disabled={disabled}
              checked={checked === "indeterminate" ? false : checked}
              indeterminate={checked === "indeterminate"}
              onChange={onChange}
              {...rest}
            />
          </FormControl>
        </>

        <Box
          sx={{
            color: theme.palette.midnight.p,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
          }}
        >
          {label}
        </Box>
      </Box>
      {error && <Typography style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>{error}</Typography>}
    </>
  );
});

export default Checkbox;
