import { useAppSelector } from "@/redux/store";
import { ArrowDownLeftIcon, ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import AudioPlayer from "../AudioPlayer";
import { useNavigate } from "react-router-dom";
import routes from "@/common/constants/routes";
import React from "react";
import Link from "@/common/components/link";
import { CallLogCallOutcomeEnum, CallLogCallStatusEnum } from "@/services/generated";

interface ChipConfig {
  color: string;
  colorBackground: string;
  text: string;
}

const formatDate = (date: string) => {
  return dayjs(date).format("MMM DD, YYYY - hh:mm A");
};

function formatTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
}

export default function CallsMessages() {
  const theme = useTheme();
  const navigate = useNavigate();
  const callCurrentInbox = useAppSelector(({ inbox }) => inbox.getInboxData.callCurrentInbox);

  const openMessages = (id: string) => {
    navigate(routes.aiVoice.callLogs.id.with({ id }));
  };

  // This is the only place we build chip based on "Message".
  const chip = (call: any) => {
    const config = toChipConfig(call);
    return (
      <Box
        sx={{
          background: config.colorBackground,
          color: config.color,
          padding: "4px 8px",
          borderRadius: "24px",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: "500",
        }}
      >
        {config.text}
      </Box>
    );
  };

  const toChipConfig = (call: any): ChipConfig => {
    const status = call.status;
    const outcome = call.outcome;
    if (status === CallLogCallStatusEnum.Failure) {
      return {
        color: theme.palette.commonColors.white,
        colorBackground: theme.palette.commonColors.danger,
        text: "In Progress",
      };
    }
    if (status === CallLogCallStatusEnum.InProgress) {
      return {
        color: theme.palette.commonColors.white,
        colorBackground: theme.palette.commonColors.informative,
        text: "In Progress",
      };
    }
    if (outcome === CallLogCallOutcomeEnum.OutboundDnc) {
      return {
        color: theme.palette.commonColors.black,
        colorBackground: theme.palette.commonColors.warning,
        text: "DNC",
      };
    }
    if (outcome === CallLogCallOutcomeEnum.OutboundVoicemail) {
      return {
        color: theme.palette.commonColors.black,
        colorBackground: theme.palette.commonColors.warning,
        text: "DNA",
      };
    }
    if (outcome === CallLogCallOutcomeEnum.Booked) {
      return {
        color: theme.palette.commonColors.black,
        colorBackground: theme.palette.commonColors.success,
        text: "Booked",
      };
    }
    if (outcome === CallLogCallOutcomeEnum.OutboundAnswered) {
      return {
        color: theme.palette.commonColors.black,
        colorBackground: "#F2F4F8",
        text: "Booked",
      };
    }
    return {
      color: theme.palette.commonColors.black,
      colorBackground: "#F2F4F8",
      text: formatTime(call?.duration),
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        overflowY: "auto",
      }}
    >
      {callCurrentInbox?.map((call: any) => {
        const isSuccess = call.status === CallLogCallStatusEnum.Success;
        const callRecordingUrl = call?.recording_url;
        return (
          <Box
            key={call?.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              border: "1px solid #E7EBED",
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                color: "#6B747B",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.2px",
              }}
            >
              {formatDate(call?.createdAt)}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: "8px" }}>
                {call?.source === "OUTBOUND" ? (
                  <ArrowUpRightIcon
                    width="24"
                    height="24"
                    style={{
                      color: "#1A2135",
                      background: "#F4F5F7",
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ArrowDownLeftIcon
                    width="24"
                    height="24"
                    style={{
                      color: "#1A2135",
                      background: "#F4F5F7",
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                )}
                <Box
                  sx={{
                    fontWeight: 600,
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                >
                  {/* {CallLogCallFlowEnum[message?.flow as keyof typeof CallLogCallFlowEnum]} */}
                  {call?.flow}
                </Box>
              </Box>
              {chip(call)}
            </Box>

            <Box
              sx={{
                padding: "8px",
                background: "#F2F4F8",
                borderRadius: "8px",
                color: "#1A2135",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.2px",
                fontWeight: 400,
              }}
            >
              {call?.body}
            </Box>

            {isSuccess && (
              <Link onClick={() => openMessages(call.call_id)} size="sm">
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M16.25 11.875V9.6875C16.25 8.94158 15.9537 8.22621 15.4262 7.69876C14.8988 7.17132 14.1834 6.875 13.4375 6.875H12.1875C11.9389 6.875 11.7004 6.77623 11.5246 6.60041C11.3488 6.4246 11.25 6.18614 11.25 5.9375V4.6875C11.25 3.94158 10.9537 3.22621 10.4262 2.69876C9.89879 2.17132 9.18342 1.875 8.4375 1.875H6.875M6.875 12.5H13.125M6.875 15H10M8.75 1.875H4.6875C4.17 1.875 3.75 2.295 3.75 2.8125V17.1875C3.75 17.705 4.17 18.125 4.6875 18.125H15.3125C15.83 18.125 16.25 17.705 16.25 17.1875V9.375C16.25 7.38588 15.4598 5.47822 14.0533 4.0717C12.6468 2.66518 10.7391 1.875 8.75 1.875Z"
                      stroke="#374667"
                      stroke-width="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Box
                    sx={{
                      color: "#2C72E2",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      letterSpacing: "0.2px",
                    }}
                  >
                    Full Transcript
                  </Box>
                </Box>
              </Link>
            )}

            {callRecordingUrl && <AudioPlayer url={callRecordingUrl} />}
          </Box>
        );
      })}
    </Box>
  );
}
