import { AiVoiceCallFlowType, AiVoiceCallFlowOutcome } from "@/types/ai-voice";

type Option = {
  label: string;
  description: string;
  bgcolor?: string;
  color?: string;
};

export const CALL_FLOW_TYPE_OPTIONS: Record<AiVoiceCallFlowType, Option> = {
  QUALIFY_AND_BOOK_A_MEETING: {
    label: "Qualify and Book a Prospect",
    description:
      "The agent will introduce itself, qualify the prospect by assessing their needs and suitability, and if qualified, book and confirm an appointment.",
  },
  BOOK_A_MEETING: {
    label: "Book a Meeting with a Prospect",
    description:
      "The agent will greet the prospect, clarify the purpose of the meeting, ask for the prospect’s availability, offer available time slots, confirm the chosen slot, and send the meeting invite.",
  },
  QUALIFY_AND_LIVE_TRANSFER: {
    label: "Qualify and Live Transfer a Prospect",
    description:
      "The agent will greet the prospect, introduce itself, determine if the prospect is qualified, and if so, inform the prospect about the transfer process and facilitate the transfer.",
  },
  ANSWER_FAQS: {
    label: "Answer FAQs for Customers",
    description:
      "The agent greets the customer, asks how it can assist, listens to the customer’s query, searches its knowledge base for relevant information, provides an answer, and offers additional resources if needed.",
  },
  CONFIRM_A_MEETING: {
    label: "Confirm a Meeting with a Prospect",
    description:
      "The agent will greet the prospect, explain the purpose of the call, confirm meeting call-summary, offer to resend the invite if needed, and provide reminders as requested.",
  },
  PROMPT: {
    label: "Prompt Based",
    description: "The agent will greet the prospect, and then follow the prompt.",
  },
};

export const CALL_FLOW_OUTCOME_OPTIONS: Record<AiVoiceCallFlowOutcome, Option> = {
  UNSPECIFIED: {
    label: "Unspecified",
    description: "",
    bgcolor: "#D3D3D3",
  },
  ERROR: {
    label: "Error",
    description: "",
    bgcolor: "#FF0000",
  },
  INBOUND: {
    label: "Inbound",
    description: "",
    bgcolor: "#69C0FF80",
  },
  OUTBOUND_DNC: {
    label: "Outbound DNC",
    description: "",
    bgcolor: "#FFD700",
  },
  OUTBOUND_VOICEMAIL: {
    label: "Voicemail",
    description: "",
    bgcolor: "#FF4500",
  },
  OUTBOUND_ANSWERED: {
    label: "Answered",
    description: "",
    bgcolor: "#0050B3CC",
    color: "#FFFFFF",
  },
  BOOKED: {
    label: "Booked",
    description: "",
    bgcolor: "#98FB98",
  },
  TRANSFER: {
    label: "Transferred",
    description: "",
    bgcolor: "#B389FB80",
  },
  TRANSFER_SALES: {
    label: "Transferred ",
    description: "",
    bgcolor: "#B389FB80",
  },
  TRANSFER_BILLING: {
    label: "Transferred",
    description: "",
    bgcolor: "#B389FB80",
  },
  TRANSFER_SUPPORT: {
    label: "Transferred",
    description: "",
    bgcolor: "#B389FB80",
  },
};
