import { Box, Stack, Typography } from "@mui/material";
import Avatar from "../avatar/Avatar";
import { useTheme } from "@mui/material";
import { ReactComponent as UserCircle } from "../../assets/svg/deals/user-circle.svg";
import HeatScore from "../heat-score/HeatScore";

export type DealsCardProps = {
  name?: string;
  heatScore: number;
  callDaysAgo: number;
  campaign: string;
  price: string;
  avatar?: string;
};

export default function DealsCard(props: DealsCardProps) {
  const { campaign, price, name, heatScore, callDaysAgo, avatar } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.tableRow.pair,
        padding: "8px",
        borderRadius: "6px",
        border: "1px solid #E7EBED",
        fontWeight: 400,
        transition: "all 0.3s",
        "&:hover": {
          borderColor: theme.palette.midnight.p,
          background: theme.palette.midnight[20],
        },
        cursor: "pointer",
        gap: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          color: theme.palette.txt.default,
        }}
      >
        <Typography
          sx={{
            lineHeight: "20px",
            letterSpacing: "0.2px",
            fontWeight: 600,
          }}
        >
          {name}
        </Typography>
        {avatar ? <Avatar src={avatar} size="small" /> : <Avatar label={name} size="small" />}
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <UserCircle />
        <Typography sx={{ fontSize: "14px" }}>{name}</Typography>
      </Stack>

      <Stack direction="row" spacing="2px" alignItems="center" justifyContent="space-between">
        <HeatScore score={heatScore} />

        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>${parseFloat(price).toFixed(2)}</Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={{ fontSize: "14px" }}>Call {callDaysAgo} days ago</Typography>
      </Stack>
    </Box>
  );
}
