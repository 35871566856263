import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Button from "@/common/components/button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DrawerLayout from "@/layouts/DrawerLayout";
import Stack from "@mui/material/Stack";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input/Input";
import {
  closeEditDrawer,
  createProspect,
  setCurrentProspect,
  updateProspect,
} from "@/redux/reducers/prospects/slices/listProspects";
import {
  ProspectConsentLevelOptions,
  ProspectJobTitleOptions,
  ProspectAddressOptions,
  ProspectLeadTypeOptions,
} from "@/redux/reducers/prospects/slices/helpers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnContactRequest, LeadSourceTypeEnum } from "@/services/generated";
import { useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PhoneInput from "@/common/components/phone-input";

const schema = Yup.object({
  firstName: Yup.string().label("First name").required(),
  lastName: Yup.string().label("Last name").required(),
  email: Yup.string(), //.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email"),
  phoneNumber: Yup.string().required(),
  companyId: Yup.string(),
  jobTitle: Yup.string(),
  address: Yup.object({
    city: Yup.string(),
    state: Yup.string(),
  }),
  ownerUserId: Yup.string(),
  leadType: Yup.string(),
  consentLevel: Yup.string(),
  declaredConsentOriginSource: Yup.string(),
});

export type ContactForm = Yup.InferType<typeof schema>;

export default function EditProspectForm() {
  const theme = useTheme();
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentProspect = useAppSelector(({ prospects }) => prospects.listProspects.currentProspect);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const companies = useAppSelector(({ companies }) => companies.listCompanies.companies);

  const { control, handleSubmit, formState, reset } = useForm<ContactForm>({
    resolver: yupResolver(schema),
  });
  console.log(formState);

  useEffect(() => {
    // TODO order
    reset({
      firstName: currentProspect?.firstName,
      lastName: currentProspect?.lastName,
      email: currentProspect?.email,
      phoneNumber: currentProspect?.phoneNumber,
      companyId: currentProspect?.companyId || "",
      // jobTitle: currentProspect?.jobTitle,
      // address: currentProspect?.address,
      // leadType: currentProspect?.leadType || "",
      // consentLevel: currentProspect?.consentLevel,
      ownerUserId: currentProspect?.ownerUserId,
    });
  }, [currentProspect, reset]);

  const assigneeOptions = useMemo(() => {
    return (
      teamUsers?.map((a) => {
        return {
          value: a.id!,
          label: a.firstName! + " " + a.lastName!,
        };
      }) || []
    );
  }, [teamUsers]);

  const companyOptions = useMemo(() => {
    return (
      companies?.map((c) => {
        return {
          label: c.name,
          value: c.id,
        };
      }) || []
    );
  }, [companies]);

  const onSubmit: SubmitHandler<ContactForm> = (data) => {
    if (!data) return;

    // TODO Prospect and Form fields must overlap in naming
    const prospect: KnContactRequest = {
      // ...currentProspect,
      id: currentProspect?.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      companyId: data.companyId,
      jobTitle: data.jobTitle,
      ownerUserId: data.ownerUserId,
      leadSource: LeadSourceTypeEnum.Ui,
      leadType: data.leadType,
      // consentLevel: data.consentLevel,
      address: data.address,
      ownerTeamId: user?.team_id,
    };

    if (currentProspect?.id) {
      dispatch(updateProspect(prospect));
    } else {
      dispatch(createProspect(prospect));
    }
    closeDrawer();
  };

  const onError: SubmitErrorHandler<ContactForm> = (errors) => {
    console.log(errors);
  };

  const closeDrawer = () => {
    dispatch(setCurrentProspect(null));
    dispatch(closeEditDrawer());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <DrawerLayout
        customWidth="500px"
        title={t("prospects.createProspectTitle")}
        testid="newProspectForm"
        closeDrawer={closeDrawer}
      >
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="First Name" />
                  <Input {...field} type="text" placeholder="John" error={fieldState?.error?.message} />
                </>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Last Name" />
                  <Input {...field} type="text" placeholder="Doe" error={fieldState?.error?.message} />
                </>
              )}
            />
          </FormControl>
        </Stack>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FormLabel label="Email" />
                <Input {...field} type="text" placeholder="johndoe@email.com" error={fieldState?.error?.message} />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FormLabel label="Phone" />
                <PhoneInput
                  value={field.value}
                  onChange={(phone) => {
                    const phoneValue = phone.startsWith("+") ? phone : `+${phone}`;
                    field.onChange(phoneValue);
                  }}
                  country={"us"}
                  error={fieldState?.error?.message}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="companyId"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FormLabel label="Company" />
                <Dropdown
                  isSearchable
                  label="Company"
                  options={companyOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                  error={fieldState?.error?.message}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="jobTitle"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Job Title" />
                <Dropdown
                  isSearchable
                  label="Job Title"
                  options={ProspectJobTitleOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Location" />
                <Dropdown
                  isSearchable
                  label="Location"
                  options={ProspectAddressOptions.map((address) => ({
                    value: JSON.stringify(address),
                    label: `${address.city}, ${address.state}`,
                  }))}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="ownerUserId"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Assignee" />
                <Dropdown
                  isSearchable
                  label="Assignee"
                  options={assigneeOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="leadType"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Lead Type" />
                <Dropdown
                  isSearchable
                  label="Lead Type"
                  options={ProspectLeadTypeOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="consentLevel"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="Level of Consent" />
                <Dropdown
                  isSearchable
                  label="Level of Consent"
                  options={ProspectConsentLevelOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </>
            )}
          />
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="declaredConsentOriginSource"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel label="How Consent Was Obtained" />
                <Input {...field} type="text" placeholder="Type how consent level was obtained" />
              </>
            )}
          />
        </FormControl>
      </DrawerLayout>
      {/*<DrawerButtonsLayout></DrawerButtonsLayout>*/}
      <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} position="absolute" bottom={0} width="100%">
        <Button
          data-testid="closeTaskDrawer"
          variant="secondary"
          onClick={closeDrawer}
          type="button"
          sx={{ width: "100%" }}
        >
          {t("tasks.cancel")}
        </Button>

        <Button type="submit" variant="primary" sx={{ width: "100%" }}>
          Save
        </Button>
      </Stack>
    </form>
  );
}
