import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Button from "@/common/components/button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "@/common/components/dropdown";
import DrawerLayout from "@/layouts/DrawerLayout";
import Stack from "@mui/material/Stack";
import React, { SyntheticEvent } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import { closeCreateProductDrawer } from "@/redux/reducers/productLibrary/slices/listProducts";
import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Input from "@/common/components/input";
import { updateProduct, createProduct } from "@/redux/reducers/productLibrary/slices/listProducts";
import Features from "@/pages/deal-flow-actions/create/Features";

const BILLING_FREQUENCY_OPTIONS = [
  {
    label: "Every 1 Month",
    value: 1,
  },
  {
    label: "Every 3 Months",
    value: 3,
  },
  {
    label: "Every 6 Months",
    value: 6,
  },
  {
    label: "Yearly",
    value: 12,
  },
];

const schema = Yup.object({
  name: Yup.string().label("Name").required(),
  description: Yup.string().label("Description"),
  price: Yup.number().label("Unit Price").required(),
  months: Yup.number().label("Billing Frequency").required(),
  feature: Yup.array(Yup.string().required()),
  valueProp: Yup.array(Yup.string().required()),
}).required();

export type ProductForm = Yup.InferType<typeof schema>;

export default function NewProductForm() {
  const dispatch = useAppDispatch();
  const currentProduct = useAppSelector(({ productLibrary }) => productLibrary.listProducts.currentProduct);
  const { t } = useTranslation();

  const methods = useForm<ProductForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: currentProduct?.name,
      description: currentProduct?.description,
      price: currentProduct?.price,
      months: currentProduct?.months,
      feature: currentProduct?.feature,
      valueProp: currentProduct?.valueProp,
    },
  });

  const { control, handleSubmit, formState } = methods;

  const onSubmit: SubmitHandler<ProductForm> = (data) => {
    if (!currentProduct) {
      dispatch(
        createProduct({
          name: data.name,
          description: data.description,
          price: data.price,
          months: data.months,
          feature: data.feature,
          valueProp: data.valueProp,
        }),
      );
    } else {
      dispatch(
        updateProduct({
          ...currentProduct,
          name: data.name,
          description: data.description,
          price: data.price,
          months: data.months,
          feature: data.feature,
          valueProp: data.valueProp,
        }),
      );
    }
    dispatch(closeCreateProductDrawer());
  };

  return (
    <form>
      <DrawerLayout
        customWidth="500px"
        title={
          currentProduct
            ? t("dealFlow.productLibrary.createDrawer.editTitle")
            : t("dealFlow.productLibrary.createDrawer.title")
        }
        testid="newProspectForm"
        closeDrawer={() => dispatch(closeCreateProductDrawer())}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <FormControl>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Product Name" />
                  <Input
                    placeholder="Product Name"
                    error={fieldState.error?.message}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </>
              )}
            />
          </FormControl>

          <FormControl>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel label="Product Description" />
                  <Input
                    placeholder="Product Description"
                    error={fieldState.error?.message}
                    multiline
                    value={field.value}
                    onChange={field.onChange}
                    leftPadding={16}
                  />
                </>
              )}
            />
          </FormControl>

          <Box>
            <Typography>Billing</Typography>
            <Box sx={{ display: "flex", gap: "16px" }}>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="price"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormLabel label="Unit Price" aria-required />
                      <Input
                        error={fieldState.error?.message}
                        type="number"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="$0.00"
                      />
                    </>
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="months"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormLabel label="Billing Frequency" aria-required />
                      <Dropdown
                        options={BILLING_FREQUENCY_OPTIONS}
                        value={field.value}
                        onChange={(o) => field.onChange(o.value)}
                        error={fieldState.error?.message}
                      />
                    </>
                  )}
                />
              </FormControl>
            </Box>
          </Box>

          <Box>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="valueProp"
                control={control}
                render={({ field }) => (
                  <Features label="Value Proposition" features={field.value || []} onChangeFeature={field.onChange} />
                )}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="feature"
                control={control}
                render={({ field }) => (
                  <Features label="Feature" features={field.value || []} onChangeFeature={field.onChange} />
                )}
              />
            </FormControl>
          </Box>
        </Box>
      </DrawerLayout>

      <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} position="absolute" bottom={0} width="100%">
        <Button
          variant="secondary"
          onClick={(e: SyntheticEvent) => {
            // TODO: Figure it out why this is causing submit
            e.preventDefault();
            dispatch(closeCreateProductDrawer());
          }}
          sx={{ width: "100%" }}
        >
          {t("dealFlow.productLibrary.createDrawer.cancel")}
        </Button>

        <Button
          type="button"
          variant="primary"
          sx={{ width: "100%" }}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          {t("dealFlow.productLibrary.createDrawer.addNew")}
        </Button>
      </Stack>
    </form>
  );
}
