import { Box, Divider } from "@mui/material";
import RolesBox from "./roles/RolesBox";
import { TitleAndSubtitleBox } from "../items";
import BasicInformation from "@/pages/settings/company/basic-information/BasicInformation";
import CrmBlock from "./crm";
import UserManagement from "./users/UserManagement";

export default function TeamSettings() {
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "16px",
      })}
    >
      <BasicInformation />
      <TitleAndSubtitleBox title="Roles" subtitle="Existing roles and description" />
      <RolesBox />
      <Divider />
      <TitleAndSubtitleBox title="Users" subtitle="Add or remove users to your team and assign roles" />
      <UserManagement />
      <Divider />
      <TitleAndSubtitleBox
        title="Company Integrations"
        subtitle="Manage your current integrated accounts and applications"
      />
      <CrmBlock />
    </Box>
  );
}
