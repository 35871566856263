import Avatar from "@/common/components/avatar";
import { useAppSelector } from "@/redux/store";
import { KnAiAgentCallDirectionEnum } from "@/services/generated";
import { capitalize } from "@/utils/capitalize";
import { ArrowDownLeftIcon, ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AiAgentForm } from "..";
import { CALL_FLOW_TYPE_OPTIONS } from "../../constants";

const AgentSummary = () => {
  const theme = useTheme();
  const voices = useAppSelector((selector) => selector.aiVoice.upsertAgent.voices);
  const { voice_id } = useWatch<AiAgentForm>();
  const { getValues } = useFormContext<AiAgentForm>();
  const agent = getValues();

  const agentVoice = useMemo(() => {
    return voices?.find((voice) => voice.id === voice_id);
  }, [voice_id, voices]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: `1px solid ${theme.palette.midnight[40]}`,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar label={agentVoice?.name} src={agentVoice?.image_url} />
          <Typography>{agentVoice?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              bgcolor: theme.palette.midnight[20],
              borderRadius: "100%",
              width: 42,
              height: 42,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {agent.call_direction === KnAiAgentCallDirectionEnum.Inbound ? (
              <ArrowDownLeftIcon height={20} />
            ) : (
              <ArrowUpRightIcon height={20} />
            )}
          </Box>
          <Box>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Agent Type</Typography>
            <Typography>{capitalize(agent.call_direction || "")}</Typography>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        sx={{
          p: 2,
          border: `1px solid ${theme.palette.midnight[40]}`,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          borderTopStyle: "none",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Voice Agent</Typography>
          <Typography>{agentVoice?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Agent Number</Typography>
          <Typography>{agent.agent_phone_number}</Typography>
        </Grid>

        <Divider sx={{ width: "100%", my: 2 }} />

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Interrupt Sensitivity</Typography>
          <Typography>{agent.interrupt}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>AI Creativity</Typography>
          <Typography>{agent.creativity}</Typography>
        </Grid>

        <Divider sx={{ width: "100%", my: 2 }} />

        {/*
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Live Transfer</Typography>
          <Typography>{agent.interrupt}</Typography>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Transfer Number</Typography>
          <Typography>{agent.transfer}</Typography>
        </Grid>

        <Divider sx={{ width: "100%", my: 2 }} />
        */}

        {agent.transfer && (
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Transfer To Departament</Typography>
            <Typography>{agent.transfer}</Typography>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Call Flow</Typography>
          {agent.call_flow && <Typography>{CALL_FLOW_TYPE_OPTIONS[agent.call_flow]?.label}</Typography>}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentSummary;
